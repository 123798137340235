import bindAll from 'lodash/bindAll';
import defaults from 'lodash/defaults';
import head from 'lodash/head';
import { filterVehicles } from './utils/data-parsing';
import { querySearchParams, queryFormatter } from './utils/query-formatter';
import { checkForFlexOverflow } from "./utils/check-for-flex-overflow";
import { changeButtonState } from "./utils/change-button-state";
import { isMobile, isTablet, isDesktop} from "./utils/viewport-check";
import SearchTags from './components/search-tags/search-tags';
import TooltipBasic from './components/tooltip-basic/tooltip-basic';
import { setTracyPage, setTracyEvent, setTracySearchFilter } from './tracy';
import SearchSelects from "./components/search-selects/search-selects";
import SearchTabs from './components/search-tabs/search-tabs';
import PrepareSearch from './components/prepare-search/prepare-search';
import { areSearchTagsApplied } from "./utils/are-search-tags-applied";
import {captureAirstripLinkEvent, captureSearchEvent} from './analytics';
import Countdown from './components/countdown/countdown';

const settings = {
  carSelector: '.car-selector',
  seriesNavItems: '.series-nav li',
  styleNavItems: '.style-nav li',
  results: '.car-selector-result',
  searchBtn: '.filter-actions .btn',
  locationPostcode: '.location-textbox .text',
  geoLocateBtn: '.js-geo-locate',
  lat: '.hidden_lat',
  long: '.hidden_long',
  dealerSelectMenu: '.location-display .retailer .js-select-menu', // Move
  distanceSelectMenu: '.location-display .distance .js-select-menu', // Move
  budgetSelectMenu: '.budget-display .js-select-menu', // Move
};

class CarSelector {
  constructor(options = {}) {
    this.options = defaults({}, options, settings);

    // Options DISTANCE, DEALER
    // NOTE THIS IS NOT RELATED TO THE DEALER_VIEW which provides a different
    // path for the request altogether
    this.requestMode = 'DISTANCE';
    this.searchDisplayType = 'grid';

    this.searchButtonElement = document.querySelector(this.options.searchBtn);
    this.selectAllSearchTag = document.getElementById('js-search-tag-select-all');

    // Reset any local storage items that have been set elsewhere on the site.
    window.localStorage.removeItem("compare");
    window.localStorage.removeItem("repex");

    this.init();
  };

  init() {
    bindAll(this,
      'handleSeriesClick',
      'updateNav',
      'updateResults',
      'handleResultClick',
      'handleAirstripLinkClick',
      'updateSelectAllSearchTagDataAttribute',
      'addSearchTagEventListeners',
      'handleSearch'
    );

    this.seriesNavItems = document.querySelectorAll(settings.seriesNavItems);

    if (this.seriesNavItems.length === 0) {
      this.firstSeriesNavItemSeries = null;
    } else {
      this.firstSeriesNavItemSeries = this.seriesNavItems[0].dataset.series;
    }

    this.BRAND = $('html').data('brand');
    this.SERIES = [];
    this.BODY_STYLE = [];
    this.STYLE = [];
    this.SEARCH_TAGS_ARRAY = [];
    this.POSTCODEREGEX = RegExp('^([A-Za-z][A-Ha-hJ-Yj-y]?[0-9][A-Za-z0-9]? ?[0-9][A-Za-z]{2}|[Gg][Ii][Rr] ?0[Aa]{2})$');

    $(this.options.seriesNavItems).on('click', this.handleSeriesClick);
    $(this.options.results).on('click', this.handleResultClick);
    $(this.options.locationPostcode).on('focus', this.handleSearchFocus);

    changeButtonState(this.searchButtonElement, 'disable');
    window.localStorage.removeItem('additional_filters');
    window.localStorage.removeItem('showMoreItems');
    window.localStorage.removeItem('targeFilter');
    // Set Tracy page
    setTracyPage(`stock > ${this.BRAND} > home`);

    this.tooltip = new TooltipBasic();
    this.checkForPresets();
    this.updatePageState();
    this.initNavs();
    this.addSearchTagEventListeners();

    const searchSelects = new SearchSelects();
    const searchTabs = new SearchTabs();
    const prepareSearch = new PrepareSearch();
    const airstripLink = document.querySelector("#airstrip-link")

    if (airstripLink !== null) {
      airstripLink.addEventListener('click', (event) => {
        this.handleAirstripLinkClick(event);
      });
    }

    this.searchButtonElement.addEventListener('click', function(e) {
      captureSearchEvent(e);
    })

    const countdownHandler = function(countdown) {

      switch(countdown.state) {
        case 'stopped':
          $('.countdown-banner').attr('data-active', false);
          $('section.banner').attr('data-active', true);
        break;
      };

    };

    new Countdown({
      target: document.querySelector('.countdown-banner .timer'),
      onStart: countdownHandler,
      onStop: countdownHandler
    });

  };

  checkForPresets() {
    // TODO: Hook these vars up to URL params
    const seriesPreset = window.tierPresets.t1;


    if (seriesPreset) {
      this.SERIES.push(seriesPreset.toString());
    } else {
      this.SERIES = [this.firstSeriesNavItemSeries];
    }

    if (this.BRAND === 'bmw') {
      this.updateSelectAllSearchTagDataAttribute(this.SERIES[0]);
      this.updateResults();
      this.updateNav('series');

      if (seriesPreset) {
        window.addEventListener('DOMContentLoaded', () => {
          this.selectAllSearchTag.click();
        });
      }
    } else {
      this.updateSelectAllSearchTagDataAttribute('mini');

      if (seriesPreset) {
        const seriesToSelect = document.querySelector(`.car-selector-result[data-modelno="${seriesPreset}"]`);

        window.addEventListener('DOMContentLoaded', () => {
          seriesToSelect.click();
        });
      }
    }
  }

  handleSearchFocus() {
    // On mobile, the footer can overlap the textbox when the keyboard pops up
    // To mitigate we can scroll the page up slightly to bring into view
    if (window.outerWidth < 768) {
      window.scroll(0, 80);
    }
  }

  handleAirstripLinkClick() {
    captureAirstripLinkEvent(event);
  }

  updatePageState() {
    // Setup page for dealer/retailer & group view
    if (window.dealer_view || window.group_view || window.group_retailer_view) {
      $('.filter-location').addClass('hide');

      this.searchButtonElement.classList.add('is-valid-search');

      if (areSearchTagsApplied()) {
        changeButtonState(this.searchButtonElement, 'enable');
      }
    }

    // Hide the distance slider if the hide distance setting is true in the CMS
    if (window.distance_toggle) {
      const tab_nav = $(".location-display .tab-nav");
      const retailer_tab = $(".location-display").find(`[data-tab='retailer']`);
      const label = $(".location-display .tabs-wrapper .filter-section > .label");
      retailer_tab.trigger('click');
      tab_nav.hide();
      label.hide();
    }
  }

  initNavs() {
    const navImg = '<svg width="15" height="24" viewBox="0 0 15 24"><path d="M23.4,10.047l-9,9,9,9-3,3-12-12,12-12Z" transform="translate(-8.398 -7.047)"/></svg>';

    const seriesOptions = {
      dots: false,
      autoWidth: true,
      mouseDrag: false,
      slideBy: 4,
      nav: true,
      navText: [ navImg, navImg ],
      responsive: {
        0:{ items: 4, nav: false },
        768:{ items: 6 },
        1000:{ items: 10 }
      }
    };

    const bodyOptions = {
      dots: false,
      autoWidth: true,
      mouseDrag: false,
      slideBy: 3,
      nav: true,
      navText: [ navImg, navImg ],
      responsive: {
        0:{ items: 3, nav: false },
        768:{ items: 6 },
        1000:{ items: 8 }
      }
    };

    const styleOptions = {
      dots: false,
      autoWidth: true,
      mouseDrag: false,
      slideBy: 3,
      nav: true,
      navText: [ navImg, navImg ],
      responsive: {
        0:{ items: 3, nav: false },
        768:{ items: 6 },
        1000:{ items: 8 }
      }
    };
  }

  handleSeriesClick(event) {
    const target = event.currentTarget;

    if (target.classList.contains('selected')) return;

    const selectedSeries = $(event.currentTarget).data('series').toString();
    const index = this.SERIES.indexOf(selectedSeries);

    if (index !== -1) {
      this.SERIES.splice(index, 1);
    } else {
      this.SERIES = [];
      this.SERIES.push(selectedSeries);
    }

    this.updateResults();
    this.updateNav('series');
    this.updateSelectAllSearchTagDataAttribute(this.SERIES[0]);
    SearchTags.checkIfAllVehiclesInSeriesAreSelected(selectedSeries);
  }

  handleResultClick(event) {
    const resultTile = event.currentTarget;
    const hiddenModelsContainer = document.querySelector('.hidden-models');
    const vehicleData = {
      'modelNumber': resultTile.dataset.modelno,
      'series': resultTile.dataset.series,
      'body': resultTile.dataset.body,
      'modelName': resultTile.dataset.modelname
    };

    if (resultTile.classList.contains('is-out-of-stock')) {
      return false;
    }

    if (resultTile.classList.contains('selected')) {
      resultTile.classList.remove('selected');
      resultTile.setAttribute('aria-selected', false);
    } else {
      resultTile.classList.add('selected');
      resultTile.setAttribute('aria-selected', true);
    }

    if (hiddenModelsContainer) {
      const hiddenModels = hiddenModelsContainer.querySelectorAll('.car-selector-result');

      hiddenModels.forEach(item => {
        const seriesName = item.dataset.series;
        if (seriesName === vehicleData.modelName) {
          item.click();
        }
      });
    }

    if (resultTile.parentNode.classList.contains('hidden-models')) return;

    SearchTags.updateSearchTagsArray(vehicleData, this.SEARCH_TAGS_ARRAY, this.BRAND);
    SearchTags.checkIfAllVehiclesInSeriesAreSelected(vehicleData.series);
  }

  addSearchTagEventListeners() {
    const searchTagsContainer = document.querySelector('.search-tags');
    const searchTagsSelectedContainer = document.querySelector('.search-tags__selected-container');
    const searchTagViewMoreToggle = document.querySelector('.js-search-tags-toggle');

    searchTagsContainer.addEventListener('click', (event) => {
      const clickTarget = event.target;

      if (!clickTarget.classList.contains('js-search-tag')) return;

      const searchTagButton = clickTarget;
      const searchTagDataAttributes = {
        'modelNumber': searchTagButton.dataset.modelno,
        'series': searchTagButton.dataset.series,
        'body': searchTagButton.dataset.body
      };

      SearchTags.checkIfAllVehiclesInSeriesAreSelected(searchTagDataAttributes.series);
      SearchTags.updateSearchTagsArray(searchTagDataAttributes, this.SEARCH_TAGS_ARRAY, this.BRAND);
    });

    window.addEventListener('resize', (event) => {
      checkForFlexOverflow(searchTagsSelectedContainer);

      const numberOfWrappedSearchTags = checkForFlexOverflow(searchTagsSelectedContainer);
      const searchTagsWrappedNumberSelector = document.querySelector('.search-tags__number');

      if (numberOfWrappedSearchTags > 0) {
        searchTagsWrappedNumberSelector.innerHTML = `+${numberOfWrappedSearchTags}`;
      } else {
        searchTagsWrappedNumberSelector.innerHTML = '';
      }
    });

    searchTagViewMoreToggle.addEventListener('click', SearchTags.expandTags);
    this.selectAllSearchTag.addEventListener('click', SearchTags.handleSelectAllSeriesClick);
  }

  updateSelectAllSearchTagDataAttribute(series) {
    const selectAllButton = document.getElementById('js-search-tag-select-all');
    selectAllButton.dataset.seriesSelected = series;
  };

  updateNav(nav) {
    let items = '';
    let LIST = '';

    if (nav === "series") {
      items = $(this.options.seriesNavItems);
      LIST = this.SERIES;
    } else if (nav === "style") {
      items = $(this.options.styleNavItems);
      LIST = this.STYLE;
    } else {
      return false;
    }

    $.each(items, (i, el) => {
      const item = $(el);
      const val = item.data(nav).toString();
      const index = LIST.indexOf(val);

      if (LIST.length === 0) {
        items.removeClass('selected');
        items.first().addClass('selected');
      } else {
        if (index === -1) {
          item.removeClass('selected');
        } else {
          item.addClass('selected');
        }
      }
    });
  }

  updateResults() {
    const results = $(this.options.results);
    const SERIES = this.SERIES;
    const BODY = this.BODY_STYLE;
    const STYLE = this.STYLE;

    filterVehicles(results, {
      series: SERIES,
      body: BODY,
      style: STYLE
    }, true);

    this.orderResults();
    this.updateNavAvailability();
  }

  orderResults() {
    const results = $('.car-selector-results');
    const notDisabled = results.find('.car-selector-result').not('.disabled');

    if (notDisabled.length > 0) {
      // Sort results by their initial index position
      const newOrder = notDisabled.sort(function(a, b) {
        return ($(b).data('index')) < ($(a).data('index')) ? 1 : -1;
      });

      results.prepend(newOrder);
    }
  }

  updateNavAvailability() {
    // First get selected items except the first item in the nav, as this is 'ALL'
    const seriesItems = $(this.options.seriesNavItems).not(":eq(0)");
    const styleItems = $(this.options.styleNavItems).not(":eq(0)");
    const results = $('.car-selector-result').not('.disabled');

    let availableSeries = [];
    let availableBody = [];
    let availableStyle = [];

    $.each(results, (i, el) => {
      const $el = $(el);
      availableSeries.push($el.data('series'));
      availableBody.push($el.data('body'));
      availableStyle.push($el.data('style'));
    });

    $.each(seriesItems, (i, el) => {
      const $el = $(el);
      const series = availableSeries.indexOf($el.data('series'));

      if ( series == -1 ) {
        $el.addClass('unavailable');
      } else {
        $el.removeClass('unavailable');
      }
    });

    $.each(styleItems, (i, el) => {
      const $el = $(el);
      const style = availableStyle.indexOf($el.data('style'));

      if ( style == -1 ) {
        $el.addClass('unavailable');
      } else {
        $el.removeClass('unavailable');
      }
    });
  }

  handleSearch(e) {
    // Capture GA Event
    captureListVehiclesEvent(e);
  }
}

export default CarSelector;
