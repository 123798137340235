import bindAll from 'lodash/bindAll';

/**
 * @class TooltipBasic
 * @description A simple tooltip component - Simplified from pre-existing Tooltip class.
 * TODO - Migrate all tooltips over to this stripped down component
 * Refactoring ticket: NCLBM-473
 * Keep tooltip content in the markup
 *
 * Matches tooltip icon to the tooltip container using data attributes and classes:
 *
 * <span data-tooltip="<UNIQUE_IDENTIFIER"></span>
 * <div class="<UNIQUE_IDENTIFIER">
 *    {{content}}
 * </span>
 */

class TooltipBasic {
  constructor(options = {}) {
    this.tip = null;
    this.bindAll();
    this.init();
  }

  bindAll() {
    bindAll(this,
      'handleTooltipOpenClick',
      'handleTooltipCloseClick',
      'show',
      'hide',
      'hideAll'
    );
  }

  init() {
    this.handleTooltipOpenClick();
    this.handleTooltipCloseClick();
  }

  handleTooltipOpenClick() {
    const tooltipIcon = document.querySelectorAll('.js-tooltip-icon');

    tooltipIcon.forEach(icon => {
      icon.addEventListener('click', (event) =>  {
        this.hideAll();
        this.show(event);
      });
    });
  }

  handleTooltipCloseClick() {
    const tooltipCloseIcon = document.querySelectorAll('.js-tooltip-close');

    tooltipCloseIcon.forEach(closeButton => {
      closeButton.addEventListener('click', (event) =>  {
        this.hide(event);
      });
    });
  }

  show(event) {
    const thisTooltipIcon = event.target;
    const targetTooltipDataAttribute = thisTooltipIcon.getAttribute('data-tooltip');

    const targetTooltipContainer = document.querySelector(`.${targetTooltipDataAttribute}`);

    if (targetTooltipContainer === null) {
      return;
    }

    targetTooltipContainer.style.display = 'block';
  }

  hide(event) {
    const thisTooltipCloseButton = event.target;
    const thisTooltipContainer = thisTooltipCloseButton.closest('.tooltip-container');

    if (thisTooltipContainer === null) {
      return;
    }

    thisTooltipContainer.style.display = 'none';
  }

  hideAll(event) {
    const thisTooltipContainers = document.querySelectorAll('.js-tooltip-container');

    thisTooltipContainers.forEach(tooltipContainer => {
      tooltipContainer.style.display = 'none';
    });
  }
}

export default TooltipBasic;
