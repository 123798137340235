import bindAll from 'lodash/bindAll';
import SimpleDialog from './../dialog/dialog';
import TabbedContent from './../tabbed-content/tabbed-content';
import { setTracyPage, setTracyEvent, setTracySearchFilter } from './../../tracy';

/**
 * @class EveVideos
 * @description
 * Fetching and displaying EVE videos
 */

class EveVideos {
  constructor(options = {}) {
    this.init();
  }

  init() {
    bindAll(this,
      'addVideoModalEventListeners',
      'fetchEveData',
      'buildEveVideoUrls',
      'displayEveVideoTabs',
      'displayEveVideoErrorMessage',
      'handleVideoLabelClick',
      'handleVideoModalClose',
      'hideVideoLabel'
    );

    this.resultsSection = document.querySelector('section[data-js-src]');

    if (
      !this.resultsSection.hasAttribute('data-eve-content-base-url') ||
      !this.resultsSection.hasAttribute('data-eve-api-base-url')
    ) {
      this.hideVideoLabel()
      return;
    }
    this.eveContentBaseUrl = this.resultsSection.dataset.eveContentBaseUrl;
    this.eveApiBaseUrl = this.resultsSection.dataset.eveApiBaseUrl;
    this.addVideoModalEventListeners();
  }

  hideVideoLabel() {
    const videoLabel = document.querySelectorAll('.video-label-container');

    videoLabel.forEach(label => {
      label.style.display = 'none';
    });
  }

  addVideoModalEventListeners() {
    const videoLabelButtons = document.querySelectorAll('.video-label-container');
    const videoContainer = document.querySelectorAll('.video-container');
    videoLabelButtons.forEach(this.handleVideoLabelClick);
    videoContainer.forEach(this.handleVideoModalClose);
  }

  fetchEveData(videoId, evenConfigId) {
    const apiEndpoint = `${this.eveApiBaseUrl}/${evenConfigId}`;
    const modal = document.getElementById(videoId);

    fetch(apiEndpoint, {
      method: 'GET',
      headers: {
        "clientID": "NCSL"
      }
    }).then((response) => {
      return response.json();
    }).then((data) => {
      this.buildEveVideoUrls(data, videoId);
      modal.dataset.videoLoaded = "true";
    }).catch((error)=>{
      this.displayEveVideoErrorMessage(videoId);
      console.log("GET Error: ", error.message);
    });
  }

  buildEveVideoUrls(eveData, videoId) {
    const eveVideoUriData = eveData.resources.videos;
    let eveVideoUrls = {
      'exterior': null,
      'interior': null
    };

    eveVideoUriData.forEach(uri => {
      if (uri.includes('EXT')) {
        eveVideoUrls.exterior = `${this.eveContentBaseUrl}/${uri}`
      } else if (uri.includes('INT')) {
        eveVideoUrls.interior = `${this.eveContentBaseUrl}/${uri}`
      }
    });

    if (Object.keys(eveVideoUrls).length === 0) {
      this.displayEveVideoErrorMessage(videoId);
    } else {
      this.displayEveVideoTabs(eveVideoUrls, videoId);
    }
  }

  displayEveVideoTabs(videoUrls, videoId) {
    const tabbedContent = document.querySelector(`#${videoId} .tabbed-content`);
    const loadingSpinner = document.querySelector(`#${videoId} .lds-spinner`);
    const videoLoadingDelay = 2000;

    for (const type in videoUrls) {
      const tabButton = tabbedContent.querySelector(`.tab-list__tab-${type}`);
      const tabPanel = tabbedContent.querySelector(`.tab-panel--${type}`);
      const videoElement = tabPanel.querySelector('video');
      const videoSource = videoElement.querySelector('source');
      const videoFallbackLink = tabPanel.querySelector('.video-fallback-link');

      if (videoUrls[type] !== null) {
        videoSource.setAttribute('src', videoUrls[type]);
        videoFallbackLink.setAttribute('href', videoUrls[type]);
        videoElement.setAttribute('preload', 'auto');

        if (type === 'exterior') {
          videoElement.load();
        } else {
          setTimeout(() => {
            videoElement.load();
          }, videoLoadingDelay);
        }
      } else {
        tabButton.remove();
        tabPanel.remove();
      }
    }

    const tabButtonCount = tabbedContent.querySelectorAll('.tab-list__tab');

    if (tabButtonCount.length < 2) {
      const firstTabButton = tabbedContent.querySelector('.tab-list__tab');
      const firstTabPanel = tabbedContent.querySelector('.tab-panel');

      firstTabButton.setAttribute('aria-selected', 'true');
      firstTabPanel.classList.add('content-has-been-viewed');
      firstTabPanel.classList.remove('hide');
    }

    this.tabbedContent = new TabbedContent();

    setTimeout(() => {
      tabbedContent.classList.remove('zero-opacity-layer');
      loadingSpinner.classList.add('hide');
    }, videoLoadingDelay);
  }

  displayEveVideoErrorMessage(videoId) {
    const tabbedContent = document.querySelector(`#${videoId} .tabbed-content`);
    const errorMessage = document.querySelector(`#${videoId} .video-container__error-message`);
    const loadingSpinner = document.querySelector(`#${videoId} .lds-spinner`);

    tabbedContent.classList.add('hide');
    loadingSpinner.classList.add('hide');
    errorMessage.classList.remove('hide');
  }

  handleVideoLabelClick(button) {
    const thisId = button.dataset.videoId;
    const evenConfigId = button.dataset.eveConfigId;
    const thisModal = document.getElementById(thisId);

    button.addEventListener('click', () => {
      this.videoModal = new SimpleDialog({
        id: thisId
      });

      this.videoModal.show();

      const selectedVideoTab = thisModal.querySelector('.tab-list__tab[aria-selected="true"]').textContent.toLowerCase();
      const tracyObject = {
        linkName: `modal opened on ${selectedVideoTab} tab`,
        requestedUrl: window.location.href,
        pageName: 'search result',
      };

      setTracyEvent('stock > internal link clicked', tracyObject);

      if (thisModal.dataset.videoLoaded) return;

      this.fetchEveData(thisId, evenConfigId);
    });
  };

  handleVideoModalClose(videoContainer) {
    const videoElements = videoContainer.querySelectorAll('video');

    videoContainer.addEventListener('click', (event) => {
      if (
        event.target.classList.contains('modal-layer') ||
        event.target.classList.contains('dialog-header') ||
        event.target.classList.contains('bt-close') ||
        event.target.classList.contains('icon')
      ) {
        videoElements.forEach(video => {
          video.pause();
        });
      }
    });
  }
}

export default EveVideos;
