import bindAll from 'lodash/bindAll';

/**
 * @class Dialog
 * @description A Simple Dialog component which will display in a modal layer
 */

class SimpleDialog {
  constructor(options = {}) {
    this.id = options.id || 'default-dialog-id';
    this.el = $(`#${this.id}`);
    this.content = $(`${this.id} .content`);
    this.btClose = $(`.bt-close`);

    this.init();
  }

  /**
   * @function init
   * @description Initialize the Dialog component and insert the content
   */
  init(content = null) {
    bindAll(this,
     'show',
     'hide',
     'handleOutsideClick'
   );
    this.btClose.on('click', this.hide);
    this.el.on('click', this.handleOutsideClick);
  }

  handleOutsideClick(ev) {
    const target = $(ev.target);

    if (target.hasClass('modal-layer') || target.hasClass('dialog-header')) {
      this.hide();
    }
  }

  /**
   * @function show
   * @description Shows the dialog window in the display
   */
  show(data) {
    // TODO - LOAD THE DIALOG HANDLEBAR
    // UPDATED ANY DYNAMIC VARIABLES
    $('body').addClass('disable-scroll');
    this.el.addClass('visible');
  }

  /**
   * @function hide
   * @description Hide the dialog window in the display
   */
  hide() {
    $('body').removeClass('disable-scroll');
    this.el.removeClass('visible');
  }
}

export default SimpleDialog;
