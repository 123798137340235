import bindAll from 'lodash/bindAll';
import defaults from 'lodash/defaults';
import head from 'lodash/head';
import { querySearchParams, queryFormatter } from './../../utils/query-formatter';
import { setTracyPage, setTracyEvent, setTracySearchFilter } from './../../tracy';
import { changeButtonState } from "./../../utils/change-button-state";
import { areSearchTagsApplied } from "./../../utils/are-search-tags-applied";

/**
 * @class PrepareSearch
 * @description
 * Validating and preparing search before redirecting to results page
 * Used on search index and similarity search pages
 */

 const selectors = {
  budgetMonthlyMin: '.budget-display .monthly .js-select-menu.js-select-min',
  budgetMonthlyMax: '.budget-display .monthly .js-select-menu.js-select-max',
  budgetOtrMin: '.budget-display .otr .js-select-menu.js-select-min',
  budgetOtrMax: '.budget-display .otr .js-select-menu.js-select-max',
  locationDealer: '.location-display .retailer .js-select-menu',
  locationDistance: '.location-display .distance .js-select-menu',
  budgetTab: '.budget .tabs li',
  locationTab: '.location .tabs li',
  searchBtn: '.filter-actions .btn',
  locationPostcode: '.location-textbox .text',
  geoLocateBtn: '.js-geo-locate',
  lat: '.hidden_lat',
  long: '.hidden_long',
  filterHeader: '.filter-header'
};

class PrepareSearch {
  constructor(options = {}) {
    this.options = defaults({}, options, selectors);

    this.POSTCODEREGEX = RegExp('^([A-Za-z][A-Ha-hJ-Yj-y]?[0-9][A-Za-z0-9]? ?[0-9][A-Za-z]{2}|[Gg][Ii][Rr] ?0[Aa]{2})$');

    this.init();
  }

  init() {
    bindAll(this,
      'showSpinner',
      'handleSearchClick',
      'getConfiguredVehicleIdFromUrl',
      'validateSearch',
      'prepareStandardSearch',
      'prepareGroupSearch',
      'prepareSimilarSearch',
      'handleSearchSubmit'
    );

    $(this.options.searchBtn).on('click', this.handleSearchClick);
    $(this.options.locationPostcode).on('keydown', this.handleSearchSubmit);
  }

  handleSearchSubmit(event) {
    if (event.key === 'Enter') {
      if (window.similar_search) {
        this.handleSearchClick();
      } else {
        if (areSearchTagsApplied()) {
          this.handleSearchClick();
        }
      }
    }
  }

  showSpinner() {
    $('.app-loader').addClass('visible');
    $('body').addClass('disable-scroll');
  }

  handleSearchClick() {
    this.validateSearch();
  }

  validateSearch() {
    if (window.dealer_view) {
      this.prepareStandardSearch();
      return;
    }

    if (window.group_view || window.group_retailer_view) {
      this.prepareGroupSearch();
      return;
    }

    const locationPostcode = $(this.options.locationPostcode)[0].value;
    let lat = $(this.options.lat)[0].value;
    let long = $(this.options.long)[0].value;

    const valid = this.POSTCODEREGEX.test(locationPostcode) || lat && long !== '';

    if (window.distance_toggle) {
      const selectedRetailer = $(this.options.locationDealer)[0].value;
      if (!selectedRetailer) {
        return false;
      }
    }

    if (valid) {
      if (window.similar_search) {
        this.prepareSimilarSearch();
      } else {
        this.prepareStandardSearch();
      }
    }
  }

  getConfiguredVehicleIdFromUrl() {
    const currentUrl = window.location.pathname;
    const array = currentUrl.split('/');

    return array[2];
  }

  prepareSimilarSearch() {
    querySearchParams.postcode = $(this.options.locationPostcode)[0].value;
    querySearchParams.lat = $(this.options.lat)[0].value;
    querySearchParams.long = $(this.options.long)[0].value;
    querySearchParams.distance = $(this.options.locationDistance)[0].value;
    querySearchParams.dealer_number = $(this.options.locationDealer)[0].value;
    querySearchParams.dealer_search = querySearchParams.dealer_number ? true : false;
    querySearchParams.monthly_max = 1000;
    querySearchParams.max = 200000;

    const configurationID = this.getConfiguredVehicleIdFromUrl();

    let path = `/configure/${configurationID}/results`;

    this.showSpinner();

    const requestURL = queryFormatter(path, window.request_mode, window.dealer_view);

    document.location = requestURL;
  }

  prepareStandardSearch() {
    const selectedBudget = $('.budget-display.tabs div.selected');
    const selectedLocation = $('.location-display .tabs div.selected');

    querySearchParams.finance = selectedBudget.hasClass('monthly');
    querySearchParams.min = selectedBudget.find('.js-select-menu.js-select-min')[0].value;
    querySearchParams.max = selectedBudget.find('.js-select-menu.js-select-max')[0].value;
    querySearchParams.monthly_min = selectedBudget.find('.js-select-menu.js-select-min')[0].value;
    querySearchParams.monthly_max = selectedBudget.find('.js-select-menu.js-select-max')[0].value;
    querySearchParams.postcode = $(this.options.locationPostcode)[0].value;
    querySearchParams.lat = $(this.options.lat)[0].value;
    querySearchParams.long = $(this.options.long)[0].value;
    querySearchParams.distance = selectedLocation.find('.js-select-menu')[0].value;
    querySearchParams.dealer_number = selectedLocation.find('.js-select-menu')[0].value;

    const selectedModels = $('.car-selector-result.selected');
    const results = $('.car-selector-result').not('.disabled');

    const vehicleDispatch = [];
    const vehicleTracking = [];

    if (selectedModels.length > 0) {
      $.each(selectedModels, (i, el) => {
        vehicleDispatch.push($(el).data('modelno'));
        vehicleTracking.push({
          series: $(el).data('series'),
          body: $(el).data('body')
        });
      });
    } else {
      if (results.length > 0) {
        $.each(results, (i, el) => {
          vehicleDispatch.push($(el).data('modelno'));
          vehicleTracking.push({
            series: $(el).data('series'),
            body: $(el).data('body')
          });
        });
      }
    }
    querySearchParams.vehicles.vehicle_ids = vehicleDispatch;
    querySearchParams.dealer_search = querySearchParams.dealer_number ? true : false;

    let path = '/results';

    if (window.dealer_view) {
     path = window.dealer_path;
    }

    this.showSpinner();


    /** [TRACY_SEARCH_FILTERS] **/
    vehicleTracking.forEach((vehicle) => {
      // state, unit, label, term
      setTracySearchFilter('user selected', null, 'series', vehicle.series);
      setTracySearchFilter('user selected', null, 'body', vehicle.body);
    });

    setTracySearchFilter('user selected', null, 'postcode', querySearchParams.postcode);
    setTracySearchFilter('user selected', 'miles', 'distance', querySearchParams.distance);
    setTracySearchFilter('user selected', null, 'finance', querySearchParams.finance);

    if (querySearchParams.finance) {
      setTracySearchFilter('user selected', null, 'monthly min payment', querySearchParams.monthly_min);
      setTracySearchFilter('user selected', null, 'monthly max payment', querySearchParams.monthly_max);
    } else {
      setTracySearchFilter('user selected', null, 'cash min', querySearchParams.min);
      setTracySearchFilter('user selected', null, 'cash max', querySearchParams.max);
    }
    /** [END] **/

    /** [TRACY_EVENT] **/
    const ref = `stock > ${this.BRAND} > home > search filters applied`;
    const parameters = { type: 'vehicle', eventAction: 'internal click' };
    setTracyEvent(ref, parameters);
    /** [END] **/

    const requestURL = queryFormatter(path, window.request_mode, window.dealer_view);
    document.location = requestURL;
  }

  prepareGroupSearch() {
    const selectedBudget = document.querySelector('.filter-budget .tabs div.selected');
    const budgetMin = selectedBudget.querySelector('.js-select-min');
    const budgetMax = selectedBudget.querySelector('.js-select-max');
    const distance = document.querySelector('.location-display .distance .js-select-menu');

    querySearchParams.finance = selectedBudget.classList.contains('monthly');
    querySearchParams.min = budgetMin.value;
    querySearchParams.max  = budgetMax.value;
    querySearchParams.monthly_min = budgetMin.value;
    querySearchParams.monthly_max  = budgetMax.value;
    querySearchParams.distance = distance.value;

    const selectedModels = $('.car-selector-result.selected');
    const results = $('.car-selector-result').not('.disabled');

    const vehicleDispatch = [];
    const vehicleTracking = [];

    if (selectedModels.length > 0) {
      $.each(selectedModels, (i, el) => {
        vehicleDispatch.push($(el).data('modelno'));
        vehicleTracking.push({
          series: $(el).data('series'),
          body: $(el).data('body')
        });
      });
    } else {
      if (results.length > 0) {
        $.each(results, (i, el) => {
          vehicleDispatch.push($(el).data('modelno'));
          vehicleTracking.push({
            series: $(el).data('series'),
            body: $(el).data('body')
          });
        });
      }
    }
    querySearchParams.vehicles.vehicle_ids = vehicleDispatch;
    querySearchParams.dealer_search = querySearchParams.dealer_number ? true : false;

    let path = window.result_path;

    this.showSpinner();

    /** [TRACY_SEARCH_FILTERS] **/
    vehicleTracking.forEach((vehicle) => {
      // state, unit, label, term
      setTracySearchFilter('user selected', null, 'series', vehicle.series);
      setTracySearchFilter('user selected', null, 'body', vehicle.body);
    });

    setTracySearchFilter('user selected', null, 'finance', querySearchParams.finance);

    if (querySearchParams.finance) {
      setTracySearchFilter('user selected', null, 'monthly min payment', querySearchParams.monthly_min);
      setTracySearchFilter('user selected', null, 'monthly max payment', querySearchParams.monthly_max);
    } else {
      setTracySearchFilter('user selected', null, 'cash min', querySearchParams.min);
      setTracySearchFilter('user selected', null, 'cash max', querySearchParams.max);
    }
    /** [END] **/

    /** [TRACY_EVENT] **/
    const ref = `stock > ${this.BRAND} > home > search filters applied`;
    const parameters = { type: 'vehicle', eventAction: 'internal click' };
    setTracyEvent(ref, parameters);
    /** [END] **/

    const requestURL = queryFormatter(path, window.request_mode, window.group_view);
    document.location = requestURL;
  }
}

export default PrepareSearch;
