export const filterVehicles = (
  vehicles,
  selected = {
    series: [],
    body: [],
    style: []
  },
  isjQuery = false,
  idOnly = false) => {
  const responseArray = [];
  let series, body, style;
  $.each(vehicles, (i, item) => {
    let vehicle = item;
    if (isjQuery) {
      vehicle = $(item);
      series = vehicle.data('series').toString(),
      body = vehicle.data('body'),
      style = vehicle.data('style');
    } else {
      series = vehicle.tier_one_id,
      body = vehicle.tier_two_id,
      style = vehicle.tier_three_id;
    }

    const seriesIndex = selected.series.indexOf(series);
    const bodyIndex = selected.body.indexOf(body);
    const styleIndex = selected.style.indexOf(style);
    let enabled = true;

    if (selected.series.length == 0) {
      if (selected.body.length == 0) {
        if (styleIndex == -1) {
          enabled = false;
        } else {
          enabled = true;
        }
      } else if (selected.style.length == 0) {
        if (bodyIndex == -1) {
          enabled = false;
        } else {
          enabled = true;
        }
      } else {
        if (bodyIndex == -1 || styleIndex == -1) {
          enabled = false;
        } else {
          enabled = true;
        }
      }
    } else if (selected.body.length == 0) {
      if (selected.series.length == 0) {
        if (styleIndex == -1) {
          enabled = false;
        } else {
          enabled = true;
        }
      } else if (selected.style.length == 0) {
        if (seriesIndex == -1) {
          enabled = false;
        } else {
          enabled = true;
        }
      } else {
        if (seriesIndex == -1 || styleIndex == -1) {
          enabled = false;
        } else {
          enabled = true;
        }
      }
    } else if (selected.style.length == 0) {
      if (selected.series.length == 0) {
        if (bodyIndex == -1) {
          enabled = false;
        } else {
          enabled = true;
        }
      } else if (selected.body.length == 0) {
        if (seriesIndex == -1) {
          enabled = false;
        } else {
          enabled = true;
        }
      } else {
        if (seriesIndex == -1 || bodyIndex == -1) {
          enabled = false;
        } else {
          enabled = true;
        }
      }
    } else {
      if (seriesIndex == -1 || bodyIndex == -1 || styleIndex == -1) {
        enabled = false;
      } else {
        enabled = true;
      }
    }

    if (selected.series.length == 0 && selected.body.length == 0 && selected.style.length == 0) {
      enabled = true;
    }

    if (selected.series[0]==='all') {
      enabled = true;
    }

    if (isjQuery) {
      if (!enabled) {
        vehicle.addClass('disabled');
      } else {
        vehicle.removeClass('disabled');
      }
    } else {
      if (enabled) {
        if (idOnly) {
          responseArray.push(vehicle.id);
        } else {
          responseArray.push(vehicle);
        }
      }
    }
  });

  return responseArray;
};
