import bindAll from 'lodash/bindAll';
import ComponentBase from '../component-base';

/**
 * @class Budget Display
 * @description A simple budget display to allow users to update price values
 */

class BudgetDisplay extends ComponentBase {
  constructor(options = {}) {
    super();
    this.id = options.id || 'default-budget-display-id';
    this.tabs = $(`#${this.id} .tab-nav li`);
    this.disclaimer = $(`#${this.id} .otr-disclaimer`);

    this.rangeValue = $(`#${this.id} .monthly .range-value`);
    this.sliderMonthly = $(`#${this.id} .monthly .js-range-slider`);

    this.rangeValueOTR = $(`#${this.id} .otr .range-value`);
    this.sliderOTR = $(`#${this.id} .otr .js-range-slider`);

    // Disclaimer only required on Search page so hiding it here
    this.disclaimer.css('display', 'none');

    // Set a reference for the callback function for the tabs
    // for the result page this might hide a panel
    this.handleTabCallback = options.tabCallback;
    // Set the referenc for the slideCallback function
    this.dispatchData = options.dispatchData;

    this.init(options);
    this.setActiveBudgetTab(options);
  }

  setActiveBudgetTab(options) {
    if (options.finance) {
      const monthly = $('.budget-display [data-tab="monthly"]');
      monthly.trigger('click');
    } else {
      const otr = $('.budget-display [data-tab="otr"]');
      otr.trigger('click');
    }
  }

  /**
   * @function init
   * @description Initialize the budget display, activate component and bind
   * any events
   */
  init(options) {
    bindAll(
      this,
      'initSliders',
      'handleSliderStopped',
      'handleMonthlySliderStopped'
    )
    super.init();
    this.initSliders(options.userValues);
  }

  /**
   * @function handleMonthlySliderStopped
   * @description Handles the monthly slider stopped event to execute the required dispatch
   */
  handleMonthlySliderStopped(values) {
    if (this.dispatchData) {
      this.dispatchData({
        monthly_min: values[0],
        monthly_max: values[1]
      });
    }
  }

  /**
   * @function handleSliderStopped
   * @description Handles the slider stopped event to execute the required dispatch
   */
  handleSliderStopped(values) {
    if (this.dispatchData) {
      this.dispatchData({
        min: values[0],
        max: values[1]
      });
    }
  }

  /**
   * @function handleTabClick
   * @description Override the main handler and add some callback functionality
   */
  handleTabClick(e) {
    const id = super.handleTabClick(e);
    if (this.handleTabCallback) { this.handleTabCallback(id); }
  }

  /**
   * @function initSliders
   * @description Initialize the sliders for the budget display
   */
  initSliders(values = null) {
    this.createSlider(
      this.sliderMonthly,
      {
        initText: '£0 - £1,000+',
        values: (values && values.monthly) ? [values.monthly.monthly_min, values.monthly.monthly_max] : [0, 1000],
        max: 1000,
        step: 10,
        formatDisplay(ui) {
          let monthly_max = ui.values[1];

          if (ui.values[1] >= 1000) {
            monthly_max = '1,000+';
          }

          return `£${ui.values[0]} - £${monthly_max}`;
        },
        onStop: this.handleMonthlySliderStopped
      },
      this.rangeValue
    );
    this.createSlider(
      this.sliderOTR,
      {
        initText: `£0 - £${window.max_vehicle_price.toLocaleString()}`,
        max: window.max_vehicle_price,
        step: 100,
        values: (values && values.otr) ? [values.otr.min, values.otr.max] : [1000, 10000],
        formatDisplay(ui) {
            return `£${ui.values[0].toLocaleString()} - £${ui.values[1].toLocaleString()}`;
        },
        onStop: this.handleSliderStopped
      },
      this.rangeValueOTR
    );
  }
}

export default BudgetDisplay;