import bindAll from 'lodash/bindAll';

/**
 * @class Toolnotification
 * @description A simple tooltip component
 */

const notificationDefaultContent = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.';

class Notification {
  constructor(options = {}) {
    this.notification = null;
    this.init();
  }

  init() {
    bindAll(this,
      'show',
      'hide'
    );
  }

  show(options) {
    let { parent, content } = options;
    if (this.notification) {
      this.hide();
    }
    if (!this.notification) {
      const notification = this.render(content || notificationDefaultContent);
      notification.appendTo(parent);

      const width = notification.width(),
      height = notification.height(),
      arrowLeft = 100;
      notification.css({
      });

      notification.on('click', () => { this.hide(); });
      this.notification = notification;
    }
    return this.notification;
  }

  hide() {
    if(this.notification) {
      this.notification.remove();
      this.notification = null;
    }
  }

  render(content) {
    const notification = `
      <div class="notification-wrapper">
        <div class="notification-container">
          <p class="notification-content">
            ${content}
          </p>
        </div>
      </div>
    `;
    return $(notification);
  }
}

export default Notification;
