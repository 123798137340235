import bindAll from 'lodash/bindAll';
import defaults from 'lodash/defaults';
import head from 'lodash/head';
import TooltipBasic from './components/tooltip-basic/tooltip-basic';
import LocationDisplay from './components/location-display/location-display';
import SearchTabs from './components/search-tabs/search-tabs';
import SearchSelects from "./components/search-selects/search-selects";
import PrepareSearch from './components/prepare-search/prepare-search';


class SimilarSearch {
  constructor(options = {}) {
    this.init();
  };

  init() {
    this.tooltipBasic = new TooltipBasic();
    const searchSelects = new SearchSelects();
    const searchTabs = new SearchTabs();
    const prepareSearch = new PrepareSearch();
  }
}

export default SimilarSearch;
