import bindAll from 'lodash/bindAll';
import ComponentBase from '../component-base';

/**
 * @class Location Display
 * @description A simple location display to allow users to enter their postcode
 * and search via a retailer location for the vehicles
 */

class LocationDisplay extends ComponentBase {
  constructor(options = {}) {
    super();
    this.id = options.id || 'default-location-display-id';
    this.selector = $(`#${this.id}`);

    if (this.selector.length > 0) {
      this.tabs = $(`#${this.id} .tab-nav li`);

      this.rangeValue = $(`#${this.id} .range-value`);
      this.slider = $(`#${this.id} .js-range-slider`);

      this.inputPostCode = $(`#${this.id} .location-textbox .text`);
      this.selectLocation = $(`#${this.id} .js-select-menu`);
      this.selectGroupDealer = $(`#${this.id} .js-group-dealer-select-menu`);
      this.btGeoLocate = $(`#${this.id} .js-geo-locate`);

      // Hidden Elements
      this.coordLat = $(`#${this.id} .hidden_lat`);
      this.coordLong = $(`#${this.id} .hidden_long`);

      // Set a reference for the callback function for the tabs
      // which will switch the request mode...
      this.handleTabCallback = options.tabCallback;
      // Data dispatcher method
      this.dispatchData = options.dispatchData;

      this.inputPostCode.val(options.userData.postcode || '');
      this.coordLat[0].value = options.userData.lat || '';
      this.coordLong[0].value = options.userData.long || '';
      this.distance = options.userData.distance;
      this.dealer_search = options.userData.dealer_search;
      this.dealer_number = options.userData.dealer_number || '';

      this.location = {
        postcode: options.userData.postcode
      }

      if (this.coordLat[0].value && this.coordLong[0].value) {
        this.location = {
          lat: this.coordLat[0].value,
          long: this.coordLong[0].value
        }
      }

      if (!window.dealerView && !window.groupView && !window.groupRetailerView && !window.isAirstripStock) {
        window.setTimeout(() => {
          this.handlePostCodeValid(this.location);
        }, 2000);
      }

      this.init();
    }
  }

  /**
   * @function init
   * @description Initialize the location display and bind any events, init
   * any sliders
   */
  init() {
    super.init();
    bindAll(this,
      'toggleTabs',
      'handleGeoClick',
      'handleGeoSuccess',
      'handleGeoError',
      'handleSelectChange',
      'handleSliderStopped',
      'handleRadioSelected'
    );
    this.selectLocation.selectmenu();
    this.selectLocation.on('selectmenuchange', this.handleSelectChange);

    this.createSlider(
      this.slider,
      {
        initText: '100 miles',
        range: 'min',
        max: 500,
        step: 5,
        value: this.distance || 100,
        formatDisplay(ui) {
          if(ui.value === 0){
            ui.value = 1;
          }
          return `${ui.value} miles`;
        },
        onStop: this.handleSliderStopped
      },
      this.rangeValue
    );

    this.inputPostCode.on('input', this.validatePostcode);
    this.btGeoLocate.on('click', this.handleGeoClick);

    if (this.location.lat && this.location.long) {
      this.inputPostCode.attr('placeholder', 'Current Location');
    }

    if (this.dealer_search) {
      // Simulating a click on load to activate dealer tab
      $(`.location-display [data-tab="retailer"]`).trigger('click');
    }

    // Hide the distance slider if the hide distance setting is true in the CMS
    if (window.distance_toggle) {
      const tab_nav = $(".location-display .tab-nav");
      const label = $(".location-display .tabs-wrapper .filter-section > .label");
      tab_nav.hide();
      label.hide();
    }
  }

  /**
   * @function handleSliderStopped
   * @description Handles the slider stopped event to execute and required callbacks
   */
  handleSliderStopped(values) {
    if (this.dispatchData) {
      this.dispatchData({
        distance: values
      });
    }
  }

  /**
   * @function handleSelectChange
   * @description Override the main handler and add some callback functionality
   */
  handleSelectChange(e) {
    if (this.dispatchData) { this.dispatchData({ dealer_number: e.currentTarget.value }); }
  }

  /**
   * @function handleTabClick
   * @description Override the main handler and add some callback functionality
   */
  handleTabClick(e) {
    const id = super.handleTabClick(e);
    if (this.handleTabCallback) { this.handleTabCallback(id); }
  }

  /**
   * @function toggleTabs
   * @description Toggles the state of the tabs in the display
   * @param {Boolean} isActive - States whether tabs should be active or inactive
   */
  toggleTabs(isActive) {
    if (isActive) {
      $(`#${this.id} .tabs-wrapper`).removeClass('disabled');
      return;
    }
    $(`#${this.id} .tabs-wrapper`).addClass('disabled');
  }

  /**
   * @function handleGeoClick
   * @description Get the location properties for the postcode entered
   */
  handleGeoClick(e) {
    if (!navigator.geolocation) {
      alert('Geo location not available on this browser');
    } else {
      navigator.geolocation.getCurrentPosition(this.handleGeoSuccess, this.handleGeoError);
      this.inputPostCode[0].value = '';
      this.inputPostCode.attr('placeholder', 'Locating...');
    }
  }

  /**
    * @function handleGeoSuccess
    * @description Handles the success response for the GEO location
    * @param {Object} position - The positon object for the respective postcode
    * containing the latitude and longitude data
    */
  handleGeoSuccess(position) {
    this.coordLat[0].value = position.coords.latitude;
    this.coordLong[0].value = position.coords.longitude;
    this.inputPostCode.attr('placeholder', 'Current Location');

    const location = { lat: position.coords.latitude, long: position.coords.longitude };

    if (window.groupView || window.groupRetailerView || window.isAirstripStock) {
      this.getGroupDealerList(location);
      this.handleRadioSelected();
    } else {
      this.getDealerList(location);
    }

    this.toggleTabs(true);
    if (this.dispatchData) { this.dispatchData(location); }
  }

  /**
    * @function handleGeoError
    * @description Handles the error response for the GEO location
    */
  handleGeoError() {
    alert('Geo location failed, please check your browser location settings.');
    this.inputPostCode.attr('placeholder', 'Enter postcode');
  }

  /**
   * @function getDealerList
   * @description Returns the dealer list to populate the select menu
   */
  getDealerList(location) {
    const getDealers = $.get('/nearest_dealers', location);

    getDealers.done((data) => {
      if (data["error"]) {
        alert(data["error"]);
        return false;
      };

      this.selectLocation.empty();
      this.selectLocation.append($('<option value="">Please select</option>'));

      $.each(data, (i, option) => {
        if (!option.distance) { option.distance = 0 };
        this.selectLocation
          .append($('<option></option>')
          .val(option.number)
          .html(`${option.title} (${option.distance} miles)`));
      });
      // Persist the selected dealer if there is one
      this.selectLocation.val(this.dealer_number);
      this.selectLocation.selectmenu('refresh');
    });
  }

  /**
   * @function enableModalButton
   * @description Enables buttons by resetting the 'disabled' attribute
   * @param {HTMLElement} selector - The button to enable
   */
  enableModalButton(selector) {
    const buttons = document.querySelectorAll(selector);

    buttons.forEach(button => {
      button.setAttribute('disabled', '');
    });
  }

  /**
   * @function disableModalButton
   * @description Disables buttons by add the 'disabled' value to the 'disabled' attribute
   * @param {HTMLElement} selector - The button to disable
   */
  disableModalButton(selector) {
    const buttons = document.querySelectorAll(selector);

    buttons.forEach(button => {
      button.setAttribute('disabled', 'disabled');
    });
  }

  /**
   * @function getGroupDealerList
   * @description Returns the dealer list within a group to populate the
                  enquiry and reservation modals
   */
  getGroupDealerList(location) {
    const groupSlug = window.isAirstripStock ? window.airstripGroupSlug : window.location.pathname.split('/')[2];
    const getDealers = $.get('/nearest_dealers?group=' + groupSlug, location);

    getDealers.done((data) => {
      if (data["error"]) {
        alert(data["error"]);
        return false;
      };

      this.selectGroupDealer.empty();

      $.each(data, (i, option) => {
        if (!option.distance) { option.distance = 0 };

        const groupDealerItem = $("<li>", {});
        const selectedGroupDealerWrapper = $("<div>", {class: 'selected-group-dealer-wrapper'});
        const selectedGroupDealer = $("<input>", {type: 'radio', name: 'group-dealer', class: 'selected-group-dealer'}).val(option.number);
        const groupDealerWrapper = $("<div>", {class: 'group-dealer-wrapper'});
        const groupDealerLeft = $("<div>", {class: 'group-dealer-wrapper__left'});
        const groupDealerRight = $("<div>", {class: 'group-dealer-wrapper__right'});
        const groupDealerTitle = $("<span>", {class: 'group-dealer-title'}).text(option.title);
        const groupDealerDistance = $("<span>", {class: 'group-dealer-distance'}).text(`Distance ${option.distance} miles`);
        const groupDealerAddress = $("<span>", {class: 'group-dealer-address'}).text(option.address);

        selectedGroupDealerWrapper.append(selectedGroupDealer)
        groupDealerItem.append(selectedGroupDealerWrapper)
        groupDealerWrapper.append(groupDealerLeft)
        groupDealerWrapper.append(groupDealerRight)
        groupDealerLeft.append(groupDealerTitle)
        groupDealerLeft.append(groupDealerAddress)
        groupDealerRight.append(groupDealerDistance)
        groupDealerItem.append(groupDealerWrapper)

        this.selectGroupDealer.append(groupDealerItem)
      });
    });
  }

  /**
   * @function handleRadioSelected
   * @description Handles when a radio button is selected for a specific dealer in the group modal
   */
  handleRadioSelected() {
    if (window.groupView || window.groupRetailerView || window.isAirstripStock) {
      const dealerList = document.querySelector('.js-group-dealer-select-menu');

      dealerList.addEventListener('change', (event) => {
        if (event.target.classList.contains('selected-group-dealer')) {
          this.enableModalButton('.js-modal--reserve-enquire .btn');
        }
      });
    }
  }

  /**
   * @function handlePostCodeValid
   * @description Initializes the distance slider this function must exist
   * if overriding and using the postcode validator
   */
  handlePostCodeValid(location) {
    if (window.groupView || window.groupRetailerView || window.isAirstripStock) {
      this.getGroupDealerList(location);
      this.disableModalButton('.js-modal--reserve-enquire .btn');
      this.handleRadioSelected();
    } else {
      this.getDealerList(location);
      this.toggleTabs(true);
    }

    // Execute the state callback function
    if (this.dispatchData) { this.dispatchData(location); }
  }

  /**
   * @function handlePostCodeValid
   * @description Initializes the distance slider this function must exist
   * if overriding and using the postcode validator
   */
  handlePostCodeInvalid(location) {
    const searchButton = document.querySelector('.search-button');

    if (window.groupView || window.groupRetailerView || window.isAirstripStock) {
      this.selectGroupDealer.empty();
      this.disableModalButton('.js-modal--reserve-enquire .btn');
      var error = $("<p>", {class: 'error'}).text("0 results found");
      $('.location-textbox.invalid .error-message .error').remove();
      $('.location-textbox.invalid .error-message').append(error);
    }

    if (searchButton) {
      searchButton.classList.remove('is-valid-search');
    }
  }
}

export default LocationDisplay;
