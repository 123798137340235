import bindAll from 'lodash/bindAll';
import ComponentBase from '../component-base';

/**
 * @class Carousel
 * @description A simple image carousel
 */
/*
Expected dom elements
<div class="image-carousel UNIQUE-REF">
  <div class="item-container">
    <div class="item-slider">
*/
class Carousel extends ComponentBase {
  constructor(options = {}) {
    super();
    this.id = options.id || 'default-carousel-id';
    this.handlebars = options.handlebars || false;
    this.breakpoints = options.breakpoints || 'none';

    // The current index for the carousel, note that the carousel display
    // will support displaying 2 items at a time (or more if needed), thus
    // the current index will be the item on the far left
    this.currentIndex = 0;
    // Lets set a default since the carousel should have at least 3 items
    // else what's the point for desktop
    this.numberItems = options.numberItems || 3;
    // This is the maximum number of items to use out of the number of items
    // provided, this will be the first items encountered in the list..
    // i.e. 1, 2, 3 of 10 items
    this.maxItems = options.maxItems || -1;
    // This is the number of scrollable items, which is dependant on the display
    // typed which affects the control of the next button
    this.maxScrollableItems = this.numberItems;
    // Setting the default item width this is computed for each resize
    this.itemWidth = 0;
    // Current Displayed number of items, this will be set when the window
    // resizes and any break point values are checked
    this.displayedItems = 1;
    // Set the transition type for the carousel
    this.transitionType = options.transition || 'simple';
    // An offset value to accommodate any padding and margines
    this.offsetValue = options.offsetValue || 0;
    this.calcOffset = options.calcOffset || false;

    // onChange Callback event
    this.onChangeCallback = options.onChange;

    this.init();
  }

  /**
   * @function init
   * @description Initializes the carousel
   */
  init() {
    super.init();
    bindAll(this,
      'handleItemClick',
      'handleSwipeLeft',
      'handleSwipeRight',
      'handleResize'
    );

    // This will retrieve and reference any elements for the carousel in the dom
    // This allows for unique element placemet without the component controlling
    // the rendering
    this.getElements();
    // Add a resize event to the window as we need to capture the main container
    // width so that we might calculate the correct slide value
    $(window).on('resize', this.handleResize);
    $(window).on('orientationchange', this.handleResize);

    if (this.handlebars) {
      // TODO
      // Load the handlebars into the component
    }

    // Check the button status based on the current selected item
    $(window).resize();
  }

  /**
   * @function getComputedWidth
   * @description Gets the computed width of the items
   */
  getComputedWidth() {
    const items = [...this.el.container.find('.vehicle-card')];
    this.itemWidth = $(items[0]).outerWidth() + 2;
    // items.forEach((el, i) => {
    //   console.log('ITEM WIDTHS', i, $(el).outerWidth());
    // });
    // this.dropShadowValue = 2;
    const parentWidth = $(items[0]).parent().outerWidth();

    // TODO this value yields 20, which doesn't work for the
    // accordion display
    const marginOffset = parentWidth - (this.itemWidth * this.displayedItems);
    if (this.calcOffset) {
      this.offsetValue = marginOffset;
    }
  }


  /**
   * @function checkAvailableItems
   * @description Checks the dom to determine the number of items available
   * for scrolling, this may not be the actuall number displayed for scrolling
   */
  checkAvailableItems() {
    const items = this.el.container.find('.slider-card').length;
    // Determine if a max number of items should be allow for scrolling
    if (this.maxItems === -1) {
      this.numberItems = items;
      return;
    }
    if (this.maxItems > items) {
      this.numberItems = items;
    } else {
      this.numberItems = this.maxItems;
    }
  }

  /**
   * @function generateDots
   * @description Generates the dots dynamically if needed
   */
  generateDots() {
    const container = $(`${this.id} .nav-dots`),
    array = [...Array(this.numberItems).keys()];
    array.forEach((item, i) => {
      container.append(`<li data-id="${i}" class="dot"></li>`);
    });
    this.el.dots = $(`${this.id} .dot`);
  }

  /**
   * @function getElements
   * @description Gets the reference to the component elements
   */
  getElements() {
    this.el = {
      root: $(this.id),
      previous: $(`${this.id} .previous`),
      next: $(`${this.id} .next`),
      dots: $(`${this.id} .dot`),
      container: $(`${this.id} .item-slider`)
    }


    // Append the transition type to the dom element
    this.el.root.addClass('transition-' + this.transitionType);

    // Check the Available dom scrollable images
    this.checkAvailableItems();

    // Generate the dots if none where included in the dom
    if (this.el.dots.length === 0) { this.generateDots(); }

    // Simpler than just using swipe and determining logic
    this.el.root.on('swipeleft', this.handleSwipeLeft);
    this.el.root.on('swipeRight', this.handleSwipeRight);

    this.el.previous.on('click', this.handleItemClick);
    this.el.next.on('click', this.handleItemClick);
    this.el.dots.on('click', this.handleItemClick);
  }

  /**
   * @function positionSlider
   * @description Positions the slider correctly based on the item width
   */
  positionSlider() {
    if (this.currentIndex === 0) {
      this.el.container.css('margin-left', '0');
    } else {
      const value = `-${this.itemWidth * this.currentIndex + (this.offsetValue * this.currentIndex)}px`;
      this.el.container.css('margin-left', value);
    }
  }

  /**
   * @function checkButtonStatus
   * @description Checks the status of the buttons to determine which ones
   * are allowed to be active based on the current carousel index
   */
  checkButtonStatus() {
    if (this.currentIndex === 0) {
      this.el.dots.removeClass('active');
      $(this.el.dots[0]).addClass('active');
      this.el.previous.addClass('disabled');
      this.el.next.removeClass('disabled');
    } else if ((this.currentIndex + 1) === this.maxScrollableItems) {
      this.el.dots.removeClass('active');
      $(this.el.dots[this.currentIndex]).addClass('active');
      this.el.previous.removeClass('disabled');
      this.el.next.addClass('disabled');
    } else {
      this.el.dots.removeClass('active');
      $(this.el.dots[this.currentIndex]).addClass('active');
      this.el.previous.removeClass('disabled');
      this.el.next.removeClass('disabled');
    }
  }

  /**
   * @function checkDisplayType
   * @description Checks the responsive display type and performs any necessary
   * corrections to the displayed buttons
   */
  checkDisplayType() {
    // TODO - To be extended for 3, 4, 5, 6, 7 items
    // If the display type is greater that 1 then we don't need as many buttons
    if (this.displayedItems === 2) {
      const dotsLength = this.el.dots.length;
      this.maxScrollableItems = dotsLength - 1;
      $(this.el.dots[dotsLength - 1]).addClass('hidden');
    } else {
      this.maxScrollableItems = this.numberItems;
      this.el.dots.removeClass('hidden');
    }
  }

  /**
   * @function handleResize
   * @description Handles the window resize event
   */
  handleResize(e) {
    const pageWidth = e.currentTarget.outerWidth;
    // Check to determine if there are breakpoints added
    if (this.breakpoints) {
      const breakpoints = Object.keys(this.breakpoints);
      breakpoints.forEach((item) => {
        const value = parseInt(item, 10);
        if (pageWidth >= value) {
          this.displayedItems = this.breakpoints[item].display;
        }
      });
    }
    this.getComputedWidth();
    this.checkDisplayType();
    this.positionSlider();
    this.checkButtonStatus();
  }

  /**
   * @function handleItemClick
   * @description Handles the next item click event, this might be either the
   * next previous buttons or the dots
   */
  handleItemClick(e) {
    const attr = $(e.currentTarget).attr('data-id');
    switch (attr) {
      case 'previous':
        this.currentIndex -= 1;
      break;
      case 'next':
        this.currentIndex += 1;
      break;
      default:
        const id = parseInt(attr, 10);
        this.currentIndex = id;
      break;
    }
    this.positionSlider();
    this.checkButtonStatus();
    if (this.onChangeCallback !== undefined) { this.onChangeCallback(this.currentIndex); }
  }

  /**
   * @function handleSwipeLeft
   * @description Handles the swipe left event
   */
  handleSwipeLeft(e) {
    if (this.currentIndex > 0) {
      this.currentIndex -= 1;
      this.positionSlider();
      this.checkButtonStatus();
      if (this.onChangeCallback !== undefined) { this.onChangeCallback(this.currentIndex); }
    }
  }

  /**
   * @function handleSwipeLeft
   * @description Handles the swipe left event
   */
  handleSwipeRight(e) {
    if (this.currentIndex < (this.numberItems - 1)) {
      this.currentIndex += 1;
      this.positionSlider();
      this.checkButtonStatus();
      if (this.onChangeCallback !== undefined) { this.onChangeCallback(this.currentIndex); }
    }
  }
}

export default Carousel;
