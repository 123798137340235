import bindAll from 'lodash/bindAll';
import { setTracyPage, setTracyEvent, setTracySearchFilter } from './../../tracy';

/**
 * @class TabbedContent
 * @description Built as per the W3C accessibility guidelines for tabbed content:
 * https://www.w3.org/TR/wai-aria-practices/#tabpanel
 */

class TabbedContent {
  constructor(options = {}) {
    this.keyValues = {
      left: "ArrowLeft",
      right: "ArrowRight",
      enter: "Enter",
      space: " "
    };
    this.init();
  }

  init() {
    bindAll(this,
      'addListeners',
      'handleClickEvent',
      'handleKeyUpEvent',
      'switchTabOnArrowPress',
      'displayTab',
      'hideTab',
      'focusFirstTab',
      'focusLastTab'
    );

    this.addListeners();
  };

  addListeners() {
    const tabLists = document.querySelectorAll('[role="tablist"]');

    tabLists.forEach(tabList => {
      const tabs = tabList.querySelectorAll('[role="tab"]');

      tabs.forEach(tab => {
        tab.addEventListener('click', this.handleClickEvent);
        tab.addEventListener('keyup', this.handleKeyUpEvent);

        const tabControl = tab.getAttribute('aria-controls');
        const tabPanel = document.getElementById(tabControl);
        const tabPanelVideos = tabPanel.querySelectorAll('.video');

        const tracyObject = {
          requestedURL: window.location.href,
          mediaType: 'video'
        };

        const addVideoSpecificPropertiesToTracyObject = (video) => {
          tracyObject.mediaName = video.dataset.series;
          tracyObject.mediaCurrentTime = video.currentTime;
          tracyObject.mediaTotalLength = video.duration;
        };

        tabPanelVideos.forEach(video => {
          video.addEventListener('play', (event) => {
            addVideoSpecificPropertiesToTracyObject(video);

            setTracyEvent('stock > media started', tracyObject);
          });

          video.addEventListener('pause', (event) => {
            if (video.currentTime === video.duration) return;

            addVideoSpecificPropertiesToTracyObject(video);

            setTracyEvent('stock > media stopped', tracyObject);
          });

          video.addEventListener('ended', (event) => {
            addVideoSpecificPropertiesToTracyObject(video);

            setTracyEvent('stock > media finished', tracyObject);
          });

          let videoMilestoneEvent = () => {
            const milestonePercentage = 50;
            const totalLength = video.duration;
            const currentTime = video.currentTime;
            const currentPercentage = Math.round((currentTime / totalLength) * 100);

            if (currentPercentage > milestonePercentage) {
              addVideoSpecificPropertiesToTracyObject(video);
              tracyObject.milestone = `${milestonePercentage}%`;

              setTracyEvent('stock > media milestone reached', tracyObject);

              clearInterval(checkVideoProgress);
            }
          };

          let checkVideoProgress = setInterval(videoMilestoneEvent, 1000);
        });
      });
    });
  };

  handleClickEvent(event) {
    const tab = event.target;

    this.displayTab(tab, false);
  };

  handleKeyUpEvent(event) {
    const keyCode = event.key;
    const parentTabList = event.target.closest('[role="tablist"]');

    switch (keyCode) {
      case this.keyValues.left:
      case this.keyValues.right:
        this.switchTabOnArrowPress(event, parentTabList);
        break;
      case this.keyValues.enter:
      case this.keyValues.space:
        this.displayTab(event.target);
        break;
    }
  };

  switchTabOnArrowPress(event, tabList) {
    const keyPressed = event.key;
    const tabs = tabList.querySelectorAll('[role="tab"]');
    const target = event.target;

    if (keyPressed === this.keyValues.right && target.nextElementSibling !== null) {
      target.nextElementSibling.focus();
    } else if (keyPressed === this.keyValues.left && target.previousElementSibling !== null) {
      target.previousElementSibling.focus();
    } else if (keyPressed === this.keyValues.left) {
      this.focusLastTab(tabs);
    } else if (keyPressed === this.keyValues.right) {
      this.focusFirstTab(tabs);
    }
  };

  displayTab(tab, setFocus) {
    const controls = tab.getAttribute('aria-controls');
    const tabPanel = document.getElementById(controls);
    const tabPanelVideos = document.querySelectorAll('.video');

    setFocus = setFocus || true;
    this.hideTab(tab);
    tab.removeAttribute('tabindex');
    tab.setAttribute('aria-selected', 'true');
    tabPanel.classList.remove('hide');

    if (setFocus) {
      tab.focus();
    }

    if (tab.classList.contains('content-has-been-viewed')) {
      return;
    }

    tabPanel.classList.add('content-has-been-viewed');

    if (tabPanelVideos.length === 0) {
      return;
    }

    const selectedVideoTab = tab.textContent.toLowerCase();
    const pageSection = document.querySelector('section[data-js-src]');
    const pageType = pageSection.dataset.jsSrc;
    const pageName = pageType === 'vehicle-results' ? 'search results' : 'vehicle details';

    const tracyObject = {
      linkName: `${selectedVideoTab} tab clicked`,
      requestedUrl: window.location.href,
      pageName: pageName,
    };

    setTracyEvent('stock > internal link clicked', tracyObject);

    tabPanelVideos.forEach(video => {
      video.pause();
    });
  };

  hideTab(tab) {
    const parentTabContainer = tab.closest('.tabbed-content');
    const parentTabList = parentTabContainer.querySelector('[role="tablist"]');
    const allTabs = parentTabList.querySelectorAll('[role="tab"]');
    const allPanels = parentTabContainer.querySelectorAll('[role="tabpanel"]');

    allTabs.forEach(tab => {
      tab.setAttribute('tabindex', '-1');
      tab.setAttribute('aria-selected', 'false');
    });

    allPanels.forEach(panel => {
      panel.classList.add('hide');
    });
  };

  focusFirstTab(tabs) {
    tabs[0].focus();
  };

  focusLastTab(tabs) {
    tabs[tabs.length - 1].focus();
  };
}

export default TabbedContent;
