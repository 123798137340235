import { setTracyPage } from './tracy';
import Page from './components/page/page';

class Enquire extends Page {
  constructor() {
    super();
    this.init();
  }

  init() {
    this.BRAND = $('html').data('brand');
    require('./vendor/enquire');
    const embeddedForm = document.getElementById('bmw-embedded-form');
    window.addEventListener('message', (ev) => {
      if (ev.data === 'FORM_LOADED') {
        let params = {};
        if ( window.enquiryData.modelTreeReference ) {
          params = { moiModelTreeReference: window.enquiryData.modelTreeReference, }
        } else {
          params = {
            moiBrand: window.enquiryData.brand,
            moiSeries: window.enquiryData.series,
            moiBodystyle: window.enquiryData.body,
          }
        }

        const currentBrand = $('html').data('brand').toUpperCase();
        const formCommentAirstrip = `Stock Locator Enquiry: #OrderNumber=${window.enquiryData.order_number}#${currentBrand}=Y`;
        const formComment = `Stock Locator Enquiry: #OrderNumber=${window.enquiryData.order_number}#${currentBrand}=N`;

        if (window.enquiryData.airstrip_stock === "Y") {
          console.log("Airstrip Stock - Adding brand to form comment");
          console.log(formCommentAirstrip)
        } else {
          console.log("Airstrip Stock - Removing brand from form comment");
          console.log(formComment)
        }

        let data = {
          ...params,
          retailerId: window.enquiryData.retailer_id,
          formComment: (window.enquiryData.airstrip_stock === "true" ? formCommentAirstrip : formComment)
        };

        embeddedForm.contentWindow.postMessage(data, '*');
      }
      else {

        try {

          const json = JSON.parse(ev.data);

          if (json && json.args[1].eventInfo.eventName === 'Form Success') {
            this.track('EnquireSubmit', {
              VehicleHandle: pixelTrackingData.vin_handle,
              VehicleName: pixelTrackingData.name
            });
          };

        }
        catch (error) {
          // not json
        };

      }
    });
    // Trigger Tracy page event
    setTracyPage(`stock > enquire`);

    this.track('EnquireForm', {
      VehicleHandle: pixelTrackingData.vin_handle,
      VehicleName: pixelTrackingData.name
    });

  }
}

export default Enquire;
