export const truncateTextToggle = (toggle) => {
  const truncateToggle = toggle;
  const truncateToggleText = truncateToggle.textContent;

  const truncateContent = truncateToggle.previousElementSibling;

  if (truncateContent.classList.contains('truncate')) {
    truncateToggle.textContent = 'Read less';
  } else {
    truncateToggle.textContent = 'Read more';
  }

  truncateContent.classList.toggle('truncate');
};
