import bindAll from 'lodash/bindAll';

/**
 * @class Tooltip
 * @description A simple tooltip component *
 */

const tipDefaultContent = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.';

class Tooltip {
  constructor(options = {}) {
    this.tip = null;
    this.init();
  }

  init() {
    bindAll(this,
      'show',
      'hide'
    );
  }

  show(parent, position, content = null, left ='10rem') {
    if (this.tip) {
      this.hide();
    }
    if (!this.tip) {
      const tip = this.render(content || tipDefaultContent, left);
      tip.appendTo(parent);

      const width = tip.width(),
      height = tip.height(),
      arrowLeft = 100;
      tip.css({
        left: Math.floor(position.left) - arrowLeft,
        top: Math.floor(position.top)
      });

      tip.on('click', () => { this.hide(); });
      this.tip = tip;
    }
    return this.tip;
  }

  hide() {
    if(this.tip) {
      this.tip.remove();
      this.tip = null;
    }
  }

  render(content, left) {
    const tip = `
      <div class="tooltip-container" style="--left-var: ${left};">
        <div class="tip-content">
          ${content}
        </div>
      </div>

    `;
    return $(tip);
  }
}

export default Tooltip;
