require("jquery-ui-dist/jquery-ui");
require('./vendor/touch-punch');
require('./vendor/owl.carousel.min');

import "core-js/stable";

import Header from './components/header/header';
import Footer from './components/footer/footer';
import CarSelector from './car-selector';
import SimilarSearch from './similar-search';
import Results from './results';
import SimilarResults from './similar-results';
import Compare from './compare';
import CarDetail from './car-detail';
import Enquire from './enquire';
import CookiePolicy from './cookie-policy';
import MarketingSearch from './marketing-search';
import Exclusive from './exclusive';
import { captureReferer } from './analytics';

const initialize = () => {
  const page = document.querySelectorAll('[data-js-src]')[0];

  // In order to track referrer when the user hasn't accepted cookies until they hit another page:
  epaas.api.registerOnConsentChange(captureReferer);
  epaas.api.registerOnInitialized(captureReferer);
  // end
  const header = new Header();
  const footer = new Footer();
  let marketingSearch = null;

  if ( page.dataset.jsSrc === 'vehicle-search' ) {
    const carSelector = new CarSelector();
  }

  if ( page.dataset.jsSrc === 'vehicle-similar-search' ) {
    const similarSearch = new SimilarSearch();
  }

  if ( page.dataset.jsSrc === 'vehicle-results' ) {
    const results = new Results();
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.has('ml')) {
      marketingSearch = new MarketingSearch();
      marketingSearch.renderHelpers($('html').attr('data-brand').toUpperCase());
    };
  }

  if ( page.dataset.jsSrc === 'vehicle-similar-results' ) {
    const results = new SimilarResults();
  }

  if ( page.dataset.jsSrc === 'compare' ) {
    require('./vendor/slick-carousel.smoother-transitions.custom');
    const results = new Compare();
  }

  if ( page.dataset.jsSrc === 'vehicle-detail' ) {
    const carDetail = new CarDetail();
  }

  if ( page.dataset.jsSrc === 'enquire' ) {
    const enquire = new Enquire();
  }

  if ( page.dataset.jsSrc === 'cookie-policy' ) {
    const cookiePolicy = new CookiePolicy();
  }

  if (page.dataset.jsSrc === 'marketing-search') {
    marketingSearch = new MarketingSearch();
  }

  if (page.dataset.jsSrc === 'exclusive-page') {
    const exclusive = new Exclusive();
  }

  // Fade out any flash messages
  $('.flash-message').delay(8000).fadeOut(200);

  // Refresh page to check localstorage
  $(window).bind("pageshow", function(event) {
    if (event.originalEvent.persisted) {
      window.location.reload()
    }
  });

  // If we are in touchscreen mode then add a class to the body so we can style accordingly
  if (window.touchscreenSession) {
    $("body").addClass("touchscreen-mode");
  }

};

$(document).on('DOMContentLoaded', initialize);
