import bindAll from 'lodash/bindAll';
import defaults from 'lodash/defaults';
import 'slick-carousel';

// import { analyticsAllowed, getCookie } from "./utils/cookie-helper";
import { slickSettings } from "./settings/slick-slider";
import { isMobile, isTablet, isDesktop} from "./utils/viewport-check";
import TooltipBasic from './components/tooltip-basic/tooltip-basic';
import SimpleDialog from './components/dialog/dialog';
import Accordion from './components/accordion/accordion';
import StickySidebar from './components/sticky-sidebar/sticky-sidebar';
import LazyLoadBackground from './components/lazy-load-background/lazy-load-background';
import EveVideos from './components/eve-videos/eve-videos';
// import LocationDisplay from './components/location-display/location-display';
import SearchTabs from './components/search-tabs/search-tabs';
import SearchSelects from "./components/search-selects/search-selects";
import PrepareSearch from './components/prepare-search/prepare-search';
import DisplayToggle from './components/display-toggle/display-toggle';
import { matchHeight } from "./utils/match-height";
// import RepexStateManagement from './components/repex-state-management/repex-state-management'; // TODO

import { setTracyPage, setTracyEvent, setTracySearchFilter } from './tracy';

const BRAND = document.querySelector('html').className;

const UPHOLSTERY = {
  'LEATHER': 'Leather interior',
  'INDIVIDUALLEATHER': 'Leather interior',
  'FABRIC': 'Fabric interior',
  'OTHER': 'Other'
}

const selectors = {
  locationModalLabel: '.js-location-modal-control',
  compareVehicleButton: '.js-similar-compare',
  compareMainSlider: '.tile-container-main-slider',
  compareHeaderSlider: '.tile-container-sticky-slider',
};

class Results {
  constructor(options = {}) {
    this.bindAll();
    this.options = defaults({}, options, selectors);
    this.init();
  };

  bindAll() {
    bindAll(this,
      'imageSlider',
      'compareSlider',
      'handleWindowResize',
      'appendLocationContent',
      'addEventListeners',
      'handleLocationModalLabelClick',
      'handleCompareButtonClick',
      'destroyCompareSlider',
      'resetSliderPosition',
      'applyClassAndResetCarouselOnScroll',
      'calculateScrollbarWidth'
    );
  }

  init() {
    this.resultsSection = document.querySelector('section[data-js-src="vehicle-similar-results"]');
    this.eveContentBaseUrl = this.resultsSection.dataset.eveContentBaseUrl;
    this.eveApiBaseUrl = this.resultsSection.dataset.eveApiBaseUrl;
    this.tooltip = new TooltipBasic();
    this.eveVideos = new EveVideos();

    this.stickySidebar = new StickySidebar({
      sidebarClass: '.sidebar',
      containerClass: '.results-split'
      // offsetHeight: 0
    });

    this.locationModal = new SimpleDialog({
      id: 'location-modal'
    });

    this.compareModal = new SimpleDialog({
      id: 'compare-modal'
    });

    const searchSelects = new SearchSelects();
    const searchTabs = new SearchTabs();
    const prepareSearch = new PrepareSearch();
    const locationDisplaySidebarToggle = new DisplayToggle();

    this.compareMainSlider = $(this.options.compareMainSlider);
    this.compareHeaderSlider = $(this.options.compareHeaderSlider);


    // setTracyPage(`stock > ${this.BRAND} > search result`);


    this.imageSlider();
    // this.initCompareCarousel();
    this.addEventListeners();
    this.appendLocationContent();
    this.configurationToggle();
    this.handleWindowResize();
    this.compareSlider();
    this.handleModalScroll();
    this.calculateScrollbarWidth();
    // this.matchHeightOfTextComponents();
  }

  // matchHeightOfTextComponents() {
    // const colourTextElement = document.querySelectorAll('.js-match-height-colour');

    // matchHeight(colourTextElement);
  // }

  handleWindowResize() {
    window.addEventListener('resize', () => {
      this.appendLocationContent();
      this.configurationToggle();
      // this.matchHeightOfTextComponents();
      this.compareMainSlider.slick('setPosition');
      this.compareHeaderSlider.slick('setPosition');

      if (isMobile()) {
        this.compareSlider();
      }
    });
  }

  destroyCompareSlider() {
    const slider = $('.tile-container-main-slider');
    const stickyHeaderSlider = $('.tile-container-sticky-slider');

    slider.slick('unslick');
    stickyHeaderSlider.slick('unslick');
  }

  addEventListeners() {
    const locationModalLabel = document.querySelector(this.options.locationModalLabel);
    const compareButtons = document.querySelectorAll(this.options.compareVehicleButton);

    locationModalLabel.addEventListener('click', this.handleLocationModalLabelClick);

    compareButtons.forEach(button => {
      button.addEventListener('click', this.handleCompareButtonClick);
    });
  }

  configurationToggle() {
    const configuredVehicleSpecification = document.querySelector('.js-toggle-configuration-target');

    if (isMobile()) {
      configuredVehicleSpecification.classList.add('hide');
      configuredVehicleSpecification.classList.remove('show');
    } else {
      configuredVehicleSpecification.classList.remove('hide');
      configuredVehicleSpecification.classList.add('show');
    }
  }

  appendLocationContent() {
    const locationStaticContainer = document.querySelector('.location-static-container');
    const locationModalContainer = document.querySelector('#location-modal .inner');
    const locationFields = document.querySelector('.filter-location.js-toggle-location-target');
    const modalCloseButton = document.querySelector('#location-modal .bt-close');

    if (!isDesktop()) {
      locationModalContainer.appendChild(locationFields);
      locationFields.classList.remove('hide');
      locationFields.classList.add('show');
    } else {
      locationStaticContainer.appendChild(locationFields);
      locationFields.classList.add('hide');
      locationFields.classList.remove('show');
      modalCloseButton.click();
    }
  }

  handleLocationModalLabelClick(event) {
    event.preventDefault();
    this.locationModal.show();
  }

  resetSliderPosition() {
    this.compareMainSlider.slick('setPosition');
    this.compareHeaderSlider.slick('setPosition');
  }

  handleCompareButtonClick(event) {
    event.preventDefault();

    const vehicleData = {
      'range': event.target.dataset.vehicleRange,
      'description': event.target.dataset.vehicleDescription,
      'price': event.target.dataset.vehiclePrice,
      'distance': event.target.dataset.vehicleDistance,
      'dealerName': event.target.dataset.vehicleDealerName,
      'image': event.target.dataset.vehicleImage,
      'link': event.target.dataset.vehicleLink,
      'colour': event.target.dataset.vehicleColour,
      'fuel': event.target.dataset.vehicleFuel,
      'transmission': event.target.dataset.vehicleTransmission,
      'driveType': event.target.dataset.vehicleDriveType,
      'engineSize': event.target.dataset.vehicleEngineSize,
      'cylinders': event.target.dataset.vehicleCylinders,
      'upholstery': event.target.dataset.vehicleUpholstery,
      'power': event.target.dataset.vehiclePower,
      'wheels': event.target.dataset.vehicleWheels,
      'acceleration': event.target.dataset.vehicleAcceleration,
      'speed': event.target.dataset.vehicleSpeed,
      'torque': event.target.dataset.vehicleTorque,
      'seats': event.target.dataset.vehicleSeats,
      'weight': event.target.dataset.vehicleWeight,
      'match': event.target.dataset.vehicleMatch,
      'packages': event.target.dataset.vehiclePackages,
      'dealerPrice': event.target.dataset.vehicleDealerPrice
    }

    this.populateModalWithData(vehicleData);
    this.compareModal.show();
    this.resetSliderPosition();
    const compareAccordion = new Accordion({ id: 'compare-accordion' });
  }

  imageSlider() {
    $('.slider').slick(slickSettings.similarResultsPage.imageSlider);
    new LazyLoadBackground();
  }

  handleModalScroll() {
    const comparisonModal = document.querySelector('.compare-modal');

    comparisonModal.addEventListener('scroll', () => {
      this.applyClassAndResetCarouselOnScroll();
      requestAnimationFrame(this.applyClassAndResetCarouselOnScroll);
    }, {
      passive: true
    });
  }

  applyClassAndResetCarouselOnScroll() {
    const body = document.body;
    const scrollThreshold = -170;
    const mainSlider = $('.tile-container-main-slider'); // Slick slider can only be initialized with jQuery
    const stickySlider = $('.tile-container-sticky-slider'); // Slick slider can only be initialized with jQuery
    const mainSliderVanilla = document.querySelector('.tile-container-main-slider');
    const bound = mainSliderVanilla.getBoundingClientRect();

    if (bound.top < scrollThreshold) {
      if (body.classList.contains('compare-scrolled')) {
        return
      }

      body.classList.add('compare-scrolled');
      mainSlider.slick('setPosition');
      stickySlider.slick('setPosition');
    } else {
      if (!body.classList.contains('compare-scrolled')) {
        return;
      }

      body.classList.remove('compare-scrolled');
    }
  }

  calculateScrollbarWidth() {
    document.documentElement.style.setProperty('--scrollbar-width', (window.innerWidth - document.documentElement.clientWidth) + "px");
  }

  compareSlider() {
    const slider = $('.tile-container-main-slider');
    const stickyHeaderSlider = $('.tile-container-sticky-slider');

    slider.slick(slickSettings.similarResultsPage.compareSlider.mainSlider);
    stickyHeaderSlider.slick(slickSettings.similarResultsPage.compareSlider.stickyHeader);

    slider.on('breakpoint', (event, slick, breakpoint) => {
      slider.slick('setPosition');
      stickyHeaderSlider.slick('setPosition');
    });

    slider.on('beforeChange', (event, slick, currentSlide, nextSlide) => {
      slider.attr('data-slide', nextSlide);
      stickyHeaderSlider.attr('data-slide', nextSlide);
    });
  }

  populateModalWithData(data) {
    const images = document.querySelectorAll('.js-vehicle-compare-image');
    const ranges = document.querySelectorAll('.js-vehicle-compare-range');
    const descriptions = document.querySelectorAll('.js-vehicle-compare-description');
    const dealerNames = document.querySelectorAll('.js-vehicle-compare-dealer-name');
    const fuelTypes = document.querySelectorAll('.js-vehicle-compare-fuel');
    const driveTypes = document.querySelectorAll('.js-vehicle-compare-drive-type');
    const matchValues = document.querySelectorAll('.js-vehicle-compare-match');
    const retailerOffer = document.querySelectorAll('.js-vehicle-compare-offer');
    const price = document.querySelector('.js-vehicle-compare-price');
    const distance = document.querySelector('.js-vehicle-compare-distance');
    const links = document.querySelectorAll('.js-vehicle-compare-link');
    const colour = document.querySelector('.js-vehicle-compare-colour');
    const transmission = document.querySelector('.js-vehicle-compare-transmission');
    const wheels = document.querySelector('.js-vehicle-compare-wheels');
    const upholstery = document.querySelector('.js-vehicle-compare-upholstery');
    const engineSize = document.querySelector('.js-vehicle-compare-engine-size');
    const cylinders = document.querySelector('.js-vehicle-compare-cylinders');
    const power = document.querySelector('.js-vehicle-compare-power');
    const acceleration = document.querySelector('.js-vehicle-compare-acceleration');
    const speed = document.querySelector('.js-vehicle-compare-speed');
    const torque = document.querySelector('.js-vehicle-compare-torque');
    const seats = document.querySelector('.js-vehicle-compare-seats');
    const weight = document.querySelector('.js-vehicle-compare-weight');
    const packages = document.querySelector('.js-vehicle-compare-packages');

    images.forEach(image => {
      image.src = data.image;
      image.alt = `${data.range} image`;
    });

    ranges.forEach(range => {
      range.innerHTML = `<h2 class="tile__range">${data.range}</h2>`;
    });

    descriptions.forEach(description => {
      description.textContent = data.description;
    });

    dealerNames.forEach(dealer => {
      dealer.textContent = data.dealerName;
    });

    fuelTypes.forEach(type => {
      type.textContent = data.fuel;
    });

    driveTypes.forEach(type => {
      type.textContent = data.driveType;
    });

    links.forEach(link => {
      link.href = data.link;
    });

    matchValues.forEach(value => {
      value.textContent = `${data.match}%`;
    });

    const similarVehiclePackages = JSON.parse(data.packages);
    let similarVehiclePackagesList = '';

    similarVehiclePackages.forEach(item => {
      similarVehiclePackagesList += `<li><span></span><span>${item.title}</span></li>`;
    });

    retailerOffer.forEach(item => {
        item.setAttribute("data-dealerprice", data.dealerPrice)
    })

    packages.innerHTML = similarVehiclePackagesList;
    price.textContent = data.price;
    distance.textContent = data.distance;
    transmission.textContent = data.transmission ? data.transmission : '-';
    wheels.textContent = data.wheels ? data.wheels : '-';
    colour.textContent = data.colour ? data.colour : '-';
    upholstery.textContent = data.upholstery ? data.upholstery : '-';
    engineSize.textContent = data.engineSize ? `${data.engineSize} cc` : '-';
    cylinders.textContent = data.cylinders ? data.cylinders : '-';
    power.textContent = data.power ? data.power : '-';
    acceleration.textContent = data.acceleration ? `${data.acceleration} secs` : '-';
    speed.textContent = data.speed ? `${data.speed} MPH` : '-';
    torque.textContent = data.torque ? `${data.torque} Nm`: '-';
    seats.textContent = data.seats ? data.seats : '-';
    weight.textContent = data.weight ? `${data.weight} kg` : '-';
  }
}

export default Results;
