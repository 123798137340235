import bindAll from 'lodash/bindAll';
import ComponentBase from '../component-base';

/**
 * @class Finance Display
 * @description A simple display for finance calculations and selection
 */

class FinanceDisplay extends ComponentBase {
  constructor(options = {}) {
    super();
    this.id = options.id || 'default-finance-display-id';

    this.selectTerm = $(`#${this.id} .select-month-boxes li`);
    this.rangeValue = $(`#${this.id} .slider-deposit .range-value`);
    this.sliderDeposit = $(`#${this.id} .slider-deposit .js-range-slider`);

    this.rangeValueMileage = $(`#${this.id} .slider-annual-mileage .range-value`);
    this.sliderMileage = $(`#${this.id} .slider-annual-mileage .js-range-slider`);

    // Data dispatcher method
    this.dispatchData = options.dispatchData;

    this.userValues = options.userValues;

    this.init();
  }

  /**
   * @function init
   * @description Initialize the finance display, activate components and bind
   * any events
   */
  init() {
    super.init();
    bindAll(this,
      'handleBoxSelect',
      'handleSliderStopped',
      'updateMileageSlider'
    );
    this.selectTerm.on('click', this.handleBoxSelect);
    this.initSliders();
    this.showActiveTerm();
  }

  showActiveTerm() {
    // Simulating a click on load so we don't have to write the logic to select the item again
    $(`.select-month-boxes [data-value="${this.userValues.term}"]`).trigger('click');
  }

  /**
   * @function handleSliderStopped
   * @description Handles the slider stopped event to execute and required callbacks
   */
  handleSliderStopped(values) {
    if (this.dispatchData) {
      this.dispatchData(values);
    }
  }

  /**
   * @function handleBoxSelect
   * @description Handles the term selection box click event
   */
  handleBoxSelect(e) {
    const target = $(e.currentTarget);
    this.selectTerm.removeClass('selected');
    target.addClass('selected');
    this.dispatchData({
      term: parseInt(target.attr('data-value'), 10)
    });

    this.updateMileageSlider(target.attr('data-max'));
  }

  updateMileageSlider(max) {
    // Update the max of the slider based on the selected term
    this.sliderMileage.slider("option", "max", max);

    // Check if the current slider value is more than the new max.
    // If it is then set the value to the same as the max
    const currentValue = this.sliderMileage.slider("option", "value");

    if (currentValue > max) {
      this.sliderMileage.slider("option", "value", max);
      const maxValue = parseInt(max).toLocaleString();
      $('.slider-annual-mileage .range-value').text(`${maxValue} miles`);
      this.dispatchData({
        mileage_max: max
      });

      this.showMessage(maxValue);
    } else {
      $('.am-message').remove();
    }
  }

  showMessage(amount) {
    // Remove any existing message
    $('.am-message').remove();

    // Add new message
    const message =
      `We have adjusted your annual mileage to ${amount}, which is
      the maximum for the term you have requested`;

    $('.finance-display').append(`<p class="am-message">${message}</p>`);
  }

  /**
   * @function initSliders
   * @description Initialize the sliders for the budget display
   */
  initSliders() {
    this.createSlider(
      this.sliderDeposit,
      {
        //initText: '£4500',
        range: 'min',
        step: 100,
        max: 10000,
        value: this.userValues.deposit_max || window.finance.default_deposit,
        formatDisplay(ui) {
          return `£${ui.value.toLocaleString()}`;
        },
        onStop: (value) => { this.handleSliderStopped({
            //deposit_min: values[0],
            deposit_max: value
          });
        }
      },
      this.rangeValue
    );
    this.createSlider(
      this.sliderMileage,
      {
        //initText: '50,000 miles',
        range: 'min',
        min: 6000,
        max: 50000,
        step: 1000,
        value: this.userValues.mileage_max || 50000,
        formatDisplay(ui) {
          return `${ui.value.toLocaleString()} miles`;
         },
        onStop: (value) => { this.handleSliderStopped({
            //mileage_min: values[0],
            mileage_max: value
          });

          // Remove any validation messages
          $('.am-message').remove();
        }
      },
      this.rangeValueMileage
    );
  }
}

export default FinanceDisplay;
