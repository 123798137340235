import { setTracyEvent } from '../../tracy';

class Footer {
  constructor() {
    this.init();
  }

  init() {
    this.BRAND = $('html').data('brand');

    $('.footer .social li a').on('click', this.handleFooterSocialClick.bind(this));
    $('.footer .single-footer-link').on('click', this.handleFooterLinkClick.bind(this));
  }

  handleFooterSocialClick(e) {
    /** [TRACY_EVENT] **/
    const ref = `stock > ${this.BRAND} > social footer`;
    setTracyEvent(ref);
    /** [END] **/
  }

  handleFooterLinkClick(e) {
    /** [TRACY_EVENT] **/
    const ref = `stock > ${this.BRAND} > footer ${this.BRAND} link`;
    setTracyEvent(ref);
    /** [END] **/
  }
}

export default Footer;
