export const captureEnquireEvent = (e) => {
  try {
    window.dataLayer.push(window.gtag_vehicle_enquiry_event)
  } catch(error) {
    console.error(error);
  }
}

export const captureReserveEvent = (e) => {
  try {
    window.dataLayer.push(window.gtag_reserve_vehicle_event)
  } catch(error) {
    console.error(error);
  }
}

export const captureAirstripLinkEvent = (e) => {
  try {
    window.dataLayer.push(window.gtag_airstrip_link_event)
  } catch(error) {
    console.error(error);
  }
}

export const captureVehicleDetailsLoadEvent = (e) => {
  try {
    window.dataLayer.push(window.gtag_vehicle_event)
  } catch(error) {
    console.error(error);
  }
}

export const captureListVehiclesEvent = () => {
  try {
    if (window.gtag_no_results_event != null) {
      window.dataLayer.push(window.gtag_no_results_event)
    }

    window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object
    window.dataLayer.push(window.gtag_list_vehicles_event)
  } catch(error) {
    console.error(error);
  }
}

export const captureRefinedSearchEvent = (e) => {
  try {
    window.dataLayer.push(window.gtag_refined_search_event)
  } catch(error) {
    console.error(error);
  }
}

export const captureSearchEvent = (e) => {
  try {
      window.dataLayer.push(window.gtag_search_event)
  } catch(error) {
    console.error(error);
  }
}

export const captureReferer = (e) => {
  // To fix the epaas issue that referrer doesn't get loaded until epaas is excepted, we use localstorage
  try {
    let host = window.location.host
    let referrerKey = "ncl_referrer"
    let newReferrer = document.referrer
    if (!newReferrer.includes(host)) {
      localStorage.setItem(referrerKey, newReferrer)
    }
    if (epaas.api.isUsageAllowed("GoogleAnalytics") && localStorage.getItem(referrerKey) !== 'null') {
      window.dataLayer.push({"event": "custom_referrer", "domain": localStorage.getItem(referrerKey)})
      localStorage.setItem(referrerKey, null)
    }
  } catch(error) {
    console.error(error);
  }
}
