import bindAll from 'lodash/bindAll';

/**
 * @class DisplayToggle
 * @description
 * Simple display toggle class to add and remove "hide" class
 * based on data attributes
 *
 * Usage:
 *
 * <button class="js-toggle-control" data-toggle-target="my-class"><button>
 * <div class="my-class"></div>
 */

class DisplayToggle {
  constructor(options = {}) {
    this.init();
  }

  init() {
    bindAll(this,
      'addEventListeners',
      'toggleClasses'
    );

    this.addEventListeners();
  }

  addEventListeners() {
    const toggleControl = document.querySelectorAll('.js-toggle-control');

    toggleControl.forEach(toggle => {
      toggle.addEventListener('click', () => {
        const toggleTarget = toggle.dataset.toggleTarget;

        this.toggleClasses(toggleTarget, toggle);
      });
    });
  }

  toggleClasses(target, control) {
    const targetElements = document.querySelectorAll(target);

    targetElements.forEach(element => {
      element.classList.toggle('hide');
      element.classList.toggle('show');
    });

    control.classList.toggle('is-active');
  }
}

export default DisplayToggle;
