import bindAll from 'lodash/bindAll';
import defaults from 'lodash/defaults';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import values from 'lodash/values';
import reduce from 'lodash/reduce';
import assign from 'lodash/assign';
import cloneDeep from 'lodash/cloneDeep';
import 'slick-carousel';

import Page from './components/page/page';
import { filterVehicles } from './utils/data-parsing';
import { querySearchParams, queryFormatter } from './utils/query-formatter';
import { analyticsAllowed, getCookie } from "./utils/cookie-helper";
import TooltipBasic from './components/tooltip-basic/tooltip-basic';
import TabbedContent from './components/tabbed-content/tabbed-content';
import SimpleDialog from './components/dialog/dialog';

// Alternate Carousel since Slick carousel could not accommodate the
// requirements of the carousel on the results page above the results
import Tooltip from './components/tooltip/tooltip';
import Carousel from './components/carousel/carousel';
import Accordion from './components/accordion/accordion';
import BudgetDisplay from './components/budget-display/budget-display';
import LocationDisplay from './components/location-display/location-display';
import FinanceDisplay from './components/finance-display/finance-display';
import StickySidebar from './components/sticky-sidebar/sticky-sidebar';
import LazyLoadBackground from './components/lazy-load-background/lazy-load-background';
import CompareVehicles from './components/compare-vehicles/compare-vehicles';
import UpdateWebComponent from './components/update-web-components/update-web-components';

import icon_compare_BMW from 'images/bmw/compare.svg';
import icon_info_BMW from 'images/bmw/info.svg';
import icon_info_primary_BMW from 'images/bmw/info-primary-inverted.svg';
import icon_info_secondary_BMW from 'images/bmw/info-secondary.svg';
import icon_tick_BMW from 'images/bmw/tick-green.svg';
import icon_pin_BMW from 'images/bmw/location-pin-condensed.svg';
import icon_blue_arrow_BMW from 'images/bmw/arrow-right-blue.svg';

import icon_compare_MINI from 'images/mini/compare.svg';
import icon_info_MINI from 'images/mini/info.svg';
import icon_info_secondary_MINI from 'images/mini/info.svg';
import icon_tick_MINI from 'images/mini/tick.svg';
import icon_pin_MINI from 'images/mini/location-pin.svg';

import keyinfo_fuel_BMW from 'images/bmw/keyinfo_Fuel.svg';
import keyinfo_gearbox_BMW from 'images/bmw/keyinfo_Gearbox.svg';
import keyinfo_interior_BMW from 'images/bmw/keyinfo_Interior.svg';
import keyinfo_colour_BMW from 'images/bmw/keyinfo_Paint_Colour.svg';
import keyinfo_seats_BMW from 'images/bmw/keyinfo_Seats.svg';
import keyinfo_drivetrain_BMW from 'images/bmw/keyinfo_Drivetrain.svg';
import keyinfo_wheels_BMW from 'images/bmw/keyinfo-wheel.svg';

import keyinfo_fuel_MINI from 'images/mini/keyinfo_Fuel.svg';
import keyinfo_gearbox_MINI from 'images/mini/keyinfo_Gearbox.svg';
import keyinfo_interior_MINI from 'images/mini/keyinfo_Interior.svg';
import keyinfo_colour_MINI from 'images/mini/keyinfo_Paint_Colour.svg';
import keyinfo_seats_MINI from 'images/mini/keyinfo_Seats.svg';
import keyinfo_drivetrain_MINI from 'images/mini/keyinfo_Drivetrain.svg';
import keyinfo_wheels_MINI from 'images/mini/keyinfo-wheel.svg';

import icon_pin from 'images/global/location-marker.svg';

import { setTracyPage, setTracyEvent, setTracySearchFilter } from './tracy';
import { captureListVehiclesEvent, captureRefinedSearchEvent } from './analytics';

import ResultsFilter from './results-filter';
import { forEach } from 'lodash';

let IMAGES = { pin: icon_pin };
const BRAND = document.querySelector('html').className;

if (BRAND === 'bmw') {
  IMAGES.compare = icon_compare_BMW;
  IMAGES.info = icon_info_BMW;
  IMAGES.info_primary = icon_info_primary_BMW;
  IMAGES.info_secondary = icon_info_secondary_BMW;
  IMAGES.tick = icon_tick_BMW;
  IMAGES.pin = icon_pin_BMW;
  IMAGES.arrow = icon_blue_arrow_BMW;
} else {
  IMAGES.compare = icon_compare_MINI;
  IMAGES.info = icon_info_MINI;
  IMAGES.info_primary = icon_info_MINI;
  IMAGES.info_secondary = icon_info_secondary_MINI;
  IMAGES.tick = icon_tick_MINI;
  IMAGES.pin = icon_pin_MINI;
}

let KEYINFO = {};
if (BRAND === 'bmw') {
  KEYINFO.fuel = keyinfo_fuel_BMW;
  KEYINFO.gearbox = keyinfo_gearbox_BMW
  KEYINFO.interior = keyinfo_interior_BMW
  KEYINFO.colour = keyinfo_colour_BMW
  KEYINFO.seats = keyinfo_seats_BMW
  KEYINFO.drivetrain = keyinfo_drivetrain_BMW
  KEYINFO.wheels = keyinfo_wheels_BMW
} else {
  KEYINFO.fuel = keyinfo_fuel_MINI;
  KEYINFO.gearbox = keyinfo_gearbox_MINI
  KEYINFO.interior = keyinfo_interior_MINI
  KEYINFO.colour = keyinfo_colour_MINI
  KEYINFO.seats = keyinfo_seats_MINI
  KEYINFO.drivetrain = keyinfo_drivetrain_MINI
  KEYINFO.wheels = keyinfo_wheels_MINI
}

const UPHOLSTERY = {
  'LEATHER': 'Leather interior',
  'INDIVIDUALLEATHER': 'Leather interior',
  'FABRIC': 'Fabric interior',
  'OTHER': 'Other'
}

const
  offerTemplate = require("./templates/offers.hbs"),
  vehicleTemplate = require("./templates/vehicle.hbs");

const settings = {
  loader: '.app-loader',
  resultsPage: 'div.results',
  termsToggle: '.results .js-terms-toggle',
  termsText: '.results .finance-terms-text',
  sortMenu: '.results .results-sort',
  sortBy: '.results .results-sortby',
  sortByOption: '.results .results-sort-list li',
  sortOptions: {
    'location': 'Location',
    'price_asc': 'Price (lowest)',
    'price_desc': 'Price (highest)',
    'model': 'Model'
  },
  offers: '.results .results-offer-wrapper',
  results: '.results .vehicle-card-wrapper',
  filterSections: '.results .filter-section',
  filterHeaders: '.results .filter-header',
  filterMenuBtn: '.filters-menu .btn',
  filterActionsBtn: '.filter-actions-mobile .btn',
  filterMainItem: '.main-filters .filter-checklist li',
  headerFilter: '.main-filters .modify-filter-header',
  modalConfirm: '.modify-filter-confirmation',
  // Additional Filters
  filterAdditionalItem: '.additional-filters .filter-checklist li',
  // TODO - Not confirmed in the design yet
  filtersClearBtn: '.bt-clear-filters',
  filtersRefineBtn: '.bt-refine-search',
  compareLink: '.results .results-compare',
  compareVehicles: '.compare .vehicles',
  resultsCarousel: '.results .results-carousel',
  modalClose: '.modal .close',
  modalCompare: '.modal .compare',
  modalContainer: '.modal.modal-wrapper',
  compareCount: '.js-compare-count',
  financeExampleHeader: '.finance-example-header'
};

const filterTemplate = {
  colorCluster: [],
  paintType: [],
  fuelType: [],
  upholsteryType: [],
  driveType: [],
  transmission: [],
  packs: [],
  optionGroups: [],
  Exterior: [],
  Interior: [],
  Technology: [],
  AlloyWheels: [],
};

class Results extends Page {
  constructor(options = {}) {
    super();
    this.bindAll();
    this.options = defaults({}, options, settings);

    // Options DISTANCE, DEALER
    // NOTE THIS IS NOT RELATED TO THE DEALER_VIEW which provides a different
    // path for the request altogether
    this.requestMode = 'DISTANCE';

    const userFilterValues = window.localStorage.getItem('additional_filters');
    const additionalFilters = JSON.parse(userFilterValues);

    const anyFilter = reduce(additionalFilters, (isPresent, value, key) => {
      if ( key == 'id' ) { return isPresent };
      return (isPresent || !isEmpty(value));
    }, false);

    if (anyFilter && additionalFilters.id === this.createSearchHash()) {
      // Only do this if URL matches hash in local storage
      this.userFilterOptions = additionalFilters;
      this.getAdditionalFilterData();
      this.persistAdditionalFilters();
    }  else {
      this.buildNewFiltersObject();
    }


    // The active user sort on the results data
    this.userSortBy = 'location';
    // The is a display requirement to only highlight the tier_twos applicable
    // to the selected tier_ones, thus when a tier_one is selected, the related
    // tier_twos are stored here. NOTE that this is NOT a tier_two selection list
    this.userSelections = {
      // SPECIAL NOTE
      // These values need to be INT
      tier_ones: [],
      tier_twos_displayed: [],
      tier_twos: [],
      tier_threes_displayed: [],
      tier_threes: [],
      // Need the labels to display in the collapsed accordion header
      tier_ones_labels: [],
      tier_twos_labels: [],
      tier_threes_labels: []
    };
    this.selectedMainFilters = {
      tier_ones: [],
      tier_twos_displayed: [],
      tier_twos: [],
      tier_threes_displayed: [],
      tier_threes: [],
      // Need the labels to display in the collapsed accordion header
      tier_ones_labels: [],
      tier_twos_labels: [],
      tier_threes_labels: []
    };
    this.init();
  };

  bindAll() {
    bindAll(this,
      'updateRequestMode',
      'checkFinancePanelStatus',
      'updateRequestState',
      'findIds',
      'setAvailableTiers',
      'updateFilterHeaders',
      'parseVehicleGroupData',
      'handleSortByClick',
      'handleSortByOptionClick',
      'handleFilterHeaderClick',
      'handleFilterMenuClick',
      'handleMainFilterClick',
      'handleAdditionalFilterClick',
      'handleRefineSearch',
      'handleTermsToggleClick',
      'handleStickyNav',
      'handleBreakpointUpdate',
      'handleCompareButtonClick',
      'handleFinanceExampleToggle',
      'updateResultCompareIcons',
      'handleCompareLinkClick',
      'handleBaseToolTip',
      'handleCompareTooltips',
      'handleResize',
      'handleCardClick',
      'handleEnquireClick',
      'handleReserveClick',
      'buildNewFiltersObject',
      'persistAdditionalFilters',
      'buildEnquiryTooltip',
      'buildEnuiryPageLink',
      'loadSlickSlider',
      'handleReadMore',
      'addExtraOrderLists',
      'filterShowMore',
      'handleClearFiltersClick',
      'getAdditionalFilterData',
      'performRefinedSearch',
      'setUserSelectedVehiclesinQuerySearchParams',
      'addVideoModalEventListeners',
      'handleVideoLabelClick',
      'fetchEveData',
      'buildEveVideoUrls',
      'displayEveVideoTabs',
      'displayEveVideoErrorMessage'
    );
  }

  init() {
    this.BRAND = $('html').data('brand');
    this.resultsSection = document.querySelector('section[data-js-src]');
    this.eveContentBaseUrl = this.resultsSection.dataset.eveContentBaseUrl;
    this.eveApiBaseUrl = this.resultsSection.dataset.eveApiBaseUrl;
    this.hiddenItems = []
    // Execute the parse vehicle function to parse the vehicle group
    // data into a desired format..
    this.vehicleTierTree = null;
    this.parseVehicleGroupData();

    // Check to determine if there were any user selections
    // from the search page
    if (window.userSelections) {

      // NOTE
      // Not really keen on this a simple Object.assign would have been nice.
      // consider using the this.userRefinedSearch object format in the index.html.erb
      // for the window.userSelections
      // this way it can be used directly. However leave for now until all
      // variables are available from the backend.....

      this.userSelections.tier_ones = window.userSelections.tier_ones;
      this.userSelections.tier_ones_labels = window.userSelections.tier_ones_labels;
      this.userSelections.tier_twos = window.userSelections.tier_twos;
      this.userSelections.tier_twos_labels = window.userSelections.tier_twos_labels;
      // FOR MINI ONLY
      // These arrays should be empty for BMW, in the event the undefined set
      // them to empty arrays
      this.userSelections.tier_threes = window.userSelections.tier_threes || [];
      this.userSelections.tier_threes_labels = window.userSelections.tier_threes_labels || [];

      // Max Min For Budget
      querySearchParams.min = window.userSelections.min;
      querySearchParams.max = window.userSelections.max;
      querySearchParams.monthly_min = window.userSelections.monthly_min;
      querySearchParams.monthly_max = window.userSelections.monthly_max;
      // For finance
      querySearchParams.finance = window.userSelections.finance;
      querySearchParams.term = window.userSelections.term;
      querySearchParams.deposit_min = window.userSelections.deposit_min;
      querySearchParams.deposit_max = window.userSelections.deposit_max;
      querySearchParams.mileage_min = window.userSelections.mileage_min;
      querySearchParams.mileage_max = window.userSelections.mileage_max;

      querySearchParams.dealer_search = window.userSelections.dealer_search;

      querySearchParams.dealer_number = window.userSelections.dealer_number;

      querySearchParams.distance = window.userSelections.distance;
      querySearchParams.lat = window.userSelections.lat;
      querySearchParams.long = window.userSelections.long;

      querySearchParams.postcode = window.userSelections.postcode;
      querySearchParams.lat = window.userSelections.lat;
      querySearchParams.long = window.userSelections.long;
      this.setUserSelectedVehiclesinQuerySearchParams()

      let { getAdditionalFilterData, handleRefineSearch } = this;
      this.resultsFilter = new ResultsFilter( {
        getAdditionalFilterData,
        handleRefineSearch,
        querySearchParams
      });
      this.setFilterSelections();
      this.checkButtonStatus();

      // Hmm calling this 5 times, not sure I like..
      this.updateFilterHeaders('tier_ones');
      this.updateFilterHeaders('tier_twos');
      this.updateFilterHeaders('tier_threes');
      this.updateFilterHeaders('budget');
      this.updateFilterHeaders('finance');
      this.updateFilterHeaders('location');
    }
    this.selectedMainFilters = JSON.parse(JSON.stringify(this.userSelections));
    // Simple Tooltip component
    this.tooltip = new Tooltip();
    this.enquiryTooltip = new Tooltip();

    // Sticky sidebar filters
    this.stickySidebar = new StickySidebar({
      sidebarClass: '.vehicle-filters',
      containerClass: '.results-split'
    });

    // Page Component inialization
    this.budgetDisplay = new BudgetDisplay({
      id: 'budget-display',
      tabCallback: this.checkFinancePanelStatus,
      dispatchData: this.updateRequestState,
      finance: querySearchParams.finance,
      userValues: {
        monthly: {
          monthly_min: querySearchParams.monthly_min,
          monthly_max: querySearchParams.monthly_max
        },
        otr: {
          min: querySearchParams.min,
          max: querySearchParams.max
        }
      }
    });

    if (window.groupView != true) {
      this.locationDisplay = new LocationDisplay({
        id: 'location-display',
        tabCallback: this.updateRequestMode,
        dispatchData: this.updateRequestState,
        userData: {
          postcode: querySearchParams.postcode,
          lat: querySearchParams.lat,
          long: querySearchParams.long,
          distance: querySearchParams.distance,
          dealer_search: querySearchParams.dealer_search,
          dealer_number: querySearchParams.dealer_number
        }
      });
    }

    this.financeDisplay = new FinanceDisplay({
      id: 'finance-display',
      dispatchData: this.updateRequestState,
      userValues: {
        term: querySearchParams.term,
        deposit_max: querySearchParams.deposit_max,
        mileage_max: querySearchParams.mileage_max
      }
    });

    this.COMPARE = JSON.parse(localStorage.getItem("compare")) || [];

    $(this.options.results).on('click', '.card-link', this.handleCardClick);
    $(this.options.results).on('click', '.card-compare', this.handleCompareButtonClick);
    $(this.options.results).on('click', '.card-retailer-offer', this.handleBaseToolTip);
    $(this.options.results).on('click', '.card-retailer-offer .js-tooltip-close', this.handleBaseToolTipClose);
    $(this.options.results).on('click', '.card-location.group-stock', this.handleBaseToolTip);
    $(this.options.results).on('click', '.card-location.group-stock .js-tooltip-close', this.handleBaseToolTipClose);
    $(this.options.results).on('click', '.card-otr', this.handleBaseToolTip);
    $(this.options.results).on('click', '.card-otr .js-tooltip-close', this.handleBaseToolTipClose);
    $(this.options.compareLink).on('click', this.handleCompareLinkClick);
    $('.compare-page .btn.secondary').on('click', this.handleCardClick);
    $(this.options.modalCompare).on('click', '.btn.primary', this.handleReserveClick);

    $(this.options.termsToggle).on('click', this.handleTermsToggleClick);
    $(this.options.sortBy).on('click', this.handleSortByClick);
    $(this.options.sortByOption).on('click', this.handleSortByOptionClick);
    $(this.options.filterHeaders).on('click', this.handleFilterHeaderClick);
    $(this.options.filterMenuBtn).on('click', this.handleFilterMenuClick);

    // Main filters
    $(this.options.filterMainItem).on('click', this.handleMainFilterClick);

    // Additional filters
    $(this.options.filterAdditionalItem).on('click', this.handleAdditionalFilterClick);
    $(this.options.filtersRefineBtn).on('click', this.handleRefineSearch);
    $(this.options.compareVehicles).on('click', '.read-more', this.handleReadMore);

    document.addEventListener('click', (event) => this.handleFinanceExampleToggle(event));

    $(window).on('scroll', this.handleStickyNav);
    $(window).on('resize', this.handleResize);
    $('.filter-options').on('click', $('.show-more'), this.filterShowMore);
    $('.clear-filters').on('click', this.handleClearFiltersClick);
    this.updateResultValues(this.userSortBy);

    $('.additional-filters').addClass('is-open');

    // Set Tracy page
    setTracyPage(`stock > ${this.BRAND} > search result`);
    window.sortBy = { ...window.sortBy, ...this.addExtraOrderLists(window.sortBy.location) };

    if (window.groupAirstripView) {
      this.userSortBy = 'price_desc';
      this.updateResultValues(this.userSortBy);
    } else if (window.groupView) {
      this.userSortBy = 'price_asc';
      this.updateResultValues(this.userSortBy);
    }

    this.loadSlickSlider();

    this.webComponent = new UpdateWebComponent({
      component: 'app-repex',
      propertyToUpdate: 'minimized',
      propertyValue: window.localStorage.getItem('repex') === 'closed' ? true : false
    });

    // Capture GA Event
    captureListVehiclesEvent();
  }

  get isSmallScreen() {
    return window.innerWidth < 1024;
  }

  isSimilarResults() {
    const similarResultsSelector = document.querySelector('section[data-js-src="vehicle-similar-results"]');

    return similarResultsSelector !== null;
  }

  addVideoModalEventListeners() {
    const videoLabelButtons = document.querySelectorAll('.video-label-container');
    const videoContainer = document.querySelectorAll('.video-container');
    videoLabelButtons.forEach(this.handleVideoLabelClick);
    videoContainer.forEach(this.handleVideoModalClose);
  }

  fetchEveData(videoId, evenConfigId) {
    const apiEndpoint = `${this.eveApiBaseUrl}/${evenConfigId}`;
    const modal = document.getElementById(videoId);

    fetch(apiEndpoint, {
      method: 'GET',
      headers: {
        "clientID": "NCSL"
      }
    }).then((response) => {
      return response.json();
    }).then((data) => {
      this.buildEveVideoUrls(data, videoId);
      modal.dataset.videoLoaded = "true";
    }).catch((error)=>{
      this.displayEveVideoErrorMessage(videoId);
      console.log("GET Error: ", error.message);
    });
  }

  buildEveVideoUrls(eveData, videoId) {
    const eveVideoUriData = eveData.resources.videos;
    let eveVideoUrls = {
      'exterior': null,
      'interior': null
    };

    eveVideoUriData.forEach(uri => {
      if (uri.includes('EXT')) {
        eveVideoUrls.exterior = `${this.eveContentBaseUrl}/${uri}`
      } else if (uri.includes('INT')) {
        eveVideoUrls.interior = `${this.eveContentBaseUrl}/${uri}`
      }
    });

    if (Object.keys(eveVideoUrls).length === 0) {
      this.displayEveVideoErrorMessage(videoId);
    } else {
      this.displayEveVideoTabs(eveVideoUrls, videoId);
    }
  }

  displayEveVideoTabs(videoUrls, videoId) {
    const tabbedContent = document.querySelector(`#${videoId} .tabbed-content`);
    const loadingSpinner = document.querySelector(`#${videoId} .lds-spinner`);
    const videoLoadingDelay = 2000;

    for (const type in videoUrls) {
      const tabButton = tabbedContent.querySelector(`.tab-list__tab-${type}`);
      const tabPanel = tabbedContent.querySelector(`.tab-panel--${type}`);
      const videoElement = tabPanel.querySelector('video');
      const videoSource = videoElement.querySelector('source');
      const videoFallbackLink = tabPanel.querySelector('.video-fallback-link');

      if (videoUrls[type] !== null) {
        videoSource.setAttribute('src', videoUrls[type]);
        videoFallbackLink.setAttribute('href', videoUrls[type]);
        videoElement.setAttribute('preload', 'auto');

        if (type === 'exterior') {
          videoElement.load();
        } else {
          setTimeout(() => {
            videoElement.load();
          }, videoLoadingDelay);
        }
      } else {
        tabButton.remove();
        tabPanel.remove();
      }
    }

    const tabButtonCount = tabbedContent.querySelectorAll('.tab-list__tab');

    if (tabButtonCount.length < 2) {
      const firstTabButton = tabbedContent.querySelector('.tab-list__tab');
      const firstTabPanel = tabbedContent.querySelector('.tab-panel');

      firstTabButton.setAttribute('aria-selected', 'true');
      firstTabPanel.classList.add('content-has-been-viewed');
      firstTabPanel.classList.remove('hide');
    }

    this.tabbedContent = new TabbedContent();

    setTimeout(() => {
      tabbedContent.classList.remove('zero-opacity-layer');
      loadingSpinner.classList.add('hide');
    }, videoLoadingDelay);
  }

  displayEveVideoErrorMessage(videoId) {
    const tabbedContent = document.querySelector(`#${videoId} .tabbed-content`);
    const errorMessage = document.querySelector(`#${videoId} .video-container__error-message`);
    const loadingSpinner = document.querySelector(`#${videoId} .lds-spinner`);

    tabbedContent.classList.add('hide');
    loadingSpinner.classList.add('hide');
    errorMessage.classList.remove('hide');
  }

  handleVideoLabelClick(button) {
    const thisId = button.dataset.videoId;
    const evenConfigId = button.dataset.eveConfigId;
    const thisModal = document.getElementById(thisId);

    button.addEventListener('click', () => {
      this.videoModal = new SimpleDialog({
        id: thisId
      });

      this.videoModal.show();

      const selectedVideoTab = thisModal.querySelector('.tab-list__tab[aria-selected="true"]').textContent.toLowerCase();
      const tracyObject = {
        linkName: `modal opened on ${selectedVideoTab} tab`,
        requestedUrl: window.location.href,
        pageName: 'search result',
      };

      setTracyEvent('stock > internal link clicked', tracyObject);

      if (thisModal.dataset.videoLoaded) return;

      this.fetchEveData(thisId, evenConfigId);
    });
  };

  handleVideoModalClose(videoContainer) {
    const videoElements = videoContainer.querySelectorAll('video');

    videoContainer.addEventListener('click', (event) => {
      if (
        event.target.classList.contains('modal-layer') ||
        event.target.classList.contains('dialog-header') ||
        event.target.classList.contains('bt-close') ||
        event.target.classList.contains('icon')
      ) {
        videoElements.forEach(video => {
          video.pause();
        });
      }
    });
  }

  /* loadSlickSlider - this is a function to load the slick carousel plugin for the search results tiles */
  loadSlickSlider() {
    const carouselArrows = {
      next: '<button class="slick-next slick-arrow" aria-label="Next Image" type="button">Next Image</button>',
      previous: '<button class="slick-prev slick-arrow" aria-label="Previous Image" type="button">Previous Image</button>'
    }

    $('.slider').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      lazyLoad: 'ondemand',
      nextArrow: carouselArrows.next,
      prevArrow: carouselArrows.previous,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 320,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          }
        }]
    });

    new LazyLoadBackground();
  }

  getAdditionalFilterData(callback) {
    // Show the loader until the request completes.
    const loader = $(this.options.loader);
    loader.addClass('visible');
    $('body').addClass('disable-scroll');

    // Execute the GET request to load the filtered results
    const filterList = $.get('/additional_filters', this.userFilterOptions);
    const _this = this;
    filterList.done((data) => {
      if (data["error"]) {
        alert(data["error"]);
        return false;
      };

      const body = document.body;

      this.additionalFilterData = { ...data, ...this.addExtraOrderLists(data.location) };
      this.updateResultValues(this.userSortBy, this.additionalFilterData);
      this.loadSlickSlider();
      this.resultsFilter.setAdditionalFilters({ filters: _this.userFilterOptions})

      loader.removeClass('visible');
      $('body').removeClass('disable-scroll filters-open');

      callback && callback();

      body.scrollIntoView({
        behavior: 'smooth'
      });
    });
  }

  addExtraOrderLists(results = []) {
    let orderSet = {};
    orderSet.price_asc = [...results].sort((a, b) => {
      return a.numeric_monthly_price - b.numeric_monthly_price;
    })
    orderSet.price_desc = [...orderSet.price_asc].reverse();
    orderSet.model = [...results].sort((a, b) => a.range.localeCompare(b.range))
    return orderSet
  }

  createSearchHash() {
    const url = window.location.pathname + window.location.search + JSON.stringify(filterTemplate);
    return this.hashCode(url);
  }

  hashCode(str) {
    let hash = 0;
    if (str.length == 0) return hash;
    for (let i = 0; i < str.length; i++) {
      let char = str.charCodeAt(i);
      hash = ((hash << 5) - hash) + char;
      hash = hash & hash;
    }
    return hash;
  }

  buildNewFiltersObject() {
    window.localStorage.removeItem('additional_filters');
    this.userFilterOptions = cloneDeep(filterTemplate);
    this.userFilterOptions.id = this.createSearchHash();
    window.localStorage.setItem('additional_filters', JSON.stringify(this.userFilterOptions));
  }


  // RESIZE
  handleResize() {
    this.enquiryTooltip.hide();
  }

  // Adds 'Series' to some of the tier 1 items
  appendSeriesLabel(val) {
    if (BRAND === 'mini') { return val };

    const series = ['1', '2', '3', '4', '5', '6', '7', '8'];
    const newVals = [];

    $.each(val, (i, num) => {
      if (series.indexOf(num) !== -1) {
        num = num + ' Series';
        newVals.push(num);
      } else {
        newVals.push(num);
      }
    });

    return newVals;
  }

  // Updates the request mode when the location tabs are pressed
  // to either DISTANCE or DEALER
  updateRequestMode(mode) {
    this.requestMode = (mode === 'retailer') ? 'DEALER' : 'DISTANCE';
  }

  // Update the values states for the request Object
  updateRequestState(state) {
    let filter = '';
    Object.keys(state).forEach((key) => {
      switch (key) {
        case 'max':
          querySearchParams.max = state[key];
          filter = 'budget';
          break;
        case 'min':
          querySearchParams.min = state[key];
          filter = 'budget';
          break;
        case 'monthly_max':
          querySearchParams.monthly_max = state[key];
          filter = 'budget';
          break;
        case 'monthly_min':
          querySearchParams.monthly_min = state[key];
          filter = 'budget';
          break;
        case 'term':
          querySearchParams.term = state[key];
          filter = 'finance';
          break;
        case 'deposit_min':
          querySearchParams.deposit_min = state[key];
          filter = 'finance';
          break;
        case 'deposit_max':
          filter = 'finance';
          querySearchParams.deposit_max = state[key];
          break;
        case 'mileage_min':
          filter = 'finance';
          querySearchParams.mileage_min = state[key];
          break;
        case 'mileage_max':
          filter = 'finance';
          querySearchParams.mileage_max = state[key];
          break;
        case 'dealer_number':
          filter = 'location';
          querySearchParams.dealer_number = state[key];
          break;
        case 'distance':
          filter = 'location';
          querySearchParams.distance = state[key];
          break;
        case 'postcode':
          filter = 'location';
          querySearchParams.postcode = state[key];
          break;
        case 'lat':
          filter = 'location';
          querySearchParams.lat = state[key];
          break;
        case 'long':
          filter = 'location';
          querySearchParams.long = state[key];
          break;
      }
    });
    this.updateFilterHeaders(filter);
    this.checkButtonStatus();
  }

  // Updates the little grey text below the title of the filter
  // when the filter is in a closed state to display the current selected
  // values (or whatever will fit).. broken with elipsis
  updateFilterHeaders(filter) {
    let value = '';
    switch (filter) {
      case 'tier_ones':
        if (this.userSelections.tier_ones_labels.length > 0) {
          value = this.appendSeriesLabel(this.userSelections.tier_ones_labels).join(', ');
        }
        $('.filter-header[data-name="tier_ones"] .user-selected-items').text(value);
        break;
      case 'tier_twos':
        if (this.userSelections.tier_twos_labels.length > 0) {
          value = this.userSelections.tier_twos_labels.join(', ');
        }
        $('.filter-header[data-name="tier_twos"] .user-selected-items').text(value);
        break;
      case 'tier_threes':
        if (this.userSelections.tier_threes_labels.length > 0) {
          value = this.userSelections.tier_threes_labels.join(', ');
        }
        $('.filter-header[data-name="tier_threes"] .user-selected-items').text(value);
        break;
      case 'budget':
        if (querySearchParams.finance) {
          let suffix = '';
          let monthly_max = querySearchParams.monthly_max;
          if (querySearchParams.monthly_max >= 1000) {
            suffix = '+'
            monthly_max = '1000';
          }
          value = `£${querySearchParams.monthly_min} to £${monthly_max.toLocaleString()}${suffix} monthly`;
        } else {
          value = `£${querySearchParams.min.toLocaleString()} to £${querySearchParams.max.toLocaleString()} OTR*`;
        }
        $('.filter-header[data-name="budget"] .user-selected-items').text(value);
        break;
      case 'finance':
        value = `${querySearchParams.term
          } months with Deposit £${querySearchParams.deposit_max.toLocaleString()
          } for ${(querySearchParams.mileage_max.toLocaleString())} miles per annum`;
        $('.filter-header[data-name="finance"] .user-selected-items').text(value);

        break;
      case 'location':
        value = `${querySearchParams.postcode
          } `;
        if (this.requestMode === 'DISTANCE') {
          value += `(Within ${querySearchParams.distance} miles)`;
        }
        $('.filter-header[data-name="location"] .user-selected-items').text(value);

        break;
    }
  }

  // If the monthly option is checked in the budget filters then the finance
  // Display should be hidden in the filters
  checkFinancePanelStatus(type) {
    if (type === 'monthly') {
      querySearchParams.finance = true;
      this.checkButtonStatus();
      return;
    }
    querySearchParams.finance = false;
    this.checkButtonStatus();
    this.updateFilterHeaders('budget');
  }

  // Checks the button status to determine if the required values are available
  // and thus it should be activated
  checkButtonStatus() {
    if ( this.resultsFilter.isQuerySearchParamsDirty )
    {
      $(this.options.filtersRefineBtn).removeClass('disabled');
    } else
    {
      $(this.options.filtersRefineBtn).addClass('disabled');
    }
  }

  // Format the Vehicle data
  // So we have a big list of Vehicle Types (not actual vehicles)
  // Now we need to build some arrays to find the associations between
  parseVehicleGroupData() {
    const data = window.vehicleData;
    const tierObjTree = {};
    data.forEach((item) => {
      if (tierObjTree[item.tier_one_id] === undefined) {
        tierObjTree[item.tier_one_id] = {
          tier_twos: [],
          tier_threes: []
        };
      }
      const tier_two = item.tier_two_id;
      tierObjTree[item.tier_one_id].tier_twos.push(tier_two);

      const tier_three = item.tier_three_id;
      tierObjTree[item.tier_one_id].tier_threes.push(tier_three);
    });

    this.vehicleTierTree = tierObjTree;
  }

  // Return the VehiclData and retrieve the related tier_two ids based on
  // the curret tier_ones selected
  findIds(value) {
    return this.vehicleTierTree[value];
  }

  // This will activate any filter items the users selected on the search page
  setFilterSelections() {
    this.setAvailableTiers(true, true, true);
  }

  // Find Active Tier Two values, because a tier_two can belong to many tier_ones
  // we need to ensure we have the correct list, and ensure that duplicate
  // values remain in the event a tier_one is removed
  setAvailableTiers(selectedTierOnes = false, selectedTierTwos = false, selectedTierThrees = false) {
    let tierTwos = [],
      // Used only for MINI (PERFORMANCE)
      tierThrees = [];
    this.userSelections.tier_ones.forEach((item) => {
      if (selectedTierOnes) {
        $(`.filter-checklist[data-name="tier_ones"] li[data-value="${item}"]`).addClass('selected');
      }
      if (this.vehicleTierTree[item]) {
        tierTwos = tierTwos.concat(this.vehicleTierTree[item].tier_twos);
        tierThrees = tierThrees.concat(this.vehicleTierTree[item].tier_threes);
      }
    });

    // Quick method for removing duplicates in es6
    tierTwos = [...new Set(tierTwos)];
    this.userSelections.tier_twos_displayed = tierTwos;
    tierThrees = [...new Set(tierThrees)];
    this.userSelections.tier_threes_displayed = tierThrees;

    // Set Active tier_two menu items, note these are NOT the User Selected Items
    // the active state represents those elements which relate to a tier one
    // Ultimately according to a spec there should always be related tier_twos
    // how this will catch any data errors.
    if (this.userSelections.tier_twos_displayed.length === 0) {
      $(`.filter-checklist[data-name="tier_twos"] li`).addClass('active-display');
    }
    if (this.userSelections.tier_threes_displayed.length === 0) {
      $(`.filter-checklist[data-name="tier_threes"] li`).addClass('active-display');
    }

    $(`.filter-checklist[data-name="tier_twos"] li`).removeClass('active-display');
    $(`.filter-checklist[data-name="tier_threes"] li`).removeClass('active-display');

    this.userSelections.tier_twos_displayed.forEach((value) => {
      $(`.filter-checklist[data-name="tier_twos"] li[data-value="${value}"]`).addClass('active-display');
    });

    this.userSelections.tier_threes_displayed.forEach((value) => {
      $(`.filter-checklist[data-name="tier_threes"] li[data-value="${value}"]`).addClass('active-display');
    });

    if (selectedTierTwos) {
      this.userSelections.tier_twos.forEach((value) => {
        $(`.filter-checklist[data-name="tier_twos"] li[data-value="${value}"]`).addClass('selected');
      });
    }
    if (selectedTierThrees) {
      this.userSelections.tier_threes.forEach((value) => {
        $(`.filter-checklist[data-name="tier_threes"] li[data-value="${value}"]`).addClass('selected');
      });
    }
  }

  // TERMS & CONDITIONS
  handleTermsToggleClick() {
    if ($(this.options.termsText).hasClass('is-open')) {
      $(this.options.termsText).removeClass('is-open');
    } else {
      $(this.options.termsText).addClass('is-open');
    }
  }

  // FINANCE EXAMPLE
  handleFinanceExampleToggle(event) {
    const composedArray = event.composedPath();

    if (composedArray[0].closest('.repex-content') === null) return;
    if (composedArray[0].closest('.content') !== null) return;

    const toggleSelectors = {
      'header': 'panel-header',
      'heading': 'repex-h3',
      'headingSpan': 'primary-text-bmw',
      'chevron': 'chevron'
    }

    const repexComponent = document.querySelector('app-repex').shadowRoot;
    const repexContent = repexComponent.querySelectorAll('.content')[0];

    if (
      composedArray[0].classList.contains(toggleSelectors.header) ||
      composedArray[0].classList.contains(toggleSelectors.heading) ||
      composedArray[0].classList.contains(toggleSelectors.chevron) ||
      composedArray[0].classList.contains(toggleSelectors.headingSpan)

    ) {
      if (repexContent.classList.contains('content--open')) {
        window.localStorage.setItem('repex', 'open');
      } else {
        window.localStorage.setItem('repex', 'closed');
      }
    }
  }

  // STICKY NAV
  handleStickyNav() {
    const navbar = $('.results-header');

    if (navbar === null) return;

    if (window.pageYOffset === 0) {
      navbar.removeClass("sticky");
    } else {
      navbar.addClass("sticky");
    }
  }

  // REFINE SEARCH
  handleRefineSearch(e) {
    captureRefinedSearchEvent(e);
    let path = $(e.currentTarget).attr('data-path');
    this.performRefinedSearch(path);
  }

  setUserSelectedVehiclesinQuerySearchParams() {
    querySearchParams.vehicles.vehicle_ids = filterVehicles(window.vehicleData, {
      series: this.userSelections.tier_ones,
      body: this.userSelections.tier_twos,
      style: this.userSelections.tier_threes
    }, false, true);
  }

  performRefinedSearch(dataPath) {
    $('.app-loader').addClass('visible');
    $('body').addClass('disable-scroll');
    let path = dataPath.split('?')[0];

    this.setUserSelectedVehiclesinQuerySearchParams();

    if (querySearchParams.distance === 0) {
      querySearchParams.distance = 1;
    }
    window.localStorage.setItem('repex', 'open');
    this.buildNewFiltersObject();

    /** [TRACY_SEARCH_FILTERS] **/
    if (this.userSelections.tier_ones_labels.length > 0) {
      this.userSelections.tier_ones_labels.forEach((series) => {
        setTracySearchFilter('user selected', null, 'series', series);
      });
    }

    if (this.userSelections.tier_twos_labels.length > 0) {
      this.userSelections.tier_twos_labels.forEach((body) => {
        setTracySearchFilter('user selected', null, 'body', body);
      });
    }

    if (BRAND === 'mini' && this.userSelections.tier_threes_labels.length > 0) {
      this.userSelections.tier_threes_labels.forEach((style) => {
        setTracySearchFilter('user selected', null, 'style', style);
      });
    }

    setTracySearchFilter('user selected', null, 'postcode', querySearchParams.postcode);
    setTracySearchFilter('user selected', 'miles', 'distance', querySearchParams.distance);
    setTracySearchFilter('user selected', null, 'finance', querySearchParams.finance);

    if (querySearchParams.finance) {
      setTracySearchFilter('user selected', null, 'deposit min', querySearchParams.deposit_min);
      setTracySearchFilter('user selected', null, 'deposit max', querySearchParams.deposit_max);
      setTracySearchFilter('user selected', null, 'monthly min payment', querySearchParams.monthly_min);
      setTracySearchFilter('user selected', null, 'monthly max payment', querySearchParams.monthly_max);
      setTracySearchFilter('user selected', null, 'term', querySearchParams.term);
    } else {
      setTracySearchFilter('user selected', null, 'cash min', querySearchParams.min);
      setTracySearchFilter('user selected', null, 'cash max', querySearchParams.max);
    }
    /** [END] **/

    /** [TRACY_EVENT] **/
    const ref = `stock > ${this.BRAND} > search result > refine search applied`;
    setTracyEvent(ref, 'vehicle');
    /** [END] **/


    const requestURL = queryFormatter(path, this.requestMode, window.dealerView, true);
    document.location = requestURL;
  }

  // FILTERS
  handleFilterMenuClick() {
    const $body = $('body');
    $body.addClass('filters-open');
    if ($($body).find('.additional-filters').length === 0) {
      $('.main-filter-accordion').addClass('visible');
      $('.main-filters').addClass('is-open');
    }
    const additionalFilterData = localStorage.getItem('additional_filters');
    if (additionalFilterData) {
      this.userFilterOptions = JSON.parse(additionalFilterData);
    }
    this.persistAdditionalFilters()
  }

  // MAIN FILTERS
  // Does not apply to all filters, since they are not all list items
  // with specific values..
  handleMainFilterClick(e) {
    const target = $(e.currentTarget),
      value = target.attr('data-value'),
      label = target.text(),
      filter = target.parent().attr('data-name');

    let store = false;
    this.selectedMainFilters = JSON.parse(JSON.stringify(this.userSelections));
    if (target.hasClass('selected')) {
      target.removeClass('selected');
      this.userSelections[filter].splice(this.userSelections[filter].indexOf(parseInt(value)), 1);
      this.userSelections[`${filter}_labels`].splice(this.userSelections[`${filter}_labels`].indexOf(label), 1);
      store = false;
    } else {
      target.addClass('selected');
      this.userSelections[filter].push(parseInt(value, 10));
      this.userSelections[`${filter}_labels`].push(label);
      store = true;
    }

    if (store) {
      // Once the filter has been selected we need to determine which type of
      // filter was clicked. The ModelRange represents tier_ones.
      if (filter === 'tier_ones') {
        // Since the click item was a tier one, we need to loop through the
        // vehicleData and find all tier_twos that are related to the tier_ones
        const ids = this.findIds(value);
        // Ensure there is an associated mapping before merging the array
        if (ids !== undefined) {
          this.setAvailableTiers();
          this.updateFilterHeaders(filter);
          this.updatePreselectedTier(store, 'tier_twos');
          this.updatePreselectedTier(store, 'tier_threes');
        }
      }

      if (filter === 'tier_twos') {
        // Since the filter are tier twos
        this.updateFilterHeaders(filter);
        this.updatePreselectedTier(store, 'tier_threes');
      }

      // FOR MINI ONLY
      if (filter === 'tier_threes') {
        // Since the filter are tier threes
        this.updateFilterHeaders(filter);
      }

    } else {
      this.setAvailableTiers();
      this.updateFilterHeaders(filter);

      if (filter === 'tier_ones') {
        this.updatePreselectedTier(store, 'tier_twos');
        this.updatePreselectedTier(store, 'tier_threes');
      }

      if (filter === 'tier_twos') {
        this.updatePreselectedTier(store, 'tier_threes');
      }
    }

    // Check the refine button status to determine if it should be active
    this.setUserSelectedVehiclesinQuerySearchParams();
    this.checkButtonStatus();
  }


  handleHeaderFilterClick(e) {
    const target = $(e.currentTarget);
    const container = target.closest('.main-filters');
    const targetFilterClone = JSON.parse(localStorage.getItem('targetFilter'));

    if (!isEmpty(targetFilterClone)) {

      $('.modify-filter-confirmation').addClass('visible');
      $('.filter-actions-mobile').css('display', 'none');
    }
    else {
      container.addClass('is-open');
      $('.modify-filter-confirmation').removeClass('visible');
      $('.main-filter-accordion').css('display', 'block');
      $('.additional-filter-accordion').css('display', 'none');
      $('.filter-actions-mobile').css('display', 'block');
      $('.additional-filters').removeClass('is-open');
    }
  }

  updatePreselectedTier(store, tier) {
    // Using a timeout as this needs to happen after the
    // userSelections get updated inside setAvailableTiers()
    // There was a 100ms timeout here. Deleted as setAvailableTiers is synchronous.
    if (!store) {
      $(`.filter-checklist[data-name="${tier}"] li`).removeClass('selected');
      this.userSelections[tier] = [];
      this.userSelections[`${tier}_labels`] = [];
    }

    this.userSelections[`${tier}_displayed`].forEach((item) => {
      const seletedItem = $(`.filter-checklist[data-name="${tier}"] li[data-value="${item}"]`);

      seletedItem.addClass('selected');
      this.userSelections[tier].push(parseInt(item, 10));
      this.userSelections[`${tier}_labels`].push(seletedItem.text());
    });
    // Remove duplicates
    let newTier = this.userSelections[tier];
    let newTierLabels = this.userSelections[`${tier}_labels`];
    newTier = [...new Set(newTier)];
    newTierLabels = [...new Set(newTierLabels)];
    this.userSelections[tier] = newTier;
    this.userSelections[`${tier}_labels`] = newTierLabels;
    this.updateFilterHeaders(tier);
  }

  // ADDITIONAL FILTERS
  handleAdditionalFilterClick(e) {
    // Show the clear filters close button
    $('.clear-filters').show();
    const target = $(e.currentTarget),
      value = target.attr('data-value'),
      filter = target.parent().attr('data-name');

    const filterElement = JSON.parse(window.localStorage.getItem('targetFilter'));
    const showMoreItems = JSON.parse(window.localStorage.getItem('showMoreItems'));


    if (target.hasClass('hide-item')) {
      target.removeClass('hide-item');
      if (!isEmpty(showMoreItems) && isEmpty(this.showMoreItems)) {
        this.hiddenItems = [...showMoreItems];
        this.hiddenItems.push(value)
      } else {
        this.hiddenItems.push(value)
      }
    }

    window.localStorage.setItem('showMoreItems', JSON.stringify([... new Set(this.hiddenItems)]));

    if (target.hasClass('selected')) {
      target.removeClass('selected');
      this.userFilterOptions[filter].splice(this.userFilterOptions[filter].indexOf(value), 1);
      !(showMoreItems) && showMoreItems.splice(showMoreItems.indexOf(value), 1);
      window.localStorage.setItem('showMoreItems', JSON.stringify(showMoreItems));
    } else {
      target.addClass('selected');
      this.userFilterOptions[filter].push(value);
    }

    this.resultsFilter.additionalFilterUpdated(this.userFilterOptions);

    /** [TRACY_SEARCH_FILTERS] **/
    setTracySearchFilter('user selected', null, filter, value);
    /** [END] **/

    /** [TRACY_EVENT] **/
    const ref = `stock > ${this.BRAND} > search result > filter search applied`;
    setTracyEvent(ref, 'vehicle');
    /** [END] **/
  }

  handleClearFiltersClick() {
    // hide the clear filters close button
    $('.clear-filters').hide();
    //remove the selected class from the children of class filter-checklist
    const filters = $('.additional-filters .filter-checklist').children();
    // loop over each list item and check if the have selected class
    // if they do remove that class
    filters.each((idx, li) => {
      const item = $(li);
      if (item.hasClass = 'selected') {
        item.removeClass('selected');
      }
    });

    // reset the object this.userFilterOptions
    assign(this.userFilterOptions, cloneDeep(filterTemplate));

    // call getAdditionalFilterData() function to load in the values without filters
    this.resultsFilter.additionalFilterUpdated(this.userFilterOptions);
  }




  persistAdditionalFilters(ev) {
    const targetFilter = JSON.parse(window.localStorage.getItem('targetFilter'));
    const showMoreItems = JSON.parse(window.localStorage.getItem('showMoreItems'));
    const clearFilterBtn = $('.clear-filters');

    $('.show-more').remove();
    for (const filter in this.userFilterOptions) {
      if (["id", "version"].includes(filter)) { continue };

      const activeFilters = this.userFilterOptions[filter];

      // check if there are any activeFilters, if there are show the clear filters button
      if (activeFilters.length > 0 && clearFilterBtn.is(":hidden")) {
        clearFilterBtn.show();
      }
      activeFilters.forEach((item) => {
        let escaped = $.escapeSelector(item);
        let option = $(`.additional-filters [data-value="${escaped}"]`);
        option.addClass('selected');
        option.closest(`.filter-section`).addClass('is-open');
      });
    };

    !isEmpty(showMoreItems) && showMoreItems.forEach((item) => {
      let escaped = $.escapeSelector(item);
      $(`.additional-filters [data-value="${escaped}"]`).addClass('hide-item');
    });
  }


  // FILTERS
  handleFilterHeaderClick(ev) {
    const target = $(ev.currentTarget);
    const container = target.closest('.filter-section');
    const optionContainer = target.next().children();
    const optionsList = optionContainer.find('li');

    if (container.hasClass('is-open')) {
      container.removeClass('is-open');
      optionContainer.find('.show-more').remove();
    } else {
      container.addClass('is-open');
      const additionalContainer = container.parent('.additional-filter-accordion');
      // check length of children
      if (optionsList.length > 3 && additionalContainer.length > 0) {
        optionsList.each((idx, li) => {
          const item = $(li);
          if (idx > 2) {
            item.hide();
          }
        });
        let showMoreElement = optionContainer.find('.show-more.hide-item');
        if (showMoreElement.length > 0) { showMoreElement.remove(); }
        optionContainer.append("<span class='show-more show-more-plus'>Show more</span>");


      }
    }
  }

  filterShowMore(ev) {
    if (ev.target.classList[0] !== 'show-more') {
      return;
    }
    const options = $(ev.currentTarget).children().children();
    const showMore = $(ev.currentTarget).find('.show-more');

    if (showMore.text() === 'Show more') {
      options.each((idx, li) => {
        if (idx > 2) {
          $(li).addClass('hide-item')
        }
        $(li).show();
      });
      showMore.removeClass('show-more-plus');
      showMore.addClass('show-more-less');
      showMore.text('Show less');
    } else {
      options.each((idx, li) => {
        if (idx > 2) {
          $(li).hide();
        }
      });
      showMore.show();
      showMore.removeClass('show-more-less');
      showMore.addClass('show-more-plus');
      showMore.text('Show more');
    }
  }
  // SORT ORDER
  handleSortByClick(ev) {
    const target = $(ev.currentTarget);
    const container = target.closest('.results-sort');
    if (container.hasClass('is-open')) {
      this.closeSortMenu();
    } else {
      this.openSortMenu();
    }
  }

  // Handler that listens to the read more click
  handleReadMore(e) {
    const targetElem = $(e.currentTarget);
    const siblingElem = targetElem.siblings('.consumption-full');
    const beforeElem = targetElem.prev('.consumption-initial')

    if (beforeElem.css("display") === "none") {
      beforeElem.show();
      targetElem.text('Read More')
      siblingElem.hide();
    } else {
      beforeElem.hide();
      targetElem.text('Read Less')
      siblingElem.show();
    }
  }

  // SORT ORDER
  handleSortByOptionClick(ev) {
    const target = $(ev.currentTarget);
    const sortBy = target.data('sort');

    this.closeSortMenu();

    $(this.options.sortByOption).removeClass('is-selected');
    target.addClass('is-selected');

    $(this.options.sortBy).text(this.options.sortOptions[sortBy]);

    //set the Tracy sort type
    window.tracyData.sortType = this.options.sortOptions[sortBy];

    // Store the sortBy Value
    this.userSortBy = sortBy;
    this.updateResultValues(this.userSortBy, this.additionalFilterData);
    this.loadSlickSlider();
    /** [TRACY_EVENT] **/
    const ref = `stock > ${this.BRAND} > search result > sort`;
    setTracyEvent(ref, 'vehicle');
    /** [END] **/
  }

  // SORT ORDER
  openSortMenu() {
    $(this.options.sortMenu).addClass('is-open');
  }

  // SORT ORDER
  closeSortMenu() {
    $(this.options.sortMenu).removeClass('is-open');
  }

  // SORT ORDER
  updateResultValues(sortOrder, response = null) {
    let vehicleTemplateOptions;
    const data = (response) ? response[sortOrder] : window.sortBy[sortOrder];
    // Escape if there are no results and show a message
    if (!data) {
      $(this.options.results).html('<div class="message-no-results">Unfortunately there are no vehicles that match your filter selections. Please update or clear the filters you have selected or start a new search.</div>');
      return false
    };

    data.forEach((vehicle) => {
      vehicle.keyinfo = [
        {
          icon: KEYINFO.wheels,
          title: vehicle.wheels,
          alt: 'Wheel icon'
        },
        {
          icon: KEYINFO.interior,
          title: UPHOLSTERY[vehicle.upholstery_type],
          alt: 'Upholsery icon'
        },
        {
          icon: KEYINFO.seats,
          title: `${vehicle.seat_count} seats`,
          alt: 'Seat icon'
        },
        {
          icon: KEYINFO.drivetrain,
          title: vehicle.drive_type,
          alt: 'Drive train icon'
        },
        {
          icon: KEYINFO.gearbox,
          title: vehicle.transmission,
          alt: 'Transmission gearbox icon'
        },
        {
          icon: KEYINFO.fuel,
          title: vehicle.fuel_type,
          alt: 'Fuel icon'
        }
      ];
      vehicle.airstrip = vehicle.airstrip_stock;
    });

    data.forEach(vehicle => {
      if (vehicle.highlighted_options.packs) {
        vehicle.highlighted_options.packs = [vehicle.highlighted_options.packs.join(' | ')];
      }
    });

    vehicleTemplateOptions = {
      mini: BRAND === 'mini',
      vehicles: data,
      img: IMAGES,
      dealer_view: window.dealerView,
      group_view: window.groupView,
      group_retailer_view: window.groupRetailerView,
      airstrip_group_view: window.groupAirstripView,
      brand: BRAND.toUpperCase()
    }

    if (window.groupView) {
      const resultsSection = document.querySelector('section[data-js-src="vehicle-results"]');
      vehicleTemplateOptions.group_name = resultsSection.getAttribute('data-group');
    }


    $(this.options.results).html('');
    $(this.options.results).append(vehicleTemplate(vehicleTemplateOptions));
    this.updateResultCompareIcons();
    this.tooltip = new TooltipBasic();
    this.addVideoModalEventListeners();
  }

  // UPDATE OFFERS
  createOffers() {
    const offers = new Carousel({
      id: '.results-carousel',
      // This does not need to be specified, the code will check the number of
      // elements in the dom. If it is specified it simply halts unecessary code
      // execution
      // numberItems: 3,
      // OPTIONAL
      // maxItems: 4,
      // Always need some sort of offset to accommodate paddings and margins..
      offsetValue: 25,
      transition: 'linear',
      onChange: this.tooltip.hide,
      breakpoints: {
        375: {
          display: 1
        },
        768: {
          display: 2
        },
        1024: {
          display: 2
        }
      }
    });
  }

  // COMPARE
  // Handles adding/removing vehicle result tiles to comparison
  handleCompareButtonClick(event) {
    const target = $(event.currentTarget);
    const vehicle_id = target.data('vin');
    const orderNumber = target.data('orderno');
    const name = target.data('name');
    const iv = target.data('iv');
    const dealer_number = target.data('dealernumber');
    const inComparison = target.closest('.vehicle-card').hasClass('in-comparison');

    if (inComparison) {
      const resultObject = this.search(orderNumber, 'orderNumber', this.COMPARE);
      const index = this.COMPARE.indexOf(resultObject);
      this.COMPARE.splice(index, 1);
    } else {
      if (this.COMPARE.length >= 3) {
        alert("The maximum number of cars has been added.");
        return false;
      }

      const newVehicle = {
        iv: iv,
        orderNumber: orderNumber,
        vin: vehicle_id,
        dealer_number: dealer_number
      }

      this.COMPARE.push(newVehicle);

      this.track('VehicleCompare', {
        VehicleHandle: vehicle_id,
        VehicleName: name
      });

    }

    window.localStorage.setItem('compare', JSON.stringify(this.COMPARE));
    this.updateResultCompareIcons();
  }

  // Find a key value in an array of objects
  search(nameKey, prop, myArray) {
    for (var i = 0; i < myArray.length; i++) {
      if (myArray[i][prop] === nameKey) {
        return myArray[i];
      }
    }
  }

  updateResultCompareIcons() {
    const results = $('.vehicle-card');

    $.each(results, (i, el) => {
      const vehicle = $(el);
      const orderNumber = vehicle.find('.card-compare').data('orderno');

      const resultObject = this.search(orderNumber, 'orderNumber', this.COMPARE);

      if (resultObject) {
        vehicle.addClass('in-comparison');
      } else {
        vehicle.removeClass('in-comparison');
      }
    });

    this.updateCompareHeader();
  }

  updateCompareHeader() {
    const count = $(this.options.compareCount);

    if (this.COMPARE.length < 1) {
      count.text('');
      $(this.options.compareLink).addClass('disabled');
    } else {
      count.text(`(${this.COMPARE.length})`);
      $(this.options.compareLink).removeClass('disabled');
    }
  }

  initAccordions() {
    const accSpec = new Accordion({ id: 'specs' }),
      accPerfromance = new Accordion({ id: 'specs-performance' }),
      accConsumption = new Accordion({ id: 'specs-consumption' }),
      accDimensions = new Accordion({ id: 'specs-dimensions' });
  }

  /**
   * @function handleCompareTooltips
   * @description Event listeners for tooltips in the comparison modal
   * @param {object} tooltip - Tooltip button
   * @param {object} closeButton - Close button
   */
  handleCompareTooltips(tooltipButton, closeButton) {
    $(tooltipButton).on('click', this.handleBaseToolTip);
    $(closeButton).on('click', this.handleBaseToolTipClose);
  }

  handleBreakpointUpdate() {
    this.initAccordions();
    this.handleCompareTooltips('.compare-price', '.compare-price .js-tooltip-close');
  }

  handleCompareLinkClick(event) {
    event.preventDefault();

    if (this.COMPARE.length < 2) {
      alert("Add at least 2 vehicles to compare");
      return false;
    };

    const compareUrl = event.target.href;

    /** [TRACY_EVENT] **/
    const ref = `stock > ${this.BRAND} > search result > compare`;
    setTracyEvent(ref, 'vehicle');
    /** [END] **/

    this.initCompare(compareUrl);
  }

  initCompare(redirectUrl) {
    const vehicles = {
      vehicles: this.COMPARE,
      dealer: window.dealerView
    };

    if (window.groupView || window.groupRetailerView) {
      let currentUrl = new URL(window.location)
      let currentGroup = currentUrl.pathname.split("/")[2]
      vehicles["group"] = currentGroup;
    }

    const loadingOverlay = document.querySelector('.app-loader');
    loadingOverlay.classList.add('visible');

    CompareVehicles.postComparisonData(vehicles, redirectUrl);
  }

  // CARD
  handleCardClick(e) {
    const path = $(e.currentTarget).attr('data-link');
    const location = this.buildLink(path);
    window.location = location;
  }

  // COMPARE
  handleEnquireClick(e) {
    const target = $(e.currentTarget);
    window.touchscreenSession ? this.buildEnquiryTooltip(target) : this.buildEnuiryPageLink(target);

    /** [TRACY_EVENT] **/
    const ref = `stock > ${this.BRAND} > compare > enquire now`;
    const parameters = { type: 'vehicle', eventAction: 'internal click' };
    setTracyEvent(ref, parameters);
    /** [END] **/
  }

  buildLink(path) {
    if (window.dealerView) {
      const dealer_id = window.location.pathname.split('/')[2];
      path = `/retailer/${dealer_id}${path}`;
    }

    if (window.groupView || window.groupRetailerView) {
      const groupSlug = window.location.pathname.split('/')[2];
      path = `/group/${groupSlug}${path}`;
    }

    return path;
  }

  /**
   * @function buildEnuiryPageLink
   * @description Builds a link to the Enquiry Page with relevant vehicle data params
   * @param {object} target - JQuery object of the element that was clicked
   */
  buildEnuiryPageLink(target) {
    const basepath = target.data('link');
    const quoteref = target.data('quoteref');
    const orderNum = target.data('ordernum');
    const dealer = target.data('dealer');
    const series = target.data('series');
    const brand = target.data('brand');
    const body = target.data('body');
    const modelTreeReference = target.data('modelTreeReference');
    const modelPath = modelTreeReference ? `moiMtr=${modelTreeReference}` : `moiBo=${body}&moiS=${series}&moiB=${brand}`;
    const path =
      `${basepath}?${modelPath}&id=${dealer}&ref=${quoteref}&on=${orderNum}`;

    const location = this.buildLink(path);
    window.location = location;
  }

  /**
   * @function buildEnquiryTooltip
   * @description Shows a tooltip when the Enquiry button is clicked when in touchscreen mode
   * @param {object} target - JQuery object of the element that was clicked
   */
  buildEnquiryTooltip(target) {
    const position = target.position();
    const tooltipContent = "If you would like to find out more about this specific vehicle, please ask to talk to one of the sales team, who will be happy to help.";
    const tipPosition = { top: 'auto', left: 100 };

    this.enquiryTooltip.show(target.closest(".modal-inner.compare"), tipPosition, tooltipContent);
  }

  handleReserveClick(e) {
    const target = $(e.currentTarget);
    const basepath = window.reserve.reserveBaseURL;
    const vin = target.data('vin');
    const iv = target.data('iv');
    const quoteref = target.data('quoteref');
    const dealerid = parseInt(target.data('dealerid'), 10); // Remove any leading zeros
    const location = `${basepath}?quoteref=${quoteref}&retailer=${dealerid}&vin17=${vin}&iv=${iv}&source=nsl`;

    window.open(location);
  }


  handleBaseToolTip(e) {
    e.stopPropagation();
    const target = $(e.currentTarget);
    const otherToolTips = $('.tooltip-container');
    const container = target.find('.js-tooltip');
    otherToolTips.hide();
    container.show();
  }

  handleBaseToolTipClose(e) {
    e.stopPropagation();
    const target = $(e.currentTarget);
    $('.js-tooltip').hide();
  }
}

export default Results;
