import bindAll from 'lodash/bindAll';
import Page from './components/page/page';
import Accordion from './components/accordion/accordion';
import SimpleDialog from './components/dialog/dialog';
import { analyticsAllowed } from "./utils/cookie-helper";
import Tooltip from './components/tooltip/tooltip';
import TabbedContent from './components/tabbed-content/tabbed-content';
import Notification from './components/notification';
import LazyLoadBackground from './components/lazy-load-background/lazy-load-background';
import UpdateWebComponent from './components/update-web-components/update-web-components';
import CompareVehicles from './components/compare-vehicles/compare-vehicles';
import { setTracyPage, setTracyEvent, setTracyProductAdd } from './tracy';
import { captureEnquireEvent, captureReserveEvent } from './analytics';
import 'slick-carousel';
import LocationDisplay from './components/location-display/location-display';
import { captureVehicleDetailsLoadEvent } from './analytics';

const packageDetailsTemplate = require("./templates/package-details.hbs");
const packagesTemplate = require("./templates/packages.hbs");

/**
 * @class Car Detail
 * @description This is the vehicle detail page displaying the data and specifications
 * for the selected vehicle from the search results.
 */
class CarDetail extends Page {

  constructor(options = {}) {
    super();
    // Set the parent container Reference
    this.el = $('.vehicle-detail-container');
    this.heroBanner = document.querySelector('.hero-vehicle');
    // Reference any page elements
    this.stickyHeader = $('.sticky-detail-header');
    this.stickyFooter = $('.sticky-detail-footer');
    this.compareLink = $('.link-favourite');
    this.viewQuoteBtn = $('.hero-vehicle .bt-action');
    this.reserveLabel = $('[data-js-src="vehicle-detail"] .reserve-info');
    this.viewMapBtn = $('.map-details .bt-action');
    this.packagesHeader = $('.accordion-container');
    this.packageDetails = $('.package-details');
    this.packagesContainer = $('.packages.detail-container');
    this.locationDisplay = new LocationDisplay({
      id: 'location-display',
      userData: {}
    });

    // Initialize the page and any applicable components
    this.init();
    this.tracyCalls($('html').data('brand'));

    this.track('VehicleDetails', {
      VinHandle: pixelTrackingData.vin_handle,
      VehicleName: pixelTrackingData.name
    });

  }

  tracyCalls(brand) {
    setTimeout(() => {
      setTracyProductAdd(`stock > ${brand} > add vehicle details`);
      setTracyPage(`stock > ${brand} > vehicle details`);
    }, 1000);
  }

  /**
  * @function init
  * @description The page initialization function, creates components,
  * and creates any desired event listeners.
  */
  init() {
    // Check to ensure the elements required for this page functionality exist
    // accordion component should already check for available elements
    if (this.el.length > 0) {
      bindAll(this,
        'handleStickyNav',
        'handleInfoClick',
        'handleCompareClick',
        'updateCompareIcon',
        'updateCostInfo',
        'handleReserveBtnClick',
        'handleEnquireClick',
        'handleEnquireContinueButtonClick',
        'handleResize',
        'handleViewQuote',
        'handleViewMap',
        'handlePackagesHeader',
        'handlePackageDetails',
        'handlePackageDetailsMore',
        'updateQuoteData',
        'buildPackages',
        'handlePackageTitleClick',
        'handleReserveModal',
        'handleDealerSelect'
      );

      this.BRAND = $('html').data('brand');

      this.COMPARE = JSON.parse(localStorage.getItem("compare")) || [];
      $(this.compareLink).on('click', this.handleCompareClick);

      // Initialize the dialog component
      this.dialogCom = new SimpleDialog({
        id: 'dialog-spec'
      });

      // Initialize the dialog for reserve label
      this.dialogLabel = new SimpleDialog({
        id: 'dialog-label'
      });
      // Retrieve any info icons and bind the click event to them
      const infoIcons = this.el.find('.icon-info');
      infoIcons.on('click', this.handleInfoClick);

      // Add the event listener to handle the sticky header/footer display
      // when the scroll position is at the desired point
      $(window).on('scroll', this.handleStickyNav);

      this.updateCompareIcon();

      this.quoteData = {};

      const buttonReserve = document.querySelectorAll('.bt-reserve');
      const buttonReserveContinue = document.querySelectorAll('.bt-reserve-continue');
      const buttonEnquireContinue = document.querySelectorAll('.bt-enquire-continue');

      buttonReserve.forEach((button) => {
        button.classList.add('disabled');
        button.addEventListener('click', (event) => {
          if (window.groupView || window.isAirstripStock) {
            this.showReservationModal();
          } else {
            this.handleReserveBtnClick(event);
          }
        });
      });

      buttonReserveContinue.forEach((button) => {
        button.addEventListener('click', (event) => {
          this.handleReserveBtnClick(event);
        });
      });

      buttonEnquireContinue.forEach((button) => {
        button.addEventListener('click', (event) => {
          this.handleEnquireContinueButtonClick();
        });
      });

      // Send anayltics info on enquire button click
      $('.bt-enquire').on('click', this.handleEnquireClick);

      this.viewQuoteBtn.on('click', this.handleViewQuote);

      //handler for reserve click event on main page
      this.reserveLabel.on('click', this.handleReserveModal);
      //handler for reserve click event on header
      this.stickyHeader.on('click','.bt-action', this.handleReserveModal);
      //handler for reserve click event on footer
      this.stickyFooter.on('click', '.bt-action',this.handleReserveModal);

      this.viewMapBtn.on('click', this.handleViewMap);
      this.packagesHeader.on('click', '.accordion-item', this.handlePackagesHeader);

      $(window).on('resize', this.handleResize);
      this.tooltip = new Tooltip();
      this.tabbedContent = new TabbedContent();
      this.notification = new Notification();

      this.packageDetails.on('click', this.handlePackageDetails);
      $('.price--retailer-offer').on('click', this.handleBaseToolTip);
      $('.price--otr').on('click', this.handleBaseToolTip);
      $('.js-tooltip-close').on('click', this.handleBaseToolTipClose);

      this.modalWrapper = document.querySelector('.modal-wrapper');
      this.modalClose = document.querySelector('.js-modal-close');

      if (this.modalWrapper !== null) {
        this.modalWrapper.addEventListener('click', (event) => {
          if (event.target.classList.contains('modal-wrapper')) {
            this.handleModalClose();
          }
        });

        this.modalClose.addEventListener('click', (event) => {
          this.handleModalClose();
        });
      }

      this.buildPackages();
    }

    $('.slider').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: true,
      useTransform: false,
      lazyLoad: 'ondemand',
      appendArrows: $('.slick-controls'),
      appendDots: $('.slick-controls'),
      variableWidth: true,
      centerMode: true
    });

    new LazyLoadBackground({
      loadAll: true
    });

    window.addEventListener('DOMContentLoaded', function() {
      this.webComponent = new UpdateWebComponent({
        component: 'dsd-calculator',
        propertyToUpdate: 'disableAnalytics',
        propertyValue: !analyticsAllowed(),
        isValueString: true
      });
    }.bind(this));

    // attach event listener for fs calculator web component
    const calculator = document.querySelector('dsd-calculator');
    calculator.addEventListener('errors', (event) => console.log('error', event.detail));
    calculator.addEventListener('initialized', (event) => this.initQuoteCalculator(event.detail));
    calculator.addEventListener('calculate', (event) => this.initQuoteCalculator(event.detail));

    if (window.groupView || window.isAirstripStock) {
      const dealerList = document.querySelector('.js-group-dealer-select-menu');

      dealerList.addEventListener('change', (event) => {
        this.handleDealerSelect(event);
      });
    }
    captureVehicleDetailsLoadEvent();
  }

  /**
    * @function buildPackages
    * @description Builds the list of packages and equipment from the data in window.salesGroups
    */
  buildPackages() {
    const accordionContainer = this.packagesContainer.find('.accordion-container');
    accordionContainer.append(packagesTemplate({ sales_groups: window.salesGroups, moreIcon: '' }));

    // Initialise events inside handlebars markup
    const accSpec = new Accordion({ id: 'specs' });
    $('.package-more-details').on('click', this.handlePackageDetailsMore);
    $('.package-title').on('click', this.handlePackageTitleClick);
  }

  /**
    * @function handlePackageTitleClick
    * @description Handles the click event when a package title is clicked
    * This is currently being used to lazy load the package image
    */
  handlePackageTitleClick(el) {

  }

  /**
    * @function handlePackageDetailsMore
    * @description Handles the click event when the learn more link is clicked from
    * inside a package.
    * Currently this is triggering a modal and injecting the relevant content for that package
    */
  handlePackageDetailsMore(el) {
    const packageType = $(el.currentTarget).closest('.accordion-item');
    const itemTitle = $(packageType.children()[0]).text();
    const selectedPackage = $(el.currentTarget).closest('.package');
    const pd = $('.package-details');
    const content = pd.find('.package-details-content');
    const packageInfo = {
      image: selectedPackage.find('.package-image').data('img'),
      title: selectedPackage.find('.package-title').text(),
      description: selectedPackage.find('.description').text()
    }

    if (this.BRAND === 'bmw' && itemTitle === 'Packages') {
      delete packageInfo.image;
    } else if (this.BRAND === 'mini' && itemTitle === 'Packs') {
      delete packageInfo.image;
    }
    content.html('');
    content.append(packageDetailsTemplate({ data: packageInfo }));

    $('body').addClass('package-detail-open');
  }

  /**
    * @function handlePackageDetails
    * @description Handles the click event on the package details modal.
    * Currently this is looking for clicks on either the close button or the overlay.
    */
  handlePackageDetails(el) {
    const target = $(el.target);

    if (target.closest(".package-details-inner").length === 0 || target.closest(".package-details-close").length > 0) {
      $('body').removeClass('package-detail-open');
    }
  }

  /**
    * @function handleViewQuote
    * @description Handles click event from View Quote link in hero
    */
  handleViewQuote() {
    /** [TRACY_EVENT] **/
    const ref = `stock > ${this.BRAND} > vehicle details > view quote`;
    const parameters = { type: 'success', eventAction: 'internal click' };
    setTracyEvent(ref, parameters);
    /** [END] **/
  }

  /**
    * @function handleViewMap
    * @description Handles click event from View Map link
    */
  handleViewMap() {
    /** [TRACY_EVENT] **/
    const ref = `stock > ${this.BRAND} > vehicle details > view map`;
    setTracyEvent(ref);
    /** [END] **/
  }

  /**
    * @function handlePackagesHeader
    * @description Handles click event from Packages Header links
    */
  handlePackagesHeader(el) {
    const target = $(el.currentTarget);
    const selectedPackage = target.find('.item-title').text();
    const image = target.find('.package-image');

    $.each(image, (i, img) => {
      const image = $(img);
      const path = image.data('img');

      if (!image.hasClass('loaded')) {
        image.addClass('loaded');
        image.attr('src', path);
      }
    });

    if (this.BRAND === 'bmw' && selectedPackage === 'Packages') {
      image.hide();
    }

    // mini check for 'Packs' instead of 'Packages' for prod
    if (this.BRAND === 'mini' && selectedPackage === 'Packs') {
      image.hide();
    }

    // catch for Packages in dev env for mini
    if (selectedPackage === 'Packages') {
      image.hide();
    }

    /** [TRACY_DATA_OBJECT] **/
    tracyData.packageAndEquipment = selectedPackage;
    tracyData.contentOpenClose = target.hasClass('open') ? 'Open' : 'Close';
    /** [END] **/

    /** [TRACY_EVENT] **/
    const ref = `stock > ${this.BRAND} > vehicle details > package options`;
    setTracyEvent(ref);
    /** [END] **/
  }

  /**
  * @function handleResize
  * @description Creates an event listener for when the browser window resizes
  */
  handleResize() {
    this.notification.hide();
  }

  /**
  * @function handleBaseToolTip
  * @description Handles the opening of tooltips
  */
  handleBaseToolTip(e) {
    e.stopPropagation();
    const target = $(e.currentTarget);
    const otherToolTips = $('.tooltip-container');
    const container = target.find('.js-tooltip');
    otherToolTips.hide();
    container.show();
  }

  /**
  * @function handleBaseToolTipClose
  * @description Handles the opening of tooltips
  */
  handleBaseToolTipClose(e) {
    e.stopPropagation();
    $('.js-tooltip').hide();
  }

  /**
  * @function handleModalClose
  * @description Handles the closing of modals
  */
  handleModalClose() {
    const modal = document.querySelector('.js-modal');

    modal.style.display = 'none';
    document.body.classList.remove('modal-open');

    this.resetModalState();

  }

  resetModalState() {
    const modal = document.querySelector('.js-modal');
    const modalButtons = modal.querySelectorAll('.js-modal--reserve-enquire .btn');

    if (modal.classList.contains('js-modal--reserve-enquire')) {
      const inputWrapper = modal.querySelector('.location-textbox');
      const inputField = modal.querySelector('input.text');
      const dealerList = modal.querySelector('.js-group-dealer-select-menu');

      if (inputWrapper) {
        inputWrapper.classList.remove('valid');
        inputWrapper.classList.remove('invalid');
      }

      if (inputField) {
        if (window.isAirstripStock && !window.groupRetailerView && !window.groupView && !window.dealerView) {
          inputField.value = window.currentPostcode;
          this.locationDisplay.validatePostcode();
        } else {
          inputField.value = '';
        }
      }

      modalButtons.forEach(button => {
        button.setAttribute('disabled', 'disabled');
      });

      while(dealerList.firstChild) {
        dealerList.removeChild(dealerList.firstChild);
      }
    }
  }

  handleDealerSelect(event) {
    let quoteData = this.quoteData;

    if (event.target.classList.contains('selected-group-dealer')) {
      let dealerSelected = parseInt(event.target.value);
      quoteData.dealer_number = dealerSelected;
      this.updateQuoteData(quoteData);
    }
  }

  updateQuoteData(newQuoteData) {
    const buttonReserveContinue = document.querySelectorAll('.bt-reserve-continue');
    const buttonEnquireContinue = document.querySelectorAll('.bt-enquire-continue');
    const modalButtons = document.querySelectorAll('.js-modal--reserve-enquire .btn');

    let quoteData = newQuoteData || this.quoteData;

    const quoteRequest = $.get('/final_quote_ref', quoteData);

    quoteRequest.done((data) => {
      if (data["error"]) {
        alert(data["error"]);
        return false;
      };

      if (window.groupView || window.isAirstripStock) {
        buttonReserveContinue.forEach((button) => {
          let buttonReserveContinueUrl = button.getAttribute('href');
          let newButtonReserveContinueUrl = buttonReserveContinueUrl.replace(/(quoteref=).*?(&|$)/,'$1' + data.quote_ref + '$2');

          newButtonReserveContinueUrl = newButtonReserveContinueUrl.replace(/(retailer=).*?(&|$)/,'$1' + quoteData.dealer_number + '$2');
          button.setAttribute('href', newButtonReserveContinueUrl);
        });

        buttonEnquireContinue.forEach((button) => {
          let buttonEnquireContinueUrl = button.getAttribute('href');
          let newButtonEnquireContinueUrl = buttonEnquireContinueUrl.replace(/(ref=).*?(&|$)/,'$1' + data.quote_ref + '$2');

          newButtonEnquireContinueUrl = newButtonEnquireContinueUrl.replace(/(id=).*?(&|$)/,'$1' + quoteData.dealer_number + '$2');
          button.setAttribute('href', newButtonEnquireContinueUrl);
        });
      }

      modalButtons.forEach(button => {
        button.removeAttribute('disabled');
      });

    });
  }

  /**
  * @function handleEnquireContinueButtonClick
  */
  handleEnquireContinueButtonClick(event) {
    this.updateQuoteData();
  }

  /**
  * @function handleReserveBtnClick
  */
  handleReserveBtnClick(event) {
    // Capture GA event
    captureReserveEvent(event);

    if (event.currentTarget.classList.contains('disabled')) {
      return false;
    }

    if (window.groupView || window.isAirstripStock) {
      this.handleModalClose();
    }

    /** [TRACY_EVENT] **/
    const ref = `stock > ${this.BRAND} > vehicle details > reserve vehicle`;
    setTracyEvent(ref);
    /** [END] **/
  }

  /**
  * @function initQuoteCalculator
  * @description Initializes the quote calculator and passes any required options.
  * @param {Object} options - The options object with the initialization parameters
  */
  initQuoteCalculator(data) {

    this.updateCostInfo(data);
    this.updateDisclaimer(data);
    $('.bt-reserve').removeClass('disabled');
  }

  /**
  * @function updateDisclaimer
  * @description Show/hide disclaimer text based on product type.
  * @param {Object} data - The data object used to determine product type
  */
  updateDisclaimer(data) {
    const type = data.quote.productType;

    if (type === "PCP" || type === "HP" || type === "PCH") {
      $('.otr-disclaimer').hide();
    } else {
      $('.otr-disclaimer').show();
    }
  }

  /**
  * @function updateCostInfo
  * @description Updates the cost info across the page.
  * @param {Object} data - The data object for quote details
  */
  updateCostInfo(data) {
    let payment = data.quote.regularPayment;
    const productType = data.quote.productType;
    payment = new Intl.NumberFormat("en", { style: "currency", currency: "GBP" }).format(payment);

    if (productType === "PCP" || productType === "HP" || productType === "PCH") {
      $('.price--monthly .price__text').html(`
        ${payment} per month
      `);
    } else {
      let retailerContribution = 0;
      let manufacturerContribution = 0;
      if (data.quote.deposit !== undefined) {
        if(data.quote.deposit.retailerContribution !== undefined) retailerContribution = data.quote.deposit.retailerContribution
        if(data.quote.deposit.manufacturerContribution !== undefined) manufacturerContribution = data.quote.deposit.manufacturerContribution
      }
      $('.price--otr .price__text').html(`
        ${(data.quote.totalAmountPayable - retailerContribution - manufacturerContribution).toLocaleString("en", { minimumFractionDigits: 2, style: "currency", currency: "GBP" })} on the road price
      `);
    }

    const favLink = $('.link-favourite');
    const vin = favLink.data('vin');
    const iv = favLink.data('iv');
    const dealer_number = favLink.data('dealernumber');

    this.quoteData = {
      term: data.quote.term,
      deposit: data.quote.deposit.cash,
      mileage: data.quote.annualMileage,
      iv: iv,
      vin: vin,
      product_type: productType,
      dealer_number: dealer_number
    };

    // Update the quote refs in the CTAs
    if (!window.groupView && !window.isAirstripStock) {
      const enquireButton = document.querySelector('.bt-enquire');
      const reserveButton = document.querySelector('.bt-reserve');

      const newQuoteRef = data.quote.quoteReference;

      if (enquireButton !== null) {
        const currentEnquireURL = $('.bt-enquire').attr('href');
        const newEnquireURL = currentEnquireURL.replace(/(ref=).*?(&|$)/,'$1' + newQuoteRef + '$2');
        $('.bt-enquire').attr('href', newEnquireURL);
      }

      if (reserveButton !== null) {
        const currentReserveURL = $('.bt-reserve').attr('href');
        const newReserveURL = currentReserveURL.replace(/(quoteref=).*?(&|$)/,'$1' + newQuoteRef + '$2');
        $('.bt-reserve').attr('href', newReserveURL);
      }
    }
  }

  /**
  * @function handleInfoClick
  * @description Handles the info button click which will trigger the dialog
  * open command and display the car information.
  * @param {Object} e - The onscroll event object
  */
  handleInfoClick(e) {
    this.dialogCom.show({});
  }

  /**
  * @function handleReserveModal
  * @description Handles the learning reserve popup
  * open command and display steps on learning how to reserve a vehicle
  * @param {Object} event - The click event object
  */
  handleReserveModal(event) {
    event.preventDefault();
    this.dialogLabel.show({})
  }

  /**
  * @function handleEnquireClick
  * @description Handles the logic when the Enquire button is clicked
  * @param {Object} e - The click event object
  */
  handleEnquireClick(event) {
    // Capture GA event
    captureEnquireEvent(event);

    if (window.groupView || window.isAirstripStock) {
      this.showEnquiryModal()
    }

    const target = $(event.currentTarget);

    if (window.touchscreenSession) {
      event.preventDefault();

      const parent = target.parent().parent();
      const content = "If you would like to find out more about this specific vehicle, please ask to talk to one of the sales team, who will be happy to help.";

      this.notification.show({ parent, content });
    }

    /** [TRACY_EVENT] **/
    const ref = `stock > ${this.BRAND} > vehicle details > enquire now`;
    setTracyEvent(ref);
    /** [END] **/
  }

  /**
  * @function handleStickyNav
  * @description This event is fired on scroll to determine the position of the
  * hero banner.
  * @param {Object} e - The onscroll event object
  */
  handleStickyNav(e) {
    const heroHeight = this.heroBanner.offsetHeight;

    if (window.pageYOffset >= heroHeight) {
      if (window.innerWidth >= 768) {
        this.stickyHeader.addClass('visible');
        this.stickyFooter.addClass('visible');
      } else {
        this.stickyHeader.removeClass('visible');
        this.stickyFooter.removeClass('visible');
      }
    } else {
      if (window.innerWidth >= 768) {
        this.stickyHeader.removeClass('visible');
        this.stickyFooter.removeClass('visible');
      }
    }
  }

  /**
  * @function handleCompareClick
  * @description Handles the click of the compare icon
  * @param {Object} ev - The click event object
  */
  handleCompareClick(ev) {
    const target = $(ev.currentTarget);
    const vehicle_id = target.data('vin');
    const orderNumber = target.data('orderno');
    const iv = target.data('iv');
    const dealer_number = target.data('dealernumber');

    const resultObject = this.search(orderNumber, 'orderNumber', this.COMPARE);

    if (resultObject) {
      const index = this.COMPARE.indexOf(resultObject);
      this.COMPARE.splice(index, 1);
      target.removeClass('in-comparison');
    } else {
      if (this.COMPARE.length >= 3) {
        alert("The maximum number of cars has been added.");
        return false;
      }

      const newVehicle = {
        iv: iv,
        orderNumber: orderNumber,
        vin: vehicle_id,
        dealer_number: dealer_number
      }

      this.COMPARE.push(newVehicle);
      target.addClass('in-comparison');
    }
    const allVehicles = {
      vehicles: this.COMPARE
    }

    window.localStorage.setItem('compare', JSON.stringify(this.COMPARE));

    CompareVehicles.postComparisonData(allVehicles);
  }

  /**
  * @function updateCompareIcon
  * @description Adds class to a vehicle if compared
  */
  updateCompareIcon() {
    const orderNumber = this.compareLink.data('orderno');
    const resultObject = this.search(orderNumber, 'orderNumber', this.COMPARE);

    if (resultObject) {
      this.compareLink.addClass('in-comparison');
    }
  }

  // COMPARE
  // Find a key value in an array of objects
  search(nameKey, prop, myArray) {
    for (var i=0; i < myArray.length; i++) {
      if (myArray[i][prop] === nameKey) {
        return myArray[i];
      }
    }
  }

  /**
  * @function resetGroupEnquiry
  * @description Resets the modal and hides the headers
  */
  resetGroupEnquiry() {
    const modalHeaders = {
      'enquiry': document.querySelector('.enquiry-modal-header'),
      'reservation': document.querySelector('.reservation-modal-header')
    };

    for (const header in modalHeaders) {
      modalHeaders[header].style.display = 'none';
    }
  }

  /**
  * @function handleCloseEnquiryModal
  * @description Closes the enquiry group modal
  * @param {Object} e - The event object
  */
  handleCloseEnquiryModal(e) {
    this.resetGroupEnquiry();
    this.handleModalClose();
  }

  /**
  * @function showReservationModal
  * @description Shows the reservation elements in the group modal
  */
  showReservationModal() {
    this.resetGroupEnquiry();
    this.showReservationModalActions();
    this.resetModalState();

    const elementsToShow = {
      'modal': document.querySelector('.js-modal--reserve-enquire'),
      'reservationHeader': document.querySelector('.reservation-modal-header')
    };

    for (const element in elementsToShow) {
      elementsToShow[element].style.display = 'block';
    }

    document.body.classList.add('modal-open');
  }

  /**
  * @function showEnquiryModal
  * @description Shows the enquiry elements in the group modal
  */
  showEnquiryModal() {
    this.resetGroupEnquiry();
    this.showEnquiryModalActions();
    this.resetModalState();

    const elementsToShow = {
      'modal': document.querySelector('.js-modal--reserve-enquire'),
      'enquiryHeader': document.querySelector('.enquiry-modal-header')
    };

    for (const element in elementsToShow) {
      elementsToShow[element].style.display = 'block';
    }

    document.body.classList.add('modal-open');
  }

  /**
  * @function showEnquiryModalActions
  * @description Show the correct CTA buttons in the group enquiry modal
  */
  showEnquiryModalActions() {
    const elementsToHide = {
      'buttonEnquire': document.querySelector('.group-enquiry .bt-enquire'),
      'buttonReserve': document.querySelector('.group-enquiry .bt-reserve'),
      'buttonReserveContinue': document.querySelector('.group-enquiry .bt-reserve-continue'),
      'reserveInfo': document.querySelector('.group-enquiry .reserve-info')
    };

    const elementsToShow = {
      'buttonEnquireContinue': document.querySelector('.group-enquiry .bt-enquire-continue')
    };

    for (const element in elementsToHide) {
      if (elementsToHide[element]) {
        elementsToHide[element].style.display = 'none';
      }
    }

    for (const element in elementsToShow) {
      if (elementsToShow[element]) {
        elementsToShow[element].style.display = 'block';
      }
    }
  }

  /**
  * @function showReservationModalActions
  * @description Show the correct CTA buttons in the group reservation modal
  */
  showReservationModalActions() {
    const elementsToHide = {
      'buttonReserve': document.querySelector('.group-enquiry .bt-reserve'),
      'buttonEnquire': document.querySelector('.group-enquiry .bt-enquire'),
      'buttonEnquireContinue': document.querySelector('.group-enquiry .bt-enquire-continue')
    };

    const elementsToShow = {
      'buttonReserveContinue': document.querySelector('.group-enquiry .bt-reserve-continue')
    };

    for (const element in elementsToHide) {
      elementsToHide[element].style.display = 'none';
    }

    for (const element in elementsToShow) {
      elementsToShow[element].style.display = 'block';
    }
  }
}

export default CarDetail;
