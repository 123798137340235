/**
  * @function setTracyEvent
  * @description Creates a Tracy event if the tracy lib exists
  * @param {string} ref - The event name/reference
  * @param {object} params - The event params
  */
export const setTracyEvent = (ref, params) => {
  try {
    tracy.event(ref, params);
  } catch(error) {
    console.log(error);
  }
}

/**
  * @function setTracyPage
  * @description Creates a Tracy page if the Tracy lib exists
  * @param {string} ref - The page name/reference
  * @param {object} params - The page params
  */
export const setTracyPage = (ref, params) => {
  try {
    tracy.page(ref, params);
  } catch(error) {
    console.log(error);
  }
}

/**
  * @function setTracySearchFilter
  * @description Adds a Tracy search filter if the Tracy lib exists
  * @param {string} state - Either preselected/user selected
  * @param {string} unit - The unit of measurement
  * @param {string} label - The label
  * @param {string} term - The search term
  */
export const setTracySearchFilter = (state, unit, label, term) => {
  try {
    tracy.searchFilter.add(state, unit, label, term);
  } catch(error) {
    console.log(error);
  }
}

/**
  * @function setTracyProductAdd
  * @description Calls the add function on product object
  * @param {string} ref - reference name - in this format: 'stock > ${BRAND} > vehicle details'
  */
export const setTracyProductAdd = (ref) => {
  try {
    tracy.product.add(ref);
  } catch (error) {
    console.log(error);
  }
}