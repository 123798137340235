/**
 * @class Accordion
 * @description A simple accordion component
 */

import { matchHeight } from "../../utils/match-height";

 class Accordion {
  constructor(options = {}) {
    this.id = options.id || 'default-accordion-id';
    this.destroy();
    this.init();
  }

  /**
   * @function init
   * @description Initialize the Accordion, perform any prerequisite rendering
   * before assigning any elements
   */
  init() {
    // TODO - Extend this component, what if Rails does not render the elements,
    // update the component to accept a data object and dynamically render internally
    // or support the new implemented handlebars.

    this.getItems();
  }

  /**
   * @function getItems
   * @description This will retrieve any items already inserted on the dom
   * and add the click event listeners
   */
  getItems() {
    const items = $(`#${this.id} .accordion-item .item-title`);
    const itemChildren = $(`#${this.id} .accordion-child-title`);

    if (items) {
      if (items.data('accordion-group')) {
        items.on('click', this.toggleGroup);
      } else {
        items.on('click', this.toggleState);
      }
    }

    if (itemChildren) {
      itemChildren.on('click', this.toggleChildNode);
    }
  }

  /**
   * @function toggleState
   * @description Click event handler to toggle the state of an accordion item
   * open or closed, by simply adding or removing a class
   */
  toggleState(e) {
    const item = $(e.currentTarget).closest('.accordion-item');

    if (item.hasClass('open')) {
      item.removeClass('open');
      return;
    }

    item.addClass('open');
  }

  destroy() {
    const items = $(`#${this.id} .accordion-item .item-title`);
    const itemChildren = $(`#${this.id} .accordion-child-title`);

    if (items) {
      items.off('click', this.toggleGroup);
      items.removeClass('open');
    }

    if (itemChildren) {
      itemChildren.off('click', this.toggleChildNode);
      itemChildren.removeClass('open');
    }
  }

  /**
   * @function toggleGroup
   * @description Click event handler to toggle the state of a group of accordion items
   * open or closed, by simply adding or removing a class
   */
  toggleGroup(event) {
    const allGroupHeadings = document.querySelectorAll(`[data-accordion-group="${event.currentTarget.dataset.accordionGroup}"]`);
    let allGroupContent = [];

    allGroupHeadings.forEach((heading) => {
      const groupItem = heading.closest('.accordion-item');

      if (groupItem.classList.contains('open')) {
        groupItem.classList.remove('open');
        return;
      }

      const groupContent = heading.nextElementSibling.querySelector('.item-info');
      groupItem.classList.add('open');

      if (groupContent.classList.contains('match-height')) {
        allGroupContent.push(groupContent);
        matchHeight(allGroupContent);
      }
    });
  }

  /**
   * @function toggleChildNode
   * @description Click event handler to toggle the child node of an accordion item
   */
  toggleChildNode(e) {
    const item = $(e.currentTarget).closest('.accordion-child');

    if (item.hasClass('open')) {
      item.removeClass('open');
      return;
    }

    item.addClass('open');
  }
}

export default Accordion;
