export const checkForFlexOverflow = (flexParentElement) => {
  let row = 0;

  [...flexParentElement.children].forEach((flexChild) => {
    if (!flexChild.previousElementSibling || flexChild.offsetLeft <= flexChild.previousElementSibling.offsetLeft) {
      row++;
    }

    flexParentElement.dataset.flexRows = row;
    flexChild.dataset.flexRow = row;

    if (row > 1) {
      flexChild.dataset.flexWrappedItem = 'true';

      if (flexParentElement.classList.contains('flex-wrapped-rows')) return;

      flexParentElement.classList.add('flex-wrapped-rows');
    } else {
      flexChild.dataset.flexWrappedItem = 'false'

      if (!flexParentElement.classList.contains('flex-wrapped-rows')) return;

      flexParentElement.classList.remove('flex-wrapped-rows');
    }
  });

  const wrappedElements = document.querySelectorAll('[data-flex-wrapped-item="true"]');
  const numberOfWrappedElements = wrappedElements.length;

  return numberOfWrappedElements;
};
