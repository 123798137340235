import Countdown from './components/countdown/countdown';
import Links from './links';

class Exclusive {

  constructor() {

    const countdownHandler = function(countdown) {

      let ctaLink = 'https://stock.bmw.co.uk';
      let availabilityMarkup = '<h2>available soon - 24.11.23</h2>';

      switch(countdown.state) {
        case 'stopped':
          ctaLink = Links.exclusiveStock;
          availabilityMarkup = `<a href="${ctaLink}">Stock available now</a>`;
        break;
      };

      $('#availability').html(availabilityMarkup);
      $('#view-stock-link').attr('href', ctaLink);

    };

    new Countdown({
      target: document.querySelector('.countdown .timer'),
      onStart: countdownHandler,
      onStop: countdownHandler
    });

    const $window = $(window);
    const $document = $('html');

    function getPosition($target, offsetByHalf = true) {
      const scroll = $window.scrollTop();
      const offset = $target.offset();
      const top = offset.top;
      const halfHeight = ($window.outerHeight() / 2);
      let fromTop = (top - scroll);
      let fromBottom = (scroll + $window.outerHeight()) - (top + $target.outerHeight());
      if (offsetByHalf) {
        fromTop -= halfHeight;
        fromBottom -= halfHeight;
      };
      fromTop = Math.round(fromTop);
      fromBottom = Math.round(fromBottom);
      return {
        fromTop,
        fromBottom,
        test: (fromTop < 0 && fromBottom < 0),
        stuck: (fromTop === 0 && fromBottom === 0)
      };
    };

    $('.purchasing-process').each(function() {

      const $this = $(this);
      const pages = $this.find('.page');
      const stuck = $this.find('.sticky');

      $window.on('scroll', function() {

        const position = getPosition(stuck, false);
        const position2 = getPosition($this, false);

        $document.attr('data-scroll-snap', position.stuck && position2.test);

        pages.each(function() {

          const $target = $(this);
          const { test } = getPosition($target);

          $(`#${$target.attr('data-target')}`).attr('data-active', test);

        });

      });

    });

    const tiles = $('.tile').each(function() {

      let active = true;

      const $tile = $(this).on('click', function() {

        active = !active;
        $tile.attr('data-active', active);
        setStyle();
        return false;

      }).on('resize.tile', function() {

        setStyle();

      }),
      setStyle = function() {

        footBody.css({
          'margin-bottom': active ? 0 : ((footBody.outerHeight() + 20) * -1),
          'opacity': active ? 1 : 0
        });

      },
      footBody = $tile.find('.tile-foot-body');

      $tile.find('img').on('load', function() {
        $tile.trigger('click');
      });

    });

    $(window).on('resize', function() {
      tiles.trigger('resize.tile');
    });

  };

}

export default Exclusive;
