import bindAll from 'lodash/bindAll';

/**
 * @class Location Display
 * @description Extendable base component with some common functionality
 */

class ComponentBase {

  /**
   * @function init
   * @description Common initialization function for shared components
   */
  init() {
    bindAll(this,
    'handleTabClick',
    'validatePostcode'
    );
    if (this.tabs && this.tabs.length > 0) {
      this.tabs.on('click', this.handleTabClick);
    }
  }

  /**
   * @function handleTabClick
   * @description Handles the tab click event to switch views
   */
  handleTabClick(e) {
    const target = $(e.currentTarget);
    const id = target.data('tab');
    const tabs = target.closest('.tabs');

    tabs.find('.tab-nav > .selected').removeClass('selected');
    tabs.find('> .selected').removeClass('selected');
    tabs.find(`[data-tab='${id}']`).addClass('selected');
    tabs.find(`[data-tab-content='${id}']`).addClass('selected');
    return id;
  }

  /**
   * @function validatePostcode
   * @description Keydown event handler for determining valid postcodes
   */
  validatePostcode(e) {
    const postcodeBox = $('.location-textbox .text');
    const postCodeWrapper = postcodeBox.closest('.location-textbox');
    const postcode = this.inputPostCode[0].value.trim();
    const postcodeRegex = RegExp('^([A-Za-z][A-Ha-hJ-Yj-y]?[0-9][A-Za-z0-9]? ?[0-9][A-Za-z]{2}|[Gg][Ii][Rr] ?0[Aa]{2})$');

    if (postcodeRegex.test(postcode)) {
      let location = { postcode }
      postCodeWrapper.removeClass('invalid');
      postCodeWrapper.addClass('valid');
      postcodeBox[0].value = postcode.toUpperCase();
      this.handlePostCodeValid(location);
    } else {
      postCodeWrapper.addClass('invalid');
      postCodeWrapper.removeClass('valid');
      this.handlePostCodeInvalid(location);
    }
  }

  /**
   * @function createSlider
   * @description Creates a slider instance
   */
  createSlider(el, options, display) {
    const defaultOptions = {
      range: options.range || true,
      min:  options.min || 0,
      max:  options.max || 3000,
      step: options.step || 10,
      create: () => {
        if (options.values === undefined && options.value === undefined) {
          display.text(options.initText || '');
        } else if (options.values) {
          display.text(options.formatDisplay({ values: defaultOptions.values }));
        } else if (options.value) {
          display.text(options.formatDisplay({ value: defaultOptions.value }));
        }
      },
      slide: (event, ui) => {
        display.text(options.formatDisplay(ui));
      },
      stop: (event, ui) => {
        if (options.onStop) {
          options.onStop(ui.values || ui.value);
        }
      },
      change: (e) => {}
    };
    if (options.values) {
      defaultOptions.values = options.values;
    }
    if (options.value) {
      defaultOptions.value = options.value;
    }

    el.slider(defaultOptions);
  }

  /**
   * @function handlePostCodeValid
   * @description Abstraction, should be overriden with component specific callback
   * functionality
   */
  handlePostCodeValid(location) {}

  /**
   * @function handlePostCodeInvalid
   * @description Abstraction, should be overriden with component specific callback
   * functionality
   */
  handlePostCodeInvalid(location) {}
}

export default ComponentBase;