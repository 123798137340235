import { checkForFlexOverflow } from "./../../utils/check-for-flex-overflow";
import { changeButtonState } from "./../../utils/change-button-state";

/**
 * @class SearchTags
 * @description Applying and displaying search tags on the search page
 */

class SearchTags {
  static updateSearchTagsArray(bodyTypeClicked, bodyTypeArray, brand) {
    if (bodyTypeArray.some(vehicle => vehicle.modelNumber === bodyTypeClicked.modelNumber)) {
      const index = bodyTypeArray.map((item) => {
        return item.modelNumber
      }).indexOf(bodyTypeClicked.modelNumber);

      bodyTypeArray.splice(index, 1);
      this.deselectVehicle(bodyTypeClicked);
      this.updateBodyTags(bodyTypeArray, brand);
    } else {
      bodyTypeArray.unshift(bodyTypeClicked);
      this.updateBodyTags(bodyTypeArray, brand);
    }
  };

  static handleSelectAllSeriesClick(event) {
    const thisJsClass = SearchTags;
    const clickTarget = event.currentTarget;
    const selectAllButtonActive = clickTarget.classList.contains('is-active');
    const seriesToSelect = clickTarget.dataset.seriesSelected;
    const vehicles = document.querySelectorAll('.car-selector-result');

    vehicles.forEach(vehicle => {
      if (vehicle.classList.contains('disabled')) return;
      if (vehicle.classList.contains('is-out-of-stock')) return;

      const vehicleSeries = vehicle.dataset.series;

      if (this.BRAND === 'bmw') {
        if (vehicleSeries !== seriesToSelect) return;
      }

      if (selectAllButtonActive) {
        if (vehicle.classList.contains('selected')) {
          vehicle.click();
        }
      } else {
        if (!vehicle.classList.contains('selected')) {
          vehicle.click();
        }
      }
    });

    thisJsClass.checkIfAllVehiclesInSeriesAreSelected(seriesToSelect);
  };

  static checkIfAllVehiclesInSeriesAreSelected(series) {
    const selectedClass = 'selected';
    const inStockClass = 'is-in-stock';
    const vehicles = document.querySelectorAll(`.car-selector-result[data-series="${series}"`);

    const vehiclesArray = Array.prototype.slice.call(vehicles);
    const vehiclesInStockArray = vehiclesArray.filter((value) => value.classList.contains(inStockClass));

    if (vehiclesInStockArray.length !==0) {
      const allVehiclesSelected = Array.from(vehiclesInStockArray).every((vehicle) => vehicle.classList.contains(selectedClass));
      this.toggleSelectAllButtonState(allVehiclesSelected);
    } else {
      this.toggleSelectAllButtonState(false);
    }
  };

  static toggleSelectAllButtonState(isActive) {
    const selectAllButton = document.getElementById('js-search-tag-select-all');
    const selectAllButtonText = selectAllButton.querySelector('.select-all-text');
    const selectAllButtonCopy = {
      'select': 'Select all',
      'deselect': 'Deselect all'
    };

    if (isActive){
      if (selectAllButton.classList.contains('is-active')) return;
      selectAllButton.classList.add('is-active');
      selectAllButtonText.textContent = selectAllButtonCopy.deselect;
    } else {
      if (!selectAllButton.classList.contains('is-active')) return;
      selectAllButton.classList.remove('is-active');
      selectAllButtonText.textContent = selectAllButtonCopy.select;
    }
  };

  static expandTags() {
    const searchTagsSelectedContainer = document.querySelector('.search-tags__selected-container');
    const searchTagToggle = document.querySelector('.js-search-tags-toggle');

    searchTagsSelectedContainer.classList.toggle('is-open');
    searchTagToggle.classList.toggle('is-expanded');
  };

  static deselectVehicle(vehicleData) {
      const resultsContainer = document.querySelector('.car-selector-results');
      const hiddenResultsContainer = document.querySelector('.hidden-models');
      const modelNumber = vehicleData.modelNumber;
      const vehicleToRemove = resultsContainer.querySelector(`[data-modelno="${modelNumber}"]`);

      vehicleToRemove.classList.remove('selected');

      if (hiddenResultsContainer) {
        const hiddenVehiclesToRemove = hiddenResultsContainer.querySelectorAll(`[data-series="${modelNumber}"]`);

        hiddenVehiclesToRemove.forEach(item => {
          item.classList.remove('selected');
        });
      }
  };

  static updateBodyTags(tagsArray, brand) {
    const tagContainerElement = document.querySelector('.search-tags__selected-container');

    tagContainerElement.innerHTML = '';

    tagsArray.forEach(bodyTypeObject => {
      let modelName;

      if (brand === 'bmw') {
        modelName = bodyTypeObject.modelName.replace('BMW ', ' ');
      } else {
        modelName = bodyTypeObject.modelName;
      }
      const buttonElement = `
        <button class="search-tag js-search-tag" id="model-number-${bodyTypeObject.modelNumber}" data-series="${bodyTypeObject.series}" data-body="${bodyTypeObject.body}" data-modelno="${bodyTypeObject.modelNumber}">
          <span class="js-search-tag-remove-icon search-tag__remove-icon"></span>
          ${modelName}
        </button>
      `;

      tagContainerElement.innerHTML += buttonElement;
    });

    checkForFlexOverflow(tagContainerElement);
    this.updateToggleNumberValue(tagContainerElement);
    this.toggleContainerClasses(tagContainerElement);
  };

  static updateToggleNumberValue(containerElement) {
    const numberOfWrappedSearchTags = checkForFlexOverflow(containerElement);
    const searchTagsWrappedNumberSelector = document.querySelector('.search-tags__number');

    if (numberOfWrappedSearchTags > 0) {
      searchTagsWrappedNumberSelector.innerHTML = `+${numberOfWrappedSearchTags}`;
    } else {
      searchTagsWrappedNumberSelector.innerHTML = '';
    }
  };

  static toggleContainerClasses(containerElement) {
    const searchButton = document.querySelector('.search-button');

    if (containerElement.innerHTML === '') {
      containerElement.classList.remove('has-tags');
      containerElement.classList.add('is-empty');

      changeButtonState(searchButton, 'disable');
    } else {
      containerElement.classList.remove('is-empty');
      containerElement.classList.add('has-tags');

      if (searchButton.classList.contains('is-valid-search')) {
        changeButtonState(searchButton, 'enable');
      }
    }
  };
}

export default SearchTags;
