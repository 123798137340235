import bindAll from 'lodash/bindAll';
import defaults from 'lodash/defaults';
import { areSearchTagsApplied } from "./../../utils/are-search-tags-applied";
import { changeButtonState } from "./../../utils/change-button-state";

/**
 * @class SearchTabs
 * @description
 * Functionality within the search tabs
 */

const selectors = {
  budgetTab: '.budget-display.tabs li',
  locationTab: '.location-display .tabs li',
  monthlyBudgetSlider: '.budget-display .monthly .js-range-slider',
  monthlyBudgetValue: '.budget-display .monthly .range-value',
  OTRBudgetSlider: '.budget-display .otr .js-range-slider',
  OTRBudgetValue: '.budget-display .otr .range-value',
  locationSlider: '.location-display .js-range-slider',
  locationRangeValue: '.location-display .range-value',
  searchButton: '.search-button',
  tabsWrapper: '.tabs-wrapper',
  locationPostcode: '.location-textbox .text',
  geoLocateBtn: '.js-geo-locate',
  locationDealerSelectMenu: '.location-display .retailer .js-select-menu',
}

class SearchTabs {
  constructor(options = {}) {
    this.options = defaults({}, options, selectors);

    this.init();
  }

  init() {
    bindAll(this,
      'handleTabClick',
      'enableTabs',
      'handleRetailerSearch',
      'getDealerList',
      'geoSuccess',
      'geoError',
      'handleGeoClick'
    );

    this.POSTCODEREGEX = RegExp('^([A-Za-z][A-Ha-hJ-Yj-y]?[0-9][A-Za-z0-9]? ?[0-9][A-Za-z]{2}|[Gg][Ii][Rr] ?0[Aa]{2})$');
    this.searchButton = document.querySelector(this.options.searchButton);

    $(this.options.budgetTab).on('click', this.handleTabClick);
    $(this.options.locationTab).on('click', this.handleTabClick);
    $(this.options.locationPostcode).on('input', this.handleRetailerSearch);
    $(this.options.geoLocateBtn).on('click', this.handleGeoClick);
  }

  handleGeoClick(ev) {
    if (!navigator.geolocation) {
      alert('Geo location not available on this browser');
    } else {
      navigator.geolocation.getCurrentPosition(this.geoSuccess, this.geoError);
      const $textbox = $(ev.currentTarget).closest('.location-textbox').find('.text');
      $textbox[0].value = '';
      $textbox.attr('placeholder', 'Locating...');
    }
  }

  updateButtonState() {
    if (window.distance_toggle) return;

    if (window.similar_search) {
      changeButtonState(this.searchButton, 'enable');
    } else if (areSearchTagsApplied()) {
      changeButtonState(this.searchButton, 'enable');
    }
  }

  geoSuccess(position) {
    $(this.options.lat)[0].value = position.coords.latitude;
    $(this.options.long)[0].value = position.coords.longitude;
    $(this.options.locationPostcode).attr('placeholder', 'Current Location');
    const location = { lat: position.coords.latitude, long: position.coords.longitude };
    const postcodeWrapper = $(this.options.locationPostcode).closest('.location-textbox');
    postcodeWrapper.addClass('valid');
    postcodeWrapper.removeClass('invalid');
    this.getDealerList(location);
    this.enableTabs();
  }

  geoError() {
    alert('Geo location failed, please check your browser location settings.');
    $(this.options.locationPostcode).attr('placeholder', 'Enter postcode');
  }

  enableTabs() {
    $(this.options.tabsWrapper).removeClass('disabled');
    this.searchButton.classList.add('is-valid-search');
  }

  getDealerList(location) {
    const params = {};
    const inGroup = window.location.pathname.split('/')[1] === "group";

    params["postcode"] = location["postcode"];

    if (inGroup) {
      params["group"] = window.location.pathname.split('/')[2];
    }

    const getDealers = $.get('/nearest_dealers', params);

    getDealers.done((data) => {
      if (data["error"]) {
        alert(data["error"]);
        return false;
      };

      $(this.options.locationDealerSelectMenu).empty();
      $(this.options.locationDealerSelectMenu).append($('<option value="">Please select</option>'));

      $.each(data, (i, option) => {
        if (!option.distance) { option.distance = 0 };

        $(this.options.locationDealerSelectMenu)
          .append($('<option></option>')
          .val(option.number)
          .html(`${option.title} (${option.distance} miles)`));
      });

      $(this.options.locationDealerSelectMenu).selectmenu('refresh');
    });
  }

  handleRetailerSearch(ev) {
    const postcodeBox = $('.location-textbox .text');
    const postCodeWrapper = postcodeBox.closest('.location-textbox');
    const postcode = postcodeBox[0].value.trim();

    if (this.POSTCODEREGEX.test(postcode)) {
      let location = { postcode };
      postCodeWrapper.removeClass('invalid');
      postCodeWrapper.addClass('valid');
      postcodeBox[0].value = postcode.toUpperCase();
      this.getDealerList(location);
      this.enableTabs();
      
      if (window.request_mode == "DISTANCE" && (window.similar_search || areSearchTagsApplied())) {
        changeButtonState(this.searchButton, 'enable');
      }
    } else {
      postCodeWrapper.addClass('invalid');
      postCodeWrapper.removeClass('valid');
      changeButtonState(this.searchButton, 'disable');
    }
  }

  handleTabClick(ev) {
    const target = $(ev.currentTarget);
    const id = target.data('tab');
    const tabs = target.closest('.tabs');

    if (id === 'location') {
      window.request_mode = 'DISTANCE';
      this.searchButton.classList.add('is-valid-search');
      $(this.options.selectMenu).val('').trigger('change');
      $('.retailer .ui-selectmenu-text').text('Please select');
      this.updateButtonState();
    } else if (id == 'retailer') {
      window.request_mode = 'DEALER';
      $('.retailer .ui-selectmenu-text').text('Please select');
      this.searchButton.classList.remove('is-valid-search');
      changeButtonState(this.searchButton, 'disable');
    }

    tabs.find('.tab-nav > .selected').removeClass('selected');
    tabs.find('> .selected').removeClass('selected');
    tabs.find(`[data-tab='${id}']`).addClass('selected');
    tabs.find(`[data-tab-content='${id}']`).addClass('selected');
  }
}

export default SearchTabs;
