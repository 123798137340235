import { setTracyPage } from './tracy';
import { setTracyEvent } from './tracy';

class CookiePolicy {
  constructor() {
    this.init();
  }

  init() {
    this.BRAND = $('html').data('brand');

    // Set Tracy page
    setTracyPage(`stock > ${this.BRAND} > cookie`);

    window.addEventListener('consentcontroller.api.initialized', this.cookiePageLoad.bind(this));
  }

  cookiePageLoad(e) {
    // The DOM for the cookie controller loads in the shadow DOM
    // So adding a small timeout before tryng to listen for clicks
    window.setTimeout(()=> {
      const container = document.querySelector('epaas-policy-page-shell').shadowRoot;
      const toggleBtn = container.querySelector('#policy-page-details-category-analytics .category-header__switch');
      $(toggleBtn).on('click', this.handleAnalyticsToggle.bind(this));
    }, 1000);
  }

  handleAnalyticsToggle(e) {
    /** [TRACY_EVENT] **/
    const ref = `stock > ${this.BRAND} > cookies > performance cookie`;
    setTracyEvent(ref);
    /** [END] **/
  }
}

export default CookiePolicy;
