export const getCookie = (processingID) => {

};

export const setCookie = (cname, cvalue, exdays) => {

};

/** Wrapper to check if analytics is enabled in epaas
 **/
export const analyticsAllowed = () => {
   let isAllowed;
   try {
      isAllowed = epaas.api.isUsageAllowed("AdobeAnalytics") || epaas.api.isUsageAllowed("GoogleAnalytics")
   } catch {
      isAllowed = false
   }
   return isAllowed
};
