import { searchArrayObjects } from "./../../utils/search-array-objects";

/**
 * @class Compare
 * @description Sticky sidebar when scrolled into the viewport
 */

class CompareVehicles {
  static updateCompareHeaderCount(count) {
    const comparePageSection = document.querySelector('.compare-page');
    const countLabel = document.querySelector('.js-compare-count');

    if (count > 0) {
      if (comparePageSection !== null) {
        countLabel.textContent = `${count}`;
      } else {
        countLabel.textContent = `(${count})`;
      }
    } else {
      countLabel.textContent = '';
    }
  };

  /**
   * @function postComparisonData
   * @description Posts vehicle data to the comparison page
   */
  static postComparisonData(data, redirectUrl) {
    const formData = JSON.stringify(data);
    const endpoint = '/results/compare/update';
    const loadingOverlay = document.querySelector('.app-loader');

    fetch(endpoint, {
      method: 'POST',
      body: formData,
      headers: {
        "Content-Type": "application/json"
      }
    }).then((json) => {
      if (loadingOverlay.classList.contains('visible')) {
        loadingOverlay.classList.remove('visible');
      }

      if (redirectUrl) {
        window.location.href = redirectUrl;
      }
    }).catch((error)=>{
      console.log("POST Error: ", error.message);
    });
  };

  static removeVehicleFromPage(event) {
    const vehicleTiles = document.querySelectorAll('.tile-container-main-slider .tile');

    if (vehicleTiles.length <= 0) {
      return;
    }

    const loadingOverlay = document.querySelector('.app-loader');
    loadingOverlay.classList.add('visible');

    const comparePage = document.querySelector('.compare-page');
    const compareLocalStorageData = JSON.parse(localStorage.getItem("compare")) || [];
    const sliderJquerySelector = $('.tile-container-main-slider'); // Slick slider can only be initialized with jQuery
    const stickySliderJquerySelector = $('.tile-container-sticky-slider'); // Slick slider can only be initialized with jQuery
    const sliderVanillaSelector = document.querySelector('.tile-container-main-slider');
    const stickySliderVanillaSelector = document.querySelector('.tile-container-sticky-slider');

    const removeButton = event.target;
    const thisSlide = removeButton.closest('.slick-slide');
    const thisSlideIndex = thisSlide.getAttribute('data-slick-index');
    const orderNumber = removeButton.getAttribute('data-orderno');

    const resultObject = searchArrayObjects(orderNumber, 'orderNumber', compareLocalStorageData);
    const index = compareLocalStorageData.indexOf(resultObject);

    sliderJquerySelector.slick('slickRemove', thisSlideIndex);
    stickySliderJquerySelector.slick('slickRemove', thisSlideIndex);

    const allSlides = sliderVanillaSelector.querySelectorAll('.slick-slide');
    const allStickySlides = stickySliderVanillaSelector.querySelectorAll('.slick-slide');

    allSlides.forEach((slide, index) => {
      slide.setAttribute('data-slick-index', index);
    });

    allStickySlides.forEach((slide, index) => {
      slide.setAttribute('data-slick-index', index);
    });

    const slideCount = allSlides.length;

    if (window.innerWidth < 768) {
      if (slideCount > 1) {
        sliderJquerySelector.slick('setPosition');
        stickySliderJquerySelector.slick('setPosition');
        sliderJquerySelector.slick('slickGoTo', 1);
        stickySliderJquerySelector.slick('slickGoTo', 1);
      }
    }

    comparePage.setAttribute('data-compare-count', slideCount);
    sliderVanillaSelector.classList.remove(`tile-container--${slideCount + 1}`);
    sliderVanillaSelector.classList.add(`tile-container--${slideCount}`);
    stickySliderVanillaSelector.classList.remove(`tile-container--${slideCount + 1}`);
    stickySliderVanillaSelector.classList.add(`tile-container--${slideCount}`);
    compareLocalStorageData.splice(index, 1);
    removeButton.classList.remove('in-comparison');

    const vehicleData = {
        vehicles: compareLocalStorageData
    };

    this.postComparisonData(vehicleData);
    this.updateCompareHeaderCount(compareLocalStorageData.length);
    window.localStorage.setItem('compare', JSON.stringify(compareLocalStorageData));
  };

  static checkAndUpdateEmptyState() {
    const compareLocalStorageData = JSON.parse(localStorage.getItem("compare")) || [];
    const numberOfVehicles = compareLocalStorageData.length;

    if (numberOfVehicles !== 0) {
      return;
    }

    const tileContainers = document.querySelectorAll('.tile-container');
    const compareCountText = document.querySelector('.compare-page__count');
    const compareEmptyText = document.querySelector('.compare-empty-text');

    tileContainers.forEach(container => {
      container.classList.add('hide');
    });

    compareCountText.classList.add('hide');
    compareEmptyText.classList.remove('hide');
  };
}

export default CompareVehicles;
