import bindAll from 'lodash/bindAll';

/**
 * @class Header
 * @description Simple header component to handle the basic functionality
 */

class Header {

  constructor() {
    this.init();
  }

  /**
   * @function init
   * @description Initialize the header buttons
   */
  init() {
    bindAll(this,
      'handleBackClick'
    );

    $('.back-link, .new-search').on('click', this.handleBackClick);
  }

  /**
   * @function handleBackClick
   * @description Handles the back button click event
   */
  handleBackClick(e) {
    localStorage.removeItem('showFilterContainer');
    const target = $(e.currentTarget),
    type = target.attr('data-type'),
    retailer = target.attr('data-retailer');
    if (type === 'search') {
      if (retailer) {
        window.location = `/retailer/${retailer}`;
      } else if (window.groupView || window.groupRetailerView){
        const groupSlug = window.location.pathname.split('/')[2];
        window.location = `/group/${groupSlug}`;
      } else {
        window.location = '/';
      }
    } else if (type === 'results'){
      // const previousPageUrl = document.referrer;
      // window.location = previousPageUrl;
      window.history.back();
    } else {
      window.history.back();
    }
  }
}

export default Header;
