var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <div class=\"vehicle-card"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"airstrip") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":26},"end":{"line":2,"column":118}}})) != null ? stack1 : "")
    + "\" data-order-no=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"order_number") || (depth0 != null ? lookupProperty(depth0,"order_number") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"order_number","hash":{},"data":data,"loc":{"start":{"line":2,"column":135},"end":{"line":2,"column":151}}}) : helper)))
    + "\" data-on-hold=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"on_hold") || (depth0 != null ? lookupProperty(depth0,"on_hold") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"on_hold","hash":{},"data":data,"loc":{"start":{"line":2,"column":167},"end":{"line":2,"column":178}}}) : helper)))
    + "\">\n    <div class=\"vehicle-card__inner\">\n      <div class=\"vehicle-card-upper\">\n        <div class=\"card-description-compare-container\">\n          <div class=\"card-description-container\">\n            <h2 class=\"card-range\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"range") || (depth0 != null ? lookupProperty(depth0,"range") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"range","hash":{},"data":data,"loc":{"start":{"line":7,"column":35},"end":{"line":7,"column":44}}}) : helper)))
    + "</h2>\n            <h3 class=\"card-description\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"description") || (depth0 != null ? lookupProperty(depth0,"description") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"description","hash":{},"data":data,"loc":{"start":{"line":8,"column":41},"end":{"line":8,"column":56}}}) : helper)))
    + "</h3>\n          </div>\n          <div class=\"compare-container\">\n            <span class=\"card-compare\"\n                  data-orderno=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"order_number") || (depth0 != null ? lookupProperty(depth0,"order_number") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"order_number","hash":{},"data":data,"loc":{"start":{"line":12,"column":32},"end":{"line":12,"column":48}}}) : helper)))
    + "\"\n                  data-vin=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"vin") || (depth0 != null ? lookupProperty(depth0,"vin") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"vin","hash":{},"data":data,"loc":{"start":{"line":13,"column":28},"end":{"line":13,"column":35}}}) : helper)))
    + "\"\n                  data-iv=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"iv") || (depth0 != null ? lookupProperty(depth0,"iv") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"iv","hash":{},"data":data,"loc":{"start":{"line":14,"column":27},"end":{"line":14,"column":33}}}) : helper)))
    + "\"\n                  data-dealernumber=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"dealer_id") || (depth0 != null ? lookupProperty(depth0,"dealer_id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"dealer_id","hash":{},"data":data,"loc":{"start":{"line":15,"column":37},"end":{"line":15,"column":50}}}) : helper)))
    + "\"\n                  data-name=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"description") || (depth0 != null ? lookupProperty(depth0,"description") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"description","hash":{},"data":data,"loc":{"start":{"line":16,"column":29},"end":{"line":16,"column":44}}}) : helper)))
    + "\">\n              <img src=\""
    + alias4(alias5(((stack1 = (depths[1] != null ? lookupProperty(depths[1],"img") : depths[1])) != null ? lookupProperty(stack1,"compare") : stack1), depth0))
    + "\" class=\"compare-icon\" alt=\"Compare Icon\" />\n              <span class=\"compare-label\">Compare</span>\n            </span>\n          </div>\n        </div>\n\n        <div class=\"card-budget\">\n          <div class=\"finance\"></div>\n          <span class=\"card-budget__text card-retailer-offer\" data-dealerprice=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"dealer_price") || (depth0 != null ? lookupProperty(depth0,"dealer_price") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"dealer_price","hash":{},"data":data,"loc":{"start":{"line":25,"column":80},"end":{"line":25,"column":96}}}) : helper)))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"airstrip") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.program(7, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":26,"column":12},"end":{"line":32,"column":19}}})) != null ? stack1 : "")
    + "\n            <img src=\""
    + alias4(alias5(((stack1 = (depths[1] != null ? lookupProperty(depths[1],"img") : depths[1])) != null ? lookupProperty(stack1,"info_primary") : stack1), depth0))
    + "\" class=\"info-icon\" alt=\"Information Icon\" />\n            <div class=\"tooltip-container js-tooltip\">\n              <div class=\"tip-content\">\n                <div class=\"js-tooltip-close tooltip-close\"></div>\n                <p class=\"tooltip-text\">This is the current offer on this vehicle from "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"group_view") : depths[1]),{"name":"if","hash":{},"fn":container.program(12, data, 0, blockParams, depths),"inverse":container.program(14, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":38,"column":87},"end":{"line":38,"column":155}}})) != null ? stack1 : "")
    + " and can be varied, withdrawn or extended at any time and is not a binding offer to enter into an agreement. This offer is also not transferrable to an alternate vehicle.</p>\n              </div>\n            </div>\n          </span>\n\n          <span class=\"card-budget__text card-otr\">\n            "
    + alias4(((helper = (helper = lookupProperty(helpers,"visible_cash_price") || (depth0 != null ? lookupProperty(depth0,"visible_cash_price") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"visible_cash_price","hash":{},"data":data,"loc":{"start":{"line":44,"column":12},"end":{"line":44,"column":34}}}) : helper)))
    + " on the road price <img src=\""
    + alias4(alias5(((stack1 = (depths[1] != null ? lookupProperty(depths[1],"img") : depths[1])) != null ? lookupProperty(stack1,"info_primary") : stack1), depth0))
    + "\" alt=\"Information Icon\" class=\"info-icon\" />\n            <div class=\"tooltip-container js-tooltip\">\n              <div class=\"tip-content\">\n                <div class=\"js-tooltip-close tooltip-close\"></div>\n                <p class=\"tooltip-text\">Includes 3-year "
    + alias4(alias5((depths[1] != null ? lookupProperty(depths[1],"brand") : depths[1]), depth0))
    + " Retailer Warranty, "
    + alias4(alias5((depths[1] != null ? lookupProperty(depths[1],"brand") : depths[1]), depth0))
    + " Emergency Service, 12-month road fund license, vehicle first registration fee, vehicle excise duty, delivery, number plates and VAT. It also includes any Manufacturer and Retailer discount.</p>\n              </div>\n            </div>\n          </span>\n          <span class=\"card-budget__text card-finance\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"monthly_finance_payment") || (depth0 != null ? lookupProperty(depth0,"monthly_finance_payment") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"monthly_finance_payment","hash":{},"data":data,"loc":{"start":{"line":52,"column":55},"end":{"line":52,"column":82}}}) : helper)))
    + " per month&ast;</span>\n        </div>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"group_view") : depths[1]),{"name":"unless","hash":{},"fn":container.program(16, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":54,"column":8},"end":{"line":77,"column":19}}})) != null ? stack1 : "")
    + "      </div>\n\n      <div class=\"slider\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"vehicle_images") : depth0)) != null ? lookupProperty(stack1,"exterior") : stack1),{"name":"each","hash":{},"fn":container.program(26, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":81,"column":8},"end":{"line":89,"column":17}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"vehicle_images") : depth0)) != null ? lookupProperty(stack1,"interior") : stack1),{"name":"each","hash":{},"fn":container.program(31, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":91,"column":8},"end":{"line":99,"column":17}}})) != null ? stack1 : "")
    + "      </div>\n\n      <div class=\"video-label-space-placeholder\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"eve_config_id") : depth0),{"name":"if","hash":{},"fn":container.program(34, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":103,"column":8},"end":{"line":113,"column":15}}})) != null ? stack1 : "")
    + "      </div>\n\n      <div id=\"js-video-"
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (data && lookupProperty(data,"index"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":116,"column":24},"end":{"line":116,"column":34}}}) : helper)))
    + "\" class=\"video-container modal-layer\">\n        <section class=\"simple-dialog\">\n          <header class=\"dialog-header\">\n            <button class=\"bt-close\" data-video-close=\"js-video-"
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (data && lookupProperty(data,"index"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":119,"column":64},"end":{"line":119,"column":74}}}) : helper)))
    + "\"><span class=\"icon\"></span><span class=\"label\">Close</span></button>\n          </header>\n          <div class=\"dialog-content\">\n            <div class=\"inner\">\n              <h2 class=\"video-container__heading\">Virtual 360&deg;</h2>\n              <h3 class=\"video-container__subheading\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"range") || (depth0 != null ? lookupProperty(depth0,"range") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"range","hash":{},"data":data,"loc":{"start":{"line":124,"column":54},"end":{"line":124,"column":63}}}) : helper)))
    + "</h3>\n\n              <button class=\"card-link bt-action view-details\" data-link=\"/vehicle/"
    + alias4(((helper = (helper = lookupProperty(helpers,"vin") || (depth0 != null ? lookupProperty(depth0,"vin") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"vin","hash":{},"data":data,"loc":{"start":{"line":126,"column":83},"end":{"line":126,"column":90}}}) : helper)))
    + "/"
    + alias4(((helper = (helper = lookupProperty(helpers,"iv") || (depth0 != null ? lookupProperty(depth0,"iv") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"iv","hash":{},"data":data,"loc":{"start":{"line":126,"column":91},"end":{"line":126,"column":97}}}) : helper)))
    + "?dn="
    + alias4(((helper = (helper = lookupProperty(helpers,"dealer_id") || (depth0 != null ? lookupProperty(depth0,"dealer_id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"dealer_id","hash":{},"data":data,"loc":{"start":{"line":126,"column":101},"end":{"line":126,"column":114}}}) : helper)))
    + "&f="
    + alias4(((helper = (helper = lookupProperty(helpers,"finance") || (depth0 != null ? lookupProperty(depth0,"finance") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"finance","hash":{},"data":data,"loc":{"start":{"line":126,"column":117},"end":{"line":126,"column":128}}}) : helper)))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"mini") : depths[1]),{"name":"unless","hash":{},"fn":container.program(36, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":127,"column":14},"end":{"line":129,"column":25}}})) != null ? stack1 : "")
    + "              <span class=\"label\">View details</span>\n              </button>\n\n              <div class=\"lds-spinner\">\n                <div></div>\n                <div></div>\n                <div></div>\n                <div></div>\n                <div></div>\n                <div></div>\n                <div></div>\n                <div></div>\n                <div></div>\n                <div></div>\n                <div></div>\n                <div></div>\n              </div>\n\n              <div class=\"video-container__error-message hide\">\n                <p>The virtual 360&deg; for this vehicle is currently unavailable.</p>\n              </div>\n\n              <div class=\"tabbed-content zero-opacity-layer\">\n                <div class=\"tab-list\" role=\"tablist\" aria-label=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"range") || (depth0 != null ? lookupProperty(depth0,"range") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"range","hash":{},"data":data,"loc":{"start":{"line":153,"column":65},"end":{"line":153,"column":74}}}) : helper)))
    + " Videos\">\n                  <button class=\"tab-list__tab tab-list__tab-exterior\" type=\"button\" role=\"tab\" aria-selected=\"true\" aria-controls=\"video-exterior-tab-"
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (data && lookupProperty(data,"index"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":154,"column":151},"end":{"line":154,"column":161}}}) : helper)))
    + "\" id=\"video-exterior-"
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (data && lookupProperty(data,"index"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":154,"column":182},"end":{"line":154,"column":192}}}) : helper)))
    + "\">Explore exterior</button>\n                  <button class=\"tab-list__tab tab-list__tab-interior\" type=\"button\" role=\"tab\" aria-selected=\"false\" aria-controls=\"video-interior-tab-"
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (data && lookupProperty(data,"index"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":155,"column":152},"end":{"line":155,"column":162}}}) : helper)))
    + "\" id=\"video-interior-"
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (data && lookupProperty(data,"index"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":155,"column":183},"end":{"line":155,"column":193}}}) : helper)))
    + "\" tabindex=\"-1\">Explore interior</button>\n                </div>\n\n                <div class=\"tab-panel tab-panel--exterior content-has-been-viewed\" role=\"tabpanel\" id=\"video-exterior-tab-"
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (data && lookupProperty(data,"index"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":158,"column":122},"end":{"line":158,"column":132}}}) : helper)))
    + "\" aria-labelledby=\"video-exterior-"
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (data && lookupProperty(data,"index"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":158,"column":166},"end":{"line":158,"column":176}}}) : helper)))
    + "\">\n                  <figure class=\"video-figure\">\n                    <video\n                      controls\n                      width=\"100%\"\n                      preload=\"metadata\"\n                      class=\"video\"\n                      controlsList=\"nodownload\"\n                      tabindex=\"0\"\n                      data-series=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"mini") : depths[1]),{"name":"if","hash":{},"fn":container.program(38, data, 0, blockParams, depths),"inverse":container.program(40, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":167,"column":35},"end":{"line":167,"column":89}}})) != null ? stack1 : "")
    + "\"\n                    >\n                      <source src=\"\">\n                      <p>Your browser doesn't support HTML5 video. Here is a <a href=\"\" class=\"video-fallback-link\">link to the video</a> instead.</p>\n                    </video>\n                  </figure>\n                </div>\n\n                <div class=\"tab-panel tab-panel--interior hide\" role=\"tabpanel\" id=\"video-interior-tab-"
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (data && lookupProperty(data,"index"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":175,"column":103},"end":{"line":175,"column":113}}}) : helper)))
    + "\" aria-labelledby=\"video-interior-"
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (data && lookupProperty(data,"index"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":175,"column":147},"end":{"line":175,"column":157}}}) : helper)))
    + "\">\n                  <figure class=\"video-figure\">\n                    <video\n                      controls\n                      width=\"100%\"\n                      preload=\"metadata\"\n                      class=\"video\"\n                      controlsList=\"nodownload\"\n                      tabindex=\"0\"\n                      data-series=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"mini") : depths[1]),{"name":"if","hash":{},"fn":container.program(38, data, 0, blockParams, depths),"inverse":container.program(40, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":184,"column":35},"end":{"line":184,"column":89}}})) != null ? stack1 : "")
    + "\"\n                    >\n                      <source src=\"\">\n                      <p>Your browser doesn't support HTML5 video. Here is a <a href=\"\" class=\"video-fallback-link\">link to the video</a> instead.</p>\n                    </video>\n                  </figure>\n                </div>\n              </div>\n            </div>\n          </div>\n        </section>\n      </div>\n\n      <div class=\"availability-and-high-spec-container"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"valid_delivery_time_frame") : depth0),{"name":"unless","hash":{},"fn":container.program(42, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":197,"column":54},"end":{"line":197,"column":153}}})) != null ? stack1 : "")
    + "\">\n        <div class=\"availability"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"tooltip_options_present") : depth0),{"name":"if","hash":{},"fn":container.program(44, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":198,"column":32},"end":{"line":198,"column":91}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"valid_delivery_time_frame") : depth0),{"name":"unless","hash":{},"fn":container.program(46, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":198,"column":91},"end":{"line":198,"column":166}}})) != null ? stack1 : "")
    + "\">\n          <span class=\"availability__label\">Typical availability:\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"valid_delivery_time_frame") : depth0),{"name":"if","hash":{},"fn":container.program(48, data, 0, blockParams, depths),"inverse":container.program(50, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":200,"column":16},"end":{"line":204,"column":23}}})) != null ? stack1 : "")
    + "          </span>\n          <img src=\""
    + alias4(alias5(((stack1 = (depths[1] != null ? lookupProperty(depths[1],"img") : depths[1])) != null ? lookupProperty(stack1,"info_primary") : stack1), depth0))
    + "\" alt=\"Information Icon\" class=\"info-icon js-tooltip-icon tooltip-basic-icon\" data-tooltip=\"availability-tooltip-"
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (data && lookupProperty(data,"index"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":206,"column":156},"end":{"line":206,"column":166}}}) : helper)))
    + "\"/>\n          <div class=\"tooltip-container tooltip-basic js-tooltip-container availability-tooltip-"
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (data && lookupProperty(data,"index"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":207,"column":96},"end":{"line":207,"column":106}}}) : helper)))
    + "\">\n            <div class=\"tip-content\">\n              <span class=\"js-tooltip-close tooltip-close\"></span>\n              <p class=\"tooltip-text\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"valid_delivery_time_frame") : depth0),{"name":"if","hash":{},"fn":container.program(52, data, 0, blockParams, depths),"inverse":container.program(54, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":211,"column":16},"end":{"line":216,"column":23}}})) != null ? stack1 : "")
    + "              </p>\n            </div>\n          </div>\n        </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"tooltip_options_present") : depth0),{"name":"if","hash":{},"fn":container.program(56, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":222,"column":8},"end":{"line":304,"column":15}}})) != null ? stack1 : "")
    + "      </div>\n\n      <div class=\"card-info\">\n        <div class=\"key-info\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"keyinfo") : depth0),{"name":"each","hash":{},"fn":container.program(79, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":309,"column":10},"end":{"line":313,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n\n        <button class=\"card-link btn primary view-details\" data-link=\"/vehicle/"
    + alias4(((helper = (helper = lookupProperty(helpers,"vin") || (depth0 != null ? lookupProperty(depth0,"vin") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"vin","hash":{},"data":data,"loc":{"start":{"line":316,"column":79},"end":{"line":316,"column":86}}}) : helper)))
    + "/"
    + alias4(((helper = (helper = lookupProperty(helpers,"iv") || (depth0 != null ? lookupProperty(depth0,"iv") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"iv","hash":{},"data":data,"loc":{"start":{"line":316,"column":87},"end":{"line":316,"column":93}}}) : helper)))
    + "?dn="
    + alias4(((helper = (helper = lookupProperty(helpers,"dealer_id") || (depth0 != null ? lookupProperty(depth0,"dealer_id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"dealer_id","hash":{},"data":data,"loc":{"start":{"line":316,"column":97},"end":{"line":316,"column":110}}}) : helper)))
    + "&f="
    + alias4(((helper = (helper = lookupProperty(helpers,"finance") || (depth0 != null ? lookupProperty(depth0,"finance") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"finance","hash":{},"data":data,"loc":{"start":{"line":316,"column":113},"end":{"line":316,"column":124}}}) : helper)))
    + "&spv="
    + alias4(((helper = (helper = lookupProperty(helpers,"vehicle_ids") || (depth0 != null ? lookupProperty(depth0,"vehicle_ids") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"vehicle_ids","hash":{},"data":data,"loc":{"start":{"line":316,"column":129},"end":{"line":316,"column":144}}}) : helper)))
    + "\">View details</button>\n      </div>\n    </div>\n  </div>\n";
},"2":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depths[1] != null ? lookupProperty(depths[1],"airstrip_group_view") : depths[1]),{"name":"unless","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":43},"end":{"line":2,"column":111}}})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    return " vehicle-card--airstrip";
},"5":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              "
    + container.escapeExpression(container.lambda((depths[1] != null ? lookupProperty(depths[1],"brand") : depths[1]), depth0))
    + " UK Group Offer\n";
},"7":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depths[1] != null ? lookupProperty(depths[1],"group_view") : depths[1]),{"name":"if","hash":{},"fn":container.program(8, data, 0, blockParams, depths),"inverse":container.program(10, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":28,"column":12},"end":{"line":32,"column":12}}})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    return "              Retailer group offer\n";
},"10":function(container,depth0,helpers,partials,data) {
    return "              Retailer offer\n            ";
},"12":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda((depths[1] != null ? lookupProperty(depths[1],"group_name") : depths[1]), depth0));
},"14":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(((helper = (helper = lookupProperty(helpers,"dealer_name") || (depth0 != null ? lookupProperty(depth0,"dealer_name") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"dealer_name","hash":{},"data":data,"loc":{"start":{"line":38,"column":133},"end":{"line":38,"column":148}}}) : helper)));
},"16":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <div class=\"card-location"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"airstrip") : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":55,"column":35},"end":{"line":55,"column":70}}})) != null ? stack1 : "")
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"airstrip") : depth0),{"name":"unless","hash":{},"fn":container.program(19, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":56,"column":12},"end":{"line":65,"column":23}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"airstrip") : depth0),{"name":"if","hash":{},"fn":container.program(24, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":67,"column":12},"end":{"line":75,"column":19}}})) != null ? stack1 : "")
    + "          </div>\n";
},"17":function(container,depth0,helpers,partials,data) {
    return " group-stock";
},"19":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"dealer_distance") : depth0),{"name":"if","hash":{"includeZero":true},"fn":container.program(20, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":57,"column":14},"end":{"line":63,"column":21}}})) != null ? stack1 : "")
    + "              ("
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"dealer_name") || (depth0 != null ? lookupProperty(depth0,"dealer_name") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"dealer_name","hash":{},"data":data,"loc":{"start":{"line":64,"column":15},"end":{"line":64,"column":30}}}) : helper)))
    + ")\n";
},"20":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depths[1] != null ? lookupProperty(depths[1],"dealer_view") : depths[1]),{"name":"unless","hash":{},"fn":container.program(21, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":58,"column":16},"end":{"line":62,"column":27}}})) != null ? stack1 : "");
},"21":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depths[1] != null ? lookupProperty(depths[1],"group_retailer_view") : depths[1]),{"name":"unless","hash":{},"fn":container.program(22, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":59,"column":18},"end":{"line":61,"column":29}}})) != null ? stack1 : "");
},"22":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <img src=\""
    + alias1(container.lambda(((stack1 = (depths[1] != null ? lookupProperty(depths[1],"img") : depths[1])) != null ? lookupProperty(stack1,"pin") : stack1), depth0))
    + "\" alt=\"Location Icon\" class=\"location-pin-icon\" /> "
    + alias1(((helper = (helper = lookupProperty(helpers,"dealer_distance") || (depth0 != null ? lookupProperty(depth0,"dealer_distance") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"dealer_distance","hash":{},"data":data,"loc":{"start":{"line":60,"column":95},"end":{"line":60,"column":114}}}) : helper)))
    + " miles away\n";
},"24":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              <span class=\"group-stock\">"
    + alias2(alias1((depths[1] != null ? lookupProperty(depths[1],"brand") : depths[1]), depth0))
    + " Group Stock</span> <img src=\""
    + alias2(alias1(((stack1 = (depths[1] != null ? lookupProperty(depths[1],"img") : depths[1])) != null ? lookupProperty(stack1,"info_primary") : stack1), depth0))
    + "\" alt=\"Information Icon\" class=\"info-icon\" />\n              <div class=\"tooltip-container js-tooltip\">\n                <div class=\"tip-content\">\n                  <div class=\"js-tooltip-close tooltip-close\"></div>\n                  <p class=\"tooltip-text\">Available through your selected retailer.</p>\n                </div>\n              </div>\n";
},"26":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <div class=\"vehicle-image-container\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,{"name":"if","hash":{},"fn":container.program(27, data, 0),"inverse":container.program(29, data, 0),"data":data,"loc":{"start":{"line":83,"column":12},"end":{"line":87,"column":19}}})) != null ? stack1 : "")
    + "          </div>\n";
},"27":function(container,depth0,helpers,partials,data) {
    var helper, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              <div class=\"vehicle-image vehicle-image--"
    + alias1(((helper = (helper = lookupProperty(helpers,"index") || (data && lookupProperty(data,"index"))) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"index","hash":{},"data":data,"loc":{"start":{"line":84,"column":55},"end":{"line":84,"column":65}}}) : helper)))
    + " js-lazy-load\" data-src=\""
    + alias1(container.lambda(depth0, depth0))
    + "\"></div>\n";
},"29":function(container,depth0,helpers,partials,data) {
    return "              <div class=\"vehicle-image vehicle-image--fallback\" style=\"background-image: url('no-image.png'); background-size: cover;\"></div>\n";
},"31":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <div class=\"vehicle-image-container\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,{"name":"if","hash":{},"fn":container.program(32, data, 0),"inverse":container.program(29, data, 0),"data":data,"loc":{"start":{"line":93,"column":12},"end":{"line":97,"column":19}}})) != null ? stack1 : "")
    + "          </div>\n";
},"32":function(container,depth0,helpers,partials,data) {
    return "              <div class=\"vehicle-image vehicle-image--interior js-lazy-load\" data-src=\""
    + container.escapeExpression(container.lambda(depth0, depth0))
    + "\"></div>\n";
},"34":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <div class=\"video-label-container\" data-video-id=\"js-video-"
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (data && lookupProperty(data,"index"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":104,"column":69},"end":{"line":104,"column":79}}}) : helper)))
    + "\" data-eve-config-id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"eve_config_id") || (depth0 != null ? lookupProperty(depth0,"eve_config_id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"eve_config_id","hash":{},"data":data,"loc":{"start":{"line":104,"column":101},"end":{"line":104,"column":118}}}) : helper)))
    + "\">\n            <span class=\"icon\">\n              <span class=\"icon__square\"></span>\n              <span class=\"icon__square\"></span>\n              <span class=\"icon__square\"></span>\n              <span class=\"icon__square\"></span>\n            </span>\n            <span class=\"label\">View the virtual 360&deg;</span>\n          </div>\n";
},"36":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <img src=\""
    + container.escapeExpression(container.lambda(((stack1 = (depths[1] != null ? lookupProperty(depths[1],"img") : depths[1])) != null ? lookupProperty(stack1,"arrow") : stack1), depth0))
    + "\" class=\"icon-arrow-right arrow-right\" alt=\"Arrow Right Icon\">\n";
},"38":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(((helper = (helper = lookupProperty(helpers,"description") || (depth0 != null ? lookupProperty(depth0,"description") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"description","hash":{},"data":data,"loc":{"start":{"line":167,"column":50},"end":{"line":167,"column":65}}}) : helper)));
},"40":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(((helper = (helper = lookupProperty(helpers,"range") || (depth0 != null ? lookupProperty(depth0,"range") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"range","hash":{},"data":data,"loc":{"start":{"line":167,"column":73},"end":{"line":167,"column":82}}}) : helper)));
},"42":function(container,depth0,helpers,partials,data) {
    return " availability-and-high-spec-container--with-no-date";
},"44":function(container,depth0,helpers,partials,data) {
    return " availability--narrow";
},"46":function(container,depth0,helpers,partials,data) {
    return " availability--with-no-date";
},"48":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                  "
    + alias4(((helper = (helper = lookupProperty(helpers,"lead_time_in_weeks_min") || (depth0 != null ? lookupProperty(depth0,"lead_time_in_weeks_min") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"lead_time_in_weeks_min","hash":{},"data":data,"loc":{"start":{"line":201,"column":18},"end":{"line":201,"column":44}}}) : helper)))
    + "-"
    + alias4(((helper = (helper = lookupProperty(helpers,"lead_time_in_weeks_max") || (depth0 != null ? lookupProperty(depth0,"lead_time_in_weeks_max") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"lead_time_in_weeks_max","hash":{},"data":data,"loc":{"start":{"line":201,"column":45},"end":{"line":201,"column":71}}}) : helper)))
    + " weeks\n";
},"50":function(container,depth0,helpers,partials,data) {
    return "              Contact Retailer\n";
},"52":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                  This vehicle should be available for you to drive away within "
    + alias4(((helper = (helper = lookupProperty(helpers,"lead_time_in_weeks_min") || (depth0 != null ? lookupProperty(depth0,"lead_time_in_weeks_min") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"lead_time_in_weeks_min","hash":{},"data":data,"loc":{"start":{"line":212,"column":80},"end":{"line":212,"column":106}}}) : helper)))
    + " to "
    + alias4(((helper = (helper = lookupProperty(helpers,"lead_time_in_weeks_max") || (depth0 != null ? lookupProperty(depth0,"lead_time_in_weeks_max") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"lead_time_in_weeks_max","hash":{},"data":data,"loc":{"start":{"line":212,"column":110},"end":{"line":212,"column":136}}}) : helper)))
    + " weeks.\n                  However exact handover timings will be confirmed by your retailer during the sales process.\n";
},"54":function(container,depth0,helpers,partials,data) {
    return "                  Exact handover timings will be confirmed by your retailer during the sales process.\n";
},"56":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <div class=\"high-spec\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"high_specification") : depth0),{"name":"if","hash":{},"fn":container.program(57, data, 0, blockParams, depths),"inverse":container.program(59, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":224,"column":12},"end":{"line":234,"column":19}}})) != null ? stack1 : "")
    + "            <img src=\""
    + alias2(container.lambda(((stack1 = (depths[1] != null ? lookupProperty(depths[1],"img") : depths[1])) != null ? lookupProperty(stack1,"info_primary") : stack1), depth0))
    + "\" alt=\"Information Icon\" class=\"info-icon js-tooltip-icon tooltip-basic-icon\" data-tooltip=\"high-spec-tooltip-"
    + alias2(((helper = (helper = lookupProperty(helpers,"index") || (data && lookupProperty(data,"index"))) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":235,"column":155},"end":{"line":235,"column":165}}}) : helper)))
    + "\"/>\n\n            <div class=\"tooltip-container tooltip-basic js-tooltip-container high-spec-tooltip-"
    + alias2(((helper = (helper = lookupProperty(helpers,"index") || (data && lookupProperty(data,"index"))) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":237,"column":95},"end":{"line":237,"column":105}}}) : helper)))
    + "\">\n              <div class=\"tip-content\">\n                <span class=\"js-tooltip-close tooltip-close\"></span>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"high_specification") : depth0),{"name":"if","hash":{},"fn":container.program(64, data, 0, blockParams, depths),"inverse":container.program(73, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":240,"column":16},"end":{"line":300,"column":23}}})) != null ? stack1 : "")
    + "              </div>\n            </div>\n          </div>\n";
},"57":function(container,depth0,helpers,partials,data) {
    return "              <span class=\"high-spec__label\">High spec</span>\n";
},"59":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              <span class=\"high-spec__label\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depths[1] != null ? lookupProperty(depths[1],"mini") : depths[1]),{"name":"if","hash":{},"fn":container.program(60, data, 0, blockParams, depths),"inverse":container.program(62, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":228,"column":16},"end":{"line":232,"column":29}}})) != null ? stack1 : "")
    + "              </span>\n";
},"60":function(container,depth0,helpers,partials,data) {
    return "                    Packs\n";
},"62":function(container,depth0,helpers,partials,data) {
    return "                    Packages\n";
},"64":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                  <!-- non-standard options -->\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"high_specification_options_present") : depth0),{"name":"if","hash":{},"fn":container.program(65, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":242,"column":18},"end":{"line":254,"column":25}}})) != null ? stack1 : "")
    + "\n                  <!-- standard packages -->\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"standard_packages") : depth0)) != null ? lookupProperty(stack1,"length") : stack1),{"name":"if","hash":{},"fn":container.program(68, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":257,"column":18},"end":{"line":269,"column":25}}})) != null ? stack1 : "");
},"65":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <span class=\"tooltip-title\">Non-standard options:</span>\n\n                    <ul>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"non_standard_options") : depth0),{"name":"each","hash":{},"fn":container.program(66, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":246,"column":22},"end":{"line":248,"column":31}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"non_standard_packages") : depth0),{"name":"each","hash":{},"fn":container.program(66, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":250,"column":22},"end":{"line":252,"column":31}}})) != null ? stack1 : "")
    + "                    </ul>\n";
},"66":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <li>"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"title","hash":{},"data":data,"loc":{"start":{"line":247,"column":28},"end":{"line":247,"column":37}}}) : helper)))
    + "</li>\n";
},"68":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"mini") : depths[1]),{"name":"if","hash":{},"fn":container.program(69, data, 0, blockParams, depths),"inverse":container.program(71, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":258,"column":20},"end":{"line":262,"column":27}}})) != null ? stack1 : "")
    + "\n                    <ul>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"standard_packages") : depth0),{"name":"each","hash":{},"fn":container.program(66, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":265,"column":22},"end":{"line":267,"column":31}}})) != null ? stack1 : "")
    + "                    </ul>\n";
},"69":function(container,depth0,helpers,partials,data) {
    return "                      <span class=\"tooltip-title\">Standard Packs:</span>\n";
},"71":function(container,depth0,helpers,partials,data) {
    return "                      <span class=\"tooltip-title\">Standard Packages:</span>\n";
},"73":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                  <!-- non-standard packages -->\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"non_standard_packages") : depth0)) != null ? lookupProperty(stack1,"length") : stack1),{"name":"if","hash":{},"fn":container.program(74, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":272,"column":18},"end":{"line":284,"column":25}}})) != null ? stack1 : "")
    + "\n                  <!-- standard packages -->\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"standard_packages") : depth0)) != null ? lookupProperty(stack1,"length") : stack1),{"name":"if","hash":{},"fn":container.program(68, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":287,"column":18},"end":{"line":299,"column":25}}})) != null ? stack1 : "");
},"74":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"mini") : depths[1]),{"name":"if","hash":{},"fn":container.program(75, data, 0, blockParams, depths),"inverse":container.program(77, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":273,"column":20},"end":{"line":277,"column":27}}})) != null ? stack1 : "")
    + "\n                    <ul>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"non_standard_packages") : depth0),{"name":"each","hash":{},"fn":container.program(66, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":280,"column":22},"end":{"line":282,"column":31}}})) != null ? stack1 : "")
    + "                    </ul>\n";
},"75":function(container,depth0,helpers,partials,data) {
    return "                      <span class=\"tooltip-title\">Non-standard Packs:</span>\n";
},"77":function(container,depth0,helpers,partials,data) {
    return "                      <span class=\"tooltip-title\">Non-standard Packages:</span>\n";
},"79":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"key-info-item\">\n              <img class=\"key-info__icon\" src=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"icon") || (depth0 != null ? lookupProperty(depth0,"icon") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"icon","hash":{},"data":data,"loc":{"start":{"line":311,"column":47},"end":{"line":311,"column":55}}}) : helper)))
    + "\" alt=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"alt") || (depth0 != null ? lookupProperty(depth0,"alt") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"alt","hash":{},"data":data,"loc":{"start":{"line":311,"column":62},"end":{"line":311,"column":69}}}) : helper)))
    + "\"/><span class=\"key-info__title\">"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":311,"column":102},"end":{"line":311,"column":113}}}) : helper))) != null ? stack1 : "")
    + "</span>\n            </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"vehicles") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":320,"column":9}}})) != null ? stack1 : "");
},"useData":true,"useDepths":true});