import bindAll from 'lodash/bindAll';
import defaults from 'lodash/defaults';
import { isMobile, isTablet, isDesktop} from "./../../utils/viewport-check";
import { areSearchTagsApplied } from "./../../utils/are-search-tags-applied";
import { changeButtonState } from "./../../utils/change-button-state";


/**
 * @class SearchSelects
 * @description
 * Initialising search drop-down selects for:
 * - Location: Distance
 * - Location: Dealers
 * - Budget: Monthly Min/Max
 * - Budget: OTR Min/Max
 */

 const selectors = {
  locationDistance: '.location-display .distance .js-select-menu',
  locationDealer: '.location-display .retailer .js-select-menu',
  budgetSelectMenu: '.budget-display .js-select-menu',
  searchButton: '.search-button',
  similarResultsSidebar: '.similar-results .sidebar'
};

class SearchSelects {
  constructor(options = {}) {
    this.options = defaults({}, options, selectors);

    this.init();
  }

  init() {
    bindAll(this,
      'initDistanceSelects',
      'initDealerSelects',
      'initBudgetSelects'
    );

    this.searchButtonElement = document.querySelector(this.options.searchButton);
    this.similarResultsSidebar = document.querySelector(this.options.similarResultsSidebar);

    this.initDistanceSelects();
    this.initDealerSelects();
    this.initBudgetSelects();

  }

  initDistanceSelects() {
    const distanceSelectMenusVanilla = document.querySelector(this.options.locationDistance);
    const distanceSelectMenus = $(this.options.locationDistance);

    const distanceMinimumValue = parseInt(distanceSelectMenusVanilla.dataset.selectMinValue);
    const distanceMaximumValue = parseInt(distanceSelectMenusVanilla.dataset.selectMaxValue);
    const distanceIncrementValue = parseInt(distanceSelectMenusVanilla.dataset.selectIncrements);

    for (let selectValue = distanceMinimumValue; selectValue <= distanceMaximumValue; selectValue += distanceIncrementValue) {
      const optionHtml = `
        <option value="${selectValue}">Within ${selectValue} miles</option>
      `;

      distanceSelectMenus.append(optionHtml);
    }

    distanceSelectMenus.selectmenu({
      change: (event, data) => {
        const siblingSpanElement = event.target.nextElementSibling;
        const label = data.item.label;
        const defaultLabel = data.item.element[0].dataset.labelText;

        if (label === defaultLabel) {
          siblingSpanElement.classList.remove('is-active');
        } else {
          siblingSpanElement.classList.add('is-active');
        }
      },
      open: (event) => {
        const element = event.currentTarget;

        if (isTablet()) {
          element.scrollIntoView({
            behavior: 'smooth'
          });
        }

        if (this.similarResultsSidebar === null) return;

        this.similarResultsSidebar.style.paddingBottom = '16rem';
      },
      close: () => {
        if (this.similarResultsSidebar === null) return;

        this.similarResultsSidebar.style.paddingBottom = '0';
      }
    });
  };

  initDealerSelects() {
    $(this.options.locationDealer).selectmenu({
      change: (event, data) => {
        const validOption = data.item.value !== "";
        if (validOption) {
          this.searchButtonElement.classList.add('is-valid-search');

          if (window.similar_search) {
            changeButtonState(this.searchButtonElement, 'enable');
          } else if (areSearchTagsApplied()) {
            changeButtonState(this.searchButtonElement, 'enable');
          }
        } else {
          this.searchButtonElement.classList.remove('is-valid-search');
          changeButtonState(this.searchButtonElement, 'disable');
        }

        if (window.distance_toggle) {
          if (data.item.value) {
            this.searchButtonElement.classList.add('is-valid-search');

            if (areSearchTagsApplied()) {
              changeButtonState(this.searchButtonElement, 'enable');
            }
          } else {
            this.searchButtonElement.classList.remove('is-valid-search');
            changeButtonState(this.searchButtonElement, 'disable');
          }
        }
      },
      open: (event) => {
        const element = event.currentTarget;

        if (isTablet()) {
          element.scrollIntoView({
            behavior: 'smooth'
          });
        }

        if (this.similarResultsSidebar === null) return;

        this.similarResultsSidebar.style.paddingBottom = '16rem';
      },
      close: () => {
        if (this.similarResultsSidebar === null) return;

        this.similarResultsSidebar.style.paddingBottom = '0';
      }
    });
  }

  initBudgetSelects() {
    // jQuery used here as the select menu UI widget is used across the site for select dropdowns
    // TODO - Look into swapping this out for a vanilla JS alternative
    // Out of scope for this ticket (NCLBM-1148)
    const budgetSelectMenus = $(this.options.budgetSelectMenu);

    budgetSelectMenus.each((index, element) => {
      const elementParent = $(element).closest('.js-select-menu-parent');
      const budgetSelectMenuMin = elementParent.find('.js-select-min');
      const budgetSelectMenuMax = elementParent.find('.js-select-max');

      let minValue = element.dataset.selectMinValue;
      let maxValue = element.dataset.selectMaxValue;
      let incrementValue = element.dataset.selectIncrements;

      const currencyFormatter = new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: 'GBP',
        maximumFractionDigits: 0
      });

      minValue = parseInt(minValue);
      maxValue = parseInt(maxValue);
      incrementValue = parseInt(incrementValue);

      for (let selectValue = minValue; selectValue <= maxValue; selectValue += incrementValue) {
        const selectOptionText = selectValue === maxValue ? `${currencyFormatter.format(selectValue)}+` : `${currencyFormatter.format(selectValue)}`;
        const optionHtml = `
          <option value="${selectValue}">${selectOptionText}</option>
        `;

        $(element).append(optionHtml);
      }

      $(element).selectmenu({
        classes: {
          "ui-selectmenu-menu": "ui-selectmenu-budget"
        },
        change: (event, data) => {
          const selectedDropdown = event.target;
          const selectedLabel = data.item.label;
          const defaultLabel = data.item.element[0].dataset.labelText;

          if (selectedDropdown.classList.contains('js-select-min')) {
            const selectedValue = parseInt(data.item.value);
            const selectDropdownMaximum = selectedDropdown.parentNode.querySelector('.js-select-max');
            const selectDropdownMaximumOptions = selectDropdownMaximum.querySelectorAll('option');
            const maxValue = selectDropdownMaximum.dataset.selectMaxValue;

            selectDropdownMaximumOptions.forEach(option => {
              let optionValue = parseInt(option.value);

              if (optionValue !== maxValue) {
                if (optionValue <= selectedValue) {
                  option.setAttribute('disabled', 'true');
                } else {
                  option.removeAttribute('disabled');
                }
              }
            });

            const maxSelected = selectDropdownMaximum.value;
            const selectDropdownMaximumSpanElement = selectDropdownMaximum.nextElementSibling;

            if (maxSelected <= selectedValue) {
              selectDropdownMaximum.value = maxValue;
              selectDropdownMaximumSpanElement.classList.remove('is-active');
            }

            budgetSelectMenuMin.selectmenu('refresh');
            budgetSelectMenuMax.selectmenu('refresh');
          }

          const siblingSpanElement = event.target.nextElementSibling;

          if (selectedLabel === defaultLabel) {
            siblingSpanElement.classList.remove('is-active');
          } else {
            siblingSpanElement.classList.add('is-active');
          }
        },
        open: (event) => {
          const element = event.currentTarget;

          if (isTablet()) {
            element.scrollIntoView({
              behavior: 'smooth'
            });
          }
        }
      });
    });
  }
}

export default SearchSelects;
