import { extend } from './../../utils/extend';

class Countdown {

  constructor(settings) {
    const defaults = {
      end: new Date('2023-11-24T17:00:00'),
      target: document.querySelector('.countdown'),
      onChange: () => {
        // console.log('change', this);
      },
      onStart: () => {
        // console.log('start', this);
      },
      onStop: () => {
        // console.log('stop', this);
      }
    };

    const options = extend(defaults, settings);
    if (options.target === null) return false;

    this.end = options.end;
    this.target = options.target;
    this.onChange = options.onChange;
    this.onStop = options.onStop;
    this.onStart = options.onStart;
    this.days = this.target.querySelector('[data-timer-value="d"]');
    this.hours = this.target.querySelector('[data-timer-value="h"]');
    this.minutes = this.target.querySelector('[data-timer-value="m"]');
    this.frame = 0;
    this.state = 'counting';
    this.onStart(this);
    this.run();
  }

  run() {

    this.frame = requestAnimationFrame(() => {

      const now = new Date();
      const time = (this.end.getTime() - now.getTime());

      if (time < 0) {
        this.days.innerHTML = this.toUnit(0, 'd');
        this.hours.innerHTML = this.toUnit(0, 'h');
        this.minutes.innerHTML = this.toUnit(0, 'm');
        this.state = 'stopped';
        this.onChange(this);
        this.onStop(this);
        return;
      };

      this.days.innerHTML = this.toUnit(time, 'd');
      this.hours.innerHTML = this.toUnit(time, 'h');
      this.minutes.innerHTML = this.toUnit(time, 'm');
      this.onChange(this);
      this.run();

    });

  }

  getExample(time) {
    return `${this.toUnit(time, 'd')} ${this.toUnit(time, 'h')} ${this.toUnit(time, 'm')}`;
  }

  toUnit(time, unit) {

    const second = 1000;
    const minute = (second * 60);
    const hour = (minute * 60);
    const day = (hour * 24);
    let raw = 0;

    switch(unit) {
      case 'd':
        raw = (time / day);
      break;
      case 'h':
        raw = (time % day) / hour;
      break;
      case 'm':
        raw = (time % hour) / minute;
      break;
    };

    raw = Math.floor(raw);

    if(raw<10) {
      raw = ('0' + raw);
    };

    return raw;

  }

}

export default Countdown;
