import defaults from 'lodash/defaults';
import bindAll from 'lodash/bindAll';
import isEmpty from 'lodash/isEmpty';
import reduce from 'lodash/reduce';

const settings = {
  mobileDoneBtn: '.filter-actions-mobile .btn',
  mobileRefineHeader: '.main-filters .modify-filter-header',
  btnProceed: '.modify-filter-confirmation .bt-proceed',
  btnCancel: '.modify-filter-confirmation .bt-cancel',
  additionalFilter: '.additional-filters .modify-filter-header',
}

class ResultsFilter {
  constructor(options = {}) {
    this.options = defaults({}, options, settings);

    this.getAdditionalFilterData = options.getAdditionalFilterData;
    this.handleRefineSearch = options.handleRefineSearch;
    this.querySearchParams = options.querySearchParams;
    this.bindAll()
    this.init()
  }

  bindAll() {
    bindAll(this,
      'additionalFilterUpdated',
      'setAdditionalFilters',
      'handleMobileRefinedHeaderClick',
      'handleMobileDoneBtnClick',
      'openRefineSearchFilter',
      'closeRefineSearchFilter',
      'openAdditionalFilters',
      'closeAdditionalFilters',
      'closeClearFilterConfirmation',
      'openClearFilterConfirmation',
      'handleCancelClick',
      'handleProceedClick',
      'handleAdditionalHeaderClick',
      'close',
      'persistTargetFilter'
    )
  }

  init() {
    $(this.options.mobileDoneBtn).on('click', this.handleMobileDoneBtnClick);
    $(this.options.mobileRefineHeader).on('click', this.handleMobileRefinedHeaderClick);
    $(this.options.btnProceed).on('click', this.handleProceedClick);
    $(this.options.btnCancel).on('click', this.handleCancelClick);
    $(this.options.additionalFilter).on('click', this.handleAdditionalHeaderClick);

    this.setLoadedFilter();
    this.setLoadedQuerySearchParams();
    this.openOnLoad();
  }

  handleMobileDoneBtnClick(e) {
    if (this.isQuerySearchParamsDirty) {
      this.handleRefineSearch(e);
    } else if (this.isAdditionalFilterDirty) {
      this.getAdditionalFilterData(this.close);
    } else {
      this.close()
    }
  }

  get isAdditionalFilterDirty() {
    return this.loadedFilter != this.rawAdditionalFilters;
  }

  get isQuerySearchParamsDirty() {
    return this.loadedQuerySearchParams != this.stringifyQuerySearchParams;
  }

  additionalFilterUpdated(filters) {
    if (!this.isMobile) {
      this.getAdditionalFilterData();
    } else {
      this.setAdditionalFilters({ filters: filters, loaded: false })
    }
  }

  setLoadedFilter() {
    this.loadedFilter = this.rawAdditionalFilters;
  }

  get stringifyQuerySearchParams() {
    return JSON.stringify(this.querySearchParams)
  }

  setLoadedQuerySearchParams() {
    this.loadedQuerySearchParams = this.stringifyQuerySearchParams;
  }

  get additionalFilters() {
    return JSON.parse(window.localStorage.getItem('additional_filters'))
  }

  get rawAdditionalFilters() {
    return window.localStorage.getItem('additional_filters')
  }

  setAdditionalFilters(options = {}) {
    let { filters = false, loaded = true }  =  options;

    filters && window.localStorage.setItem('additional_filters', JSON.stringify(filters));
    loaded && this.setLoadedFilter();
    this.persistTargetFilter();
  }

  get showFilterOnLoad() {
    return localStorage.getItem('showFilterContainer');
  }


  get isMobile() {
    return window.innerWidth < 1024;
  }

  get isAdditionalFiltersOpen() {
    return !isEmpty($('.additional-filters.is-open'));
  }

  open() {
    $('body').addClass('filters-open');
  }

  close() {
    $('body').removeClass('filters-open');
  }

  openOnLoad() {
    this.showFilterContainer && this.open()
    this.clearShowOnLoad()
  }

  setShowOnLoad() {
    localStorage.setItem('showFilterContainer', true);
  }

  clearShowOnLoad() {
    localStorage.removeItem('showFilterContainer');
  }

  get showFilterContainer() {
    return localStorage.getItem('showFilterContainer');
  }

  get targetFilter() {
    return JSON.parse(this.rawTargetFilter)
  }

  get rawTargetFilter() {
    return localStorage.getItem('targetFilter');
  }

  get isAdditionalFilterPresent() {
    return reduce(this.additionalFilters, (isPresent, value) => {
      return (isPresent || !isEmpty(value));
    }, false)
  }



  openClearFilterConfirmation() {
    $('.modify-filter-confirmation').addClass('visible');
    $('.filter-actions-mobile').css('display', 'none');
  }

  openRefineSearchFilter() {
    $('.main-filters').addClass('is-open');
    $('.main-filter-accordion').css('display', 'block');
  }

  closeRefineSearchFilter() {
    $('.main-filter-accordion').css('display', 'none');
  }

  openAdditionalFilters() {
    $('.additional-filters').addClass('is-open');
    $('.additional-filter-accordion').css('display', 'block');
  }

  closeAdditionalFilters() {
    $('.additional-filters').removeClass('is-open');
    $('.additional-filter-accordion').css('display', 'none');
  }

  closeClearFilterConfirmation() {
    $('.modify-filter-confirmation').removeClass('visible');
    $('.filter-actions-mobile').css('display', 'block');
  }

  handleMobileRefinedHeaderClick(e) {
    if (this.isAdditionalFilterPresent) {
      this.openClearFilterConfirmation();
    }
    else {
      this.openRefineSearchFilter()
      this.closeAdditionalFilters()
    }
  }

  handleProceedClick(e) {
    this.closeAdditionalFilters();
    this.closeClearFilterConfirmation()
    this.openRefineSearchFilter();
  };

  handleCancelClick(e) {
    this.closeClearFilterConfirmation()
    this.openAdditionalFilters()
  }

  handleAdditionalHeaderClick(e) {
    let _this = this;
    const target = $(e.currentTarget);
    const container = target.closest('.additional-filters');

    if (this.isQuerySearchParamsDirty) {
      this.setShowOnLoad()
      this.handleRefineSearch(e)
    }
    this.closeRefineSearchFilter();
    this.openAdditionalFilters();
  }

  persistTargetFilter() {
    const targetFilter = reduce(this.additionalFilters, (targetFilters, value, key) => {
      if ( key == 'id' ) { return targetFilters };
      if ( value.length > 0  ) { targetFilters.push(key) };
      return targetFilters;
    }, []);

    window.localStorage.setItem('targetFilter', JSON.stringify([... new Set(targetFilter)]));
  }

}

export default ResultsFilter;
