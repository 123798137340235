export const slickSettings = {
  similarResultsPage: {
    imageSlider: {
      slidesToShow: 1,
      slidesToScroll: 1,
      lazyLoad: 'ondemand',
      nextArrow: '<button class="slick-next slick-arrow" aria-label="Next Image" type="button">Next Image</button>',
      prevArrow: '<button class="slick-prev slick-arrow" aria-label="Previous Image" type="button">Previous Image</button>',
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 320,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          }
        }
      ]
    },
    compareSlider: {
      stickyHeader: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: false,
        mobileFirst: true,
        arrows: false,
        dots: false,
        asNavFor: '.tile-container-main-slider',
        responsive: [
          {
            breakpoint: 479,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            }
          },
          {
            breakpoint: 767,
            settings: 'unslick'
          }
        ]
      },
      mainSlider: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: false,
        mobileFirst: true,
        arrows: true,
        // appendArrows: $('.compare-page__count'),
        dots: false,
        asNavFor: '.tile-container-sticky-slider',
        responsive: [
          {
            breakpoint: 767,
            settings: 'unslick'
          }
        ]
      }
    }
  }
}
