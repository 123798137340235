import bindAll from 'lodash/bindAll';
import { analyticsAllowed } from "./../../utils/cookie-helper";

class UpdateWebComponent {
  constructor(options = {}) {
    this.component = options.component;
    this.propertyToUpdate = options.propertyToUpdate;
    this.propertyValue = options.propertyValue;
    this.isValueString = options.isValueString;

    this.bindAll();
    this.init();
  }

  bindAll() {
    bindAll(this, 'updateWebComponentInitProperty');
  }

  init() {
    this.updateWebComponentInitProperty(this.component, this.propertyToUpdate, this.propertyValue, this.isValueString);
  }

  updateWebComponentInitProperty(component, property, value, isValueString) {
    const webComponents = document.querySelectorAll(component);

    webComponents.forEach(item => {
      if (item.hasAttribute('init')) {
        const itemAttributes = item.getAttribute('init');

        let attributesObject = JSON.parse(itemAttributes);

        if (isValueString) {
          attributesObject[property] = value.toString();
        } else {
          attributesObject[property] = value;
        }

        const newString = JSON.stringify(attributesObject, null, 2);

        item.setAttribute('init', newString);
      }
    });
  }
}

export default UpdateWebComponent;
