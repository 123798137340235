class Page {

  track(event, data) {
    const obj = {
      event,
      ...data
    };
    window.dataLayer.push(obj);
    console.log('track', obj);
  }
 
}

export default Page;
