export const matchHeight = (elements) => {
  let allHeights = [];

  elements.forEach((item) => {
    allHeights.push(item.offsetHeight);
  });

  const tallest = getMaxHeight(allHeights);

  elements.forEach((item) => {
    item.style.height = `${tallest}px`;
  });
};

const getMaxHeight = (heights) => {
  return Math.max(...heights);
}
