/*

vehicles[vehicle_ids] - vehicles%5Bvehicle_ids%5D%5B%5D=16
finance - true/false
min - minimum cash price
max - maximum cash price
postcode - unser postcode
lat - user lat
long - user long
distance - distance from distance slider
dealer_search true/false - If in dealer search mode for national site on a single dealer
monthly_min - Minimum price for monthly min payments (finance only)
monthly_max - Maximum price for monthly max payments (finance only)
dealer_number - dealer id of the dealer (national dealer_search only)
term - Term of the advanced finance (Finance only. Advanced finance filter only)
deposit_max - Maximum deposit (Finance only. Advanced finance filter only)
mileage_max - Maximum annual mileage (Finance only. Advanced finance filter only)

CASH: JOURNEY NATIONAL DISTANCE
  Endpoint: {rootURL}/results?{PARAMS} Required:
    vehicles[vehicle_ids]
    finance (false)
    postcode OR lat & long
    distance
    dealer_search (false)
    min
    max

CASH: JOURNEY NATIONAL DEALER

  Endpoint: {rootURL}/results?{PARAMS} Required:
    vehicles[vehicle_ids]
    finance (false)
    postcode OR lat & long
    dealer_number
    dealer_search (true)
    min
    max

CASH: Journey: In dealership
  Endpoint: {rootURL}/dealer/{DEALER_ID}/results?{PARAMS} Required:
  vehicles[vehicle_ids]
  finance (false)
  min
  max

FINANCE: Journey: National Distance
  Endpoint: {rootURL}/results?{PARAMS} Required:
    vehicles[vehicle_ids]
    finance (true)
    postcode OR lat & long
    distance
    dealer_search (false)
    monthly_min
    monthly_max


FINANCE: Journey: National Dealer
  Endpoint: {rootURL}/results?{PARAMS} Required:
    vehicles[vehicle_ids]
    finance (true)
    postcode OR lat & long
    dealer_number
    dealer_search (true)
    monthly_min
    monthly_max

FINANCE: In dealership
  Endpoint: {rootURL}/dealer/{DEALER_ID}/results?{PARAMS} Required:
    vehicles[vehicle_ids]
    finance (true)
    monthly_min
    monthly_max

Results page (Main Filters) Journey:
ON THE ROAD TAB
***
  IDENTICAL TO ALL CASH JOURNEY'S ABOVE. MUST READ WHICH JOURNEY YOU ARE ON USING THE VARIABLE GIVEN.
AKA in_retailer? == true then use in dealership params above for cash ***

Journey:
MONTHLY TAB
SAME AS ALL FINANCE JOURNEY'S ABOVE PLUS
  term
  max_deposit
  max_mileage
  (VSS API only allow us to pass in single deposit and mileage integer so
  using max makes sense. Ignore min values for deposit and mileage.
  Must bring up with designs at one point)
*/
export const querySearchParams = {
  vehicles: {
    vehicle_ids: []
  },
  finance: false,
  // CASH ONLY
  min: 0,
  max: 200000,
  // MONTHLY PAYMENT
  monthly_min: 0,
  monthly_max: 0,
  term: 12,
  // NOT SENT WITH REQUEST
  deposit_min: 0,
  deposit_max: 20000,
  // NOT SENT WITH REQUEST
  mileage_min: 0,
  mileage_max: 60000,

  dealer_search: false,
  dealer_number: 0,

  postcode: '',
  distance: 50,
  lat: '',
  long: '',
  debug: false
};
// List of parameter to be base64 encoded, at the moment postcode, latitude and longitude
const encodedQueryParams = ['p', 'f', 'ds', 'dn', 'd', 'min', 'max', 'monthly_min', 'monthly_max', 'term', 'deposit_max', 'mileage_max', 'la', 'lo', 'debug']

const setQueryParams = (obj) => {
  let str = '?';
  const params = [];
  Object.keys(obj).forEach((key) => {
    const value = obj[key];
    // Check if the type of values is a string or number
    if (typeof value === 'string'
      || typeof value === 'number'
      || typeof value === 'boolean') {
      let queryParamValue = encodedQueryParams.includes(key) ? btoa(String(value)) : value;
      params.push([ key, queryParamValue ].join('='));
    // Else check if its and array
    } else if (Array.isArray(value)) {
      // TODO - ROUTE SCOPE ARRAYS YET..

    // It must be an object with properties
    } else {
      // Loop through the object properties, the format for the array will be
      // key[subkey][]=1
      // In this instance for this task we only need to check for arrays, consider
      // expanding
      Object.keys(value).forEach((subKey) => {
        const subValue = value[subKey];
        if (Array.isArray(subValue)) {
          subValue.forEach((subItem) => {
            params.push(`${key}[${subKey}][]=${subItem}`);
          });
        }
      });
    }
  });
  str += params.join('&');
  return str;
}

const setDebugMode = () => {
  const debugMode = $('.debug-mode input')[0]
  return (debugMode) ? debugMode.checked : false
}

/*
 * This utility function will format the query requests for both the search
 * and the results pages, since the request formats are exactly the same,
 * requiring the same variables
 */
export const queryFormatter = (path, mode, dealerView, isRefined = false) => {
  // BASIC PARAMETERS
  // These are common for all requests
  let requiredParams = {
    f: querySearchParams.finance,
    v: querySearchParams.vehicles
  };

  // CHECK IF IN DEALER VIEW
  // If not in dealer view we need the user location
  if (!dealerView) {
    if (querySearchParams.postcode !== '') {
      requiredParams.p = querySearchParams.postcode;
    } else {
      requiredParams.la = querySearchParams.lat;
      requiredParams.lo = querySearchParams.long;
    }

    // DETERMINE WHETHER THE USER
    // Values from the distance slider, or dealer select
    let lat, long, distance;
    if (mode === 'DISTANCE') {
      requiredParams.ds = false;
      requiredParams.d = querySearchParams.distance;
    } else if(mode === 'DEALER') {
      requiredParams.ds = true;
      requiredParams.dn = querySearchParams.dealer_number;
    }
  }

  // CHECK VALUES TO USE FOR PAYMENTS
  // Same for dealerView or noDealer view
  // USE FINANCE VALUES
  if (requiredParams.f) {
    requiredParams.monthly_min = querySearchParams.monthly_min;
    requiredParams.monthly_max = querySearchParams.monthly_max;

  // USE THE MAX/MIN CASH VALUES
  } else {
    requiredParams.min = querySearchParams.min;
    requiredParams.max = querySearchParams.max;
  }

  // ADDITONAL PARAMETER FOR THE
  // REFINED SEARCH ONLY
  if (isRefined && requiredParams.f) {
    requiredParams.term = querySearchParams.term;
    requiredParams.deposit_max = querySearchParams.deposit_max;
    requiredParams.mileage_max = querySearchParams.mileage_max;
  }

  requiredParams.debug = setDebugMode();

  let str = `${path}${setQueryParams(requiredParams)}`;
  return str;
};
