// TODO - Migrate all uses of link building to use this module
// Refactoring ticket: NCLBM-473
export const buildLink = (path) => {
  if (window.dealerView) {
    const dealerId = window.location.pathname.split('/')[2];
    path = `/retailer/${dealerId}${path}`;
  } else if (window.groupView || window.groupRetailerView) {
    const groupSlug = window.location.pathname.split('/')[2];
    path = `/group/${groupSlug}${path}`;
  }

  return path;
};
