export const changeButtonState = (buttonElement, state) => {
  if (buttonElement === undefined) return;
  if (state === undefined) return;

  if (state === 'disable') {
    buttonElement.setAttribute('disabled', '');
    buttonElement.setAttribute('aria-disabled', true);
    buttonElement.classList.add('disabled');
  } else if (state === 'enable') {
    buttonElement.removeAttribute('disabled');
    buttonElement.setAttribute('aria-disabled', false);
    buttonElement.classList.remove('disabled');
  }
};
