import bindAll from 'lodash/bindAll';
import TooltipBasic from './components/tooltip-basic/tooltip-basic';
import Accordion from './components/accordion/accordion';
import CompareVehicles from './components/compare-vehicles/compare-vehicles';
import UpdateWebComponent from './components/update-web-components/update-web-components';
import { buildLink } from "./utils/build-link";
import { matchHeight } from "./utils/match-height";
import { truncateTextToggle } from "./utils/truncate-text-toggle";
import { analyticsAllowed } from "./utils/cookie-helper";

class Compare {
  constructor() {
    this.init();
  };

  init() {
    bindAll(this,
      'initCompareCarousel',
      'applyClassAndResetCarouselOnScroll'
    );

    this.tooltip = new TooltipBasic();
    this.webComponent = new UpdateWebComponent({
      component: 'finance-summary',
      propertyToUpdate: 'disableAnalytics',
      propertyValue: !analyticsAllowed(),
      isValueString: true
    });

    const otrPrice = document.querySelectorAll('.tile__otr-value');
    const monthlyPrice = document.querySelectorAll('.tile__monthly-value');

    this.updateCurrencyString(otrPrice);
    this.updateCurrencyString(monthlyPrice);

    const vehicleLinks = document.querySelectorAll('.compare-button-section a');

    this.handleCardClick(vehicleLinks);
    this.handleScroll();
    this.handleRemoveClick();
    this.handleReadMoreClick();
    this.initCompareCarousel();
    this.matchHeightOfTextComponents();
    this.handleBackToSearchClick();

    const compareAccordion = new Accordion({ id: 'compare-accordion' });

    window.addEventListener('DOMContentLoaded', function() {
      CompareVehicles.checkAndUpdateEmptyState();
    });
  }

  initCompareCarousel() {
    const pageSection = document.querySelector('section[data-compare-count]');
    const compareCount = pageSection.getAttribute('data-compare-count');
    const arrowContainer = pageSection.querySelector('.compare-page__count');
    const slider = $('.tile-container-main-slider');
    const stickyHeaderSlider = $('.tile-container-sticky-slider');

    // Slick slider can only be initialized with jQuery
    slider.slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: false,
      mobileFirst: true,
      arrows: true,
      appendArrows: arrowContainer,
      dots: false,
      asNavFor: '.tile-container-sticky-slider',
      responsive: [
        {
          breakpoint: 479,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: compareCount,
            slidesToScroll: 1,
          }
        }
      ]
    });

    stickyHeaderSlider.slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: false,
      mobileFirst: true,
      arrows: false,
      dots: false,
      asNavFor: '.tile-container-main-slider',
      responsive: [
        {
          breakpoint: 479,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: compareCount,
            slidesToScroll: 1,
          }
        }
      ]
    });

    slider.on('breakpoint', function(event, slick, breakpoint) {
      const compareAccordion = new Accordion({ id: 'compare-accordion' });
      slider.slick('setPosition');
    });
  }

  matchHeightOfTextComponents() {
    const vehicleRange = document.querySelectorAll('.tile__range');
    const vehicleTitles = document.querySelectorAll('.tile__description');
    const vehicleLocationText = document.querySelectorAll('.compare-location-section');

    matchHeight(vehicleRange);
    matchHeight(vehicleTitles);
    matchHeight(vehicleLocationText);
  }

  handleReadMoreClick(event) {
    const truncateToggles = document.querySelectorAll('.js-truncate-toggle');

    truncateToggles.forEach(toggle => {
      toggle.addEventListener('click', () => {
        truncateToggles.forEach(toggle => {
          truncateTextToggle(toggle);
        });
      });
    });
  }

  updateCurrencyString(priceArray) {
    priceArray.forEach((price) => {
      let priceText = price.textContent;
      priceText = parseInt(priceText);
      const priceUpdated = priceText.toLocaleString('en', {
        style: 'currency',
        currency: 'GBP'
      });
      price.textContent = priceUpdated;
    });
  }

  handleCardClick(elements) {
    elements.forEach((link) => {
      link.addEventListener('click', function(event) {
        event.preventDefault();

        const path = event.target.getAttribute('data-link');
        const location = buildLink(path);

        window.location = location;
      });
    });
  }

  handleRemoveClick() {
    const removeButtons = document.querySelectorAll('.tile__compare');

    if (removeButtons) {
      removeButtons.forEach((button) => {
        button.addEventListener('click', (event) => {
          event.preventDefault();

          CompareVehicles.removeVehicleFromPage(event);
          CompareVehicles.checkAndUpdateEmptyState();
        });
      });
    }
  }

  handleScroll() {
    window.addEventListener('scroll', () => {
      this.applyClassAndResetCarouselOnScroll();
      requestAnimationFrame(this.applyClassAndResetCarouselOnScroll);
    }, {
      passive: true
    });
  }

  handleBackToSearchClick() {
    const backToSearchPageButton = document.getElementById('js-back-to-search-button');

    backToSearchPageButton.addEventListener('click', (event) => {
      event.preventDefault();

      const location = buildLink('/');
      window.location = location;
    });
  }

  applyClassAndResetCarouselOnScroll() {
    const body = document.body;
    const scrollThreshold = 200;
    const mainSlider = $('.tile-container-main-slider'); // Slick slider can only be initialized with jQuery
    const stickySlider = $('.tile-container-sticky-slider'); // Slick slider can only be initialized with jQuery

    if (window.scrollY > scrollThreshold) {
      if (body.classList.contains('compare-scrolled')) {
        return
      }

      body.classList.add('compare-scrolled');
      mainSlider.slick('setPosition');
      stickySlider.slick('setPosition');
    } else {
      if (!body.classList.contains('compare-scrolled')) {
        return;
      }

      body.classList.remove('compare-scrolled');
    }
  }
}

export default Compare;
