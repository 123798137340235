import bindAll from 'lodash/bindAll';

/**
 * @class LazyLoadBackground
 */

class LazyLoadBackground {
  constructor(options = {}) {
    this.loadAll = options.loadAll || false;
    this.init();
  }

  /**
   * @function init
   */
  init() {
    bindAll(this,
      'lazyLoadCarouselImages'
    );

    this.lazyLoadCarouselImages();
  }

  /**
   * @function lazyLoadCarouselImages
   */
  lazyLoadCarouselImages() {
    const sliders = document.querySelectorAll('.slider');

    sliders.forEach(slider => {
      const images = slider.querySelectorAll('.js-lazy-load');

      if (this.loadAll === true) {
        images.forEach(image => {
          if (image.hasAttribute('data-src')) {
            const imageUrlNext = image.getAttribute('data-src');
            image.style.backgroundImage = `url(${imageUrlNext})`;
          }
        })
      } else {
        // offset by 1 due to slick appending a slide at the beginning of the carousel
        const firstImage = images[1];
        const firstImageUrl = firstImage.getAttribute('data-src');
        const secondImage = images[2];
        const secondImageUrl = secondImage.getAttribute('data-src');

        firstImage.style.backgroundImage = `url(${firstImageUrl})`;
        secondImage.style.backgroundImage = `url(${secondImageUrl})`;

        // Slick slider events can only be called using jquery
        $(slider).on('beforeChange', (e, slick, currentSlide, nextSlide) => {
          if ((currentSlide > nextSlide && (nextSlide !== 0 || currentSlide === 1)) || (currentSlide === 0 && nextSlide === slick.slideCount - 1)) {
            if (images[nextSlide + 1].hasAttribute('data-src')) {
              const imageUrlNext = images[nextSlide + 1].getAttribute('data-src');
              images[nextSlide + 1].style.backgroundImage = `url(${imageUrlNext})`;
            }
          }
          else {
            if (images[nextSlide + 2].hasAttribute('data-src')) {
              const imageUrlNext = images[nextSlide + 2].getAttribute('data-src');
              images[nextSlide + 2].style.backgroundImage = `url(${imageUrlNext})`;
            }
          }
        });
      }
    })
  }
}

export default LazyLoadBackground;
