class MarketingSearch {
  constructor() {
    this.init();
  };

  init() {
    
    $('.marketing-slider').slick({
      infinite: false,
      dots: true,
      arrows: false
    });
    
    $('[data-brand="mini"] .marketing-slider h1').each(function() {
      const $this = $(this);
      const color = $this.attr('data-color');
      const split = $this.text().split(' ');
      const isElectric = $this.parents('[data-series="Electric"]').length;
      let out = [];
      split.forEach((item, index) => {
        out.push(index < split.length - 1 ? `<span>${item}</span>` : `<span><i style="background-color: ${color};"></i>${item}</span>`);
      });
      $this.html(out.join(isElectric ? ' ' : '<br /> '));
    });

    const processText = (text) => {
      text = text.toUpperCase();
      text = text.replace(' PLUG-IN HYBRID', '');
      text = text.replace('I7', 'i7');
      text = text.replace('IX', 'iX');
      text = text.replace('I4', 'i4');
      return text;
    };

    $('[data-brand="bmw"] .marketing-slider h2').each(function() {
      const $this = $(this);
      $this.text(processText($this.text()));
    });

    window.addEventListener('resize', this.setHeight);
    this.setHeight();

  };

  getNode({ head, body, type }, step, index, last) {
    const hasFoot = index < last;
    const types = {
      tooltip: `<div class="marketing-tooltip">
        <div class="marketing-tooltip-head">
          <span>${step}</span> Modify your results
          <a href="#close" class="marketing-tooltip-close">Close</a>
        </div>
        <div class="marketing-tooltip-body">
          <div class="marketing-tooltip-body-head">${head}</div>
          <div class="marketing-tooltip-body-body">${body}</div>
          <div class="marketing-tooltip-body-foot" data-next="${hasFoot}">
            <a href="#next">Next</a>
          </div>
        </div>
      </div>`,
      prompt: `<div class="marketing-prompt-body">${body}</div>`
    };
    return $(types[type]);
  };

  showNext(target) {
    this[target].active ++;
    this[target].items.forEach(({$node, $mist, $target}) => {
      $node.attr('data-active', 'false');
      $mist.attr('data-active', 'false');
      $target.attr('data-active', 'false').attr('data-init', 'false');
    });
    const { $node, $mist, $target } = this[target].items[this[target].active];
    $node.attr('data-active', 'true');
    $mist.attr('data-active', 'true');
    $target.attr('data-active', 'true');
    setTimeout(() => {
      $target.attr('data-init', 'true');
    }, 1000);
  };

  close(target) {
    const { $node, $mist, $target } = this[target].items[this[target].active];
    $node.attr('data-active', 'false');
    $mist.attr('data-active', 'false');
    $target.attr('data-active', 'false');
    setTimeout(() => {
      this[target].items.forEach(({$node, $mist}) => {
        if (target === 'tooltip') {
          $node.remove();
        };
        $mist.remove();
      });
    }, 2000);
  };

  renderTooltips(data) {

    this.tooltips = data;

    const count = this.tooltips.items.length;

    this.tooltips.items.forEach((tooltip, index) => {
      const $target = $(`.filter-header[data-name="${tooltip.target}"]`);
      const $parent = $target.parent();
      tooltip.$node = this.getNode(tooltip, `${index + 1}/${count}`, index, count - 1);
      tooltip.$mist = $(`<div class="marketing-tooltip-mist" />`);
      tooltip.$target = $target;
      $parent.prepend(tooltip.$mist);
      $parent.append(tooltip.$node);
      tooltip.$node.on('click', 'a[href="#next"]', () => {
        this.showNext('tooltips');
        clearTimeout(this.timer);
        return false;
      }).on('click', 'a[href="#close"]', () => {
        this.close('tooltips');
        clearTimeout(this.timer);
        return false;
      });
    });

    this.showNext('tooltips');
    this.wait('tooltips');

  };

  wait(target) {
    this.timer = setTimeout(() => {
      if (this[target].items[this[target].active + 1]) {
        this.showNext('tooltips');
        this.wait('tooltips');
      }
      else {
        this.close('tooltips');
      }
    }, 5000);
  };

  renderPrompts(data) {

    this.prompts = data;

    this.prompts.items.forEach((prompt) => {

      const $target = $(`section.${prompt.target}`).addClass('marketing-prompt');
      const $parent = $target.parent();
      prompt.$node = this.getNode(prompt);
      prompt.$mist = $(`<div class="marketing-prompt-mist" />`);
      prompt.$target = $target;
      $parent.prepend(prompt.$mist);
      $target.append(prompt.$node);

      setTimeout(() => {
        this.close('prompts');
        $target.css('bottom', `${(prompt.$node.outerHeight() + 15) * -1}px`);
      }, 5000);

    });

    this.showNext('prompts');

  };

  renderHelpers(brand) {

    const tooltips = {
      active: -1,
      items: [
        {
          head: 'Model',
          body: `Modify your search results based on a particular ${brand} model of interest.`,
          target: 'tier_ones',
          type: 'tooltip'
        }
      ]
    };

    if (!dealerView && !groupView && !groupRetailerView) {
      tooltips.items.push({
        head: 'Location',
        body: `You can search for available ${brand} models by postcode and radius or you can select a ${brand} retailer of choice.`,
        target: 'location',
        type: 'tooltip'
      });
    };

    this.renderTooltips(tooltips);

    this.renderPrompts({
      active: -1,
      items: [
        {
          target: 'filters-menu',
          body: `Modify your search above, based on your location, desired retailer, budget and ${brand} model of interest.`,
          type: 'prompt'
        }
      ]
    });
    
  };

  setHeight() {
    
    document.documentElement.style.setProperty('--app-height', `${window.innerHeight}px`);

  };

};

export default MarketingSearch;
