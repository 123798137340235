// BMW Enquiry Form Plugin

/* eslint-env browser */
/* eslint-disable semi */
/* global $, window */
$(function() {
  var ADJUST_HEIGHT_MSG = 'ADJUST_HEIGHT:';
  var SCROLL_TOP_MSG = 'SCROLL_TOP';
  var UPDATE_HASH_MSG = 'UPDATE_HASH';
  var SKYGLUE_WEB_USER_ID = 'SKY_GLUE_WEB_ID';
  var FORM_LOADED = 'FORM_LOADED';
  var SET_FORM_DATA = 'SET_FORM_DATA';
  var SET_REFERRER_URL = 'SET_REFERRER_URL';
  var embeddedForm = document.getElementById('bmw-embedded-form');

  // checks if we the message contains the prefix
  function isMessage(prefix, msgData) {
      return msgData.indexOf(prefix) === 0;
  }

  // returns the reported height of the container
  function getReportedHeight(msgData) {
      return parseInt(msgData.replace(ADJUST_HEIGHT_MSG, ''), 10);
  }

  // returns the hash of the model tree reference, used for deeplinking
  function getReportedHash(msgData) {
      return msgData.replace(UPDATE_HASH_MSG + ':', '');
  }

  // sets the iframe height
  function setFrameHeight(ev) {
      var frame = document.querySelector('.bmw-embedded-form');
      frame.style.height = getReportedHeight(ev.data) + 'px';
  }

  // scrolls to the top of the iframe
  function setPageScrollTop(ev) {
      var targetScrollTop = jQuery(window).scrollTop() + embeddedForm.getBoundingClientRect().top;

      jQuery(window).scrollTop(parseInt(targetScrollTop, 10));
  }

  // updates the has path, used for deeplinking
  function setHashParam(ev) {
      history.pushState(null, null, '#' + getReportedHash(ev.data).trim());
  }

  function setReferrerUrl() {
      var data = SET_REFERRER_URL + ':' + document.referrer;

      embeddedForm.contentWindow.postMessage(data, '*');
  }

  // creates the message event listener for the parent window
  window.addEventListener('message', function(ev) {
      var message = ev.data.toString();

      if (isMessage(ADJUST_HEIGHT_MSG, message)) {
          setFrameHeight(ev);
      } else if (isMessage(SCROLL_TOP_MSG, message)) {
          setPageScrollTop(ev);
      } else if (isMessage(UPDATE_HASH_MSG, message)) {
          setHashParam(ev);
      } else if (isMessage(FORM_LOADED, message)) {
          setReferrerUrl();
      }
  });

  // function to set iframe src value
  function setLink(sourceUrl, bodyTypeId) {
      // check if URL ends in a '/'
      if (sourceUrl[sourceUrl.length - 1] === '/') {
          // check if the 'bodyTypeId' is set
          if (bodyTypeId !== '' && bodyTypeId !== null) {
              // set new iframe 'src' value with the HASH value
              embeddedForm.src = sourceUrl + bodyTypeId;
          } else if (sourceUrl !== embeddedForm.src) {
              // else set new iframe 'src' value without the HASH value and is not currently the source value
              embeddedForm.src = sourceUrl;
          }
      } else {
          // else set new iframe 'src' value with or without the HASH value with '/' appended to it
          embeddedForm.src =
              bodyTypeId !== '' && bodyTypeId !== null ? sourceUrl + '/' + bodyTypeId : sourceUrl + '/';
      }
  }

  // function to check if new 'data-src' value is set
  function checkUrlSrc(bodyTypeId = null) {
      // set source value
      var dataSrc =
        embeddedForm.getAttribute('data-src') !== 'undefined' && embeddedForm.getAttribute('data-src') !== ''
        ? embeddedForm.getAttribute('data-src')
        : embeddedForm.src;

      // run 'setLink' function
      setLink(dataSrc, bodyTypeId);
  }

  // check if URL contains a HASH value
  if (window.location.hash) {
      var bodyTypeId = window.location.hash.replace('#', '');

      // run 'checkUrlSrc' function
      checkUrlSrc(bodyTypeId);
  } else {
      // run 'checkUrlSrc' function
      checkUrlSrc();
  }
});
