import bindAll from 'lodash/bindAll';

/**
 * @class StickySidebar
 * @description Sticky sidebar when scrolled into the viewport
 */

class StickySidebar {
  constructor(options = {}) {
    this.sidebarClass = options.sidebarClass || '.sticky-sidebar';
    this.containerClass = options.containerClass || '.sticky-container';
    this.headerHeight = options.offsetHeight || document.querySelector('.header ').offsetHeight;
    this.sidebar = document.querySelector(this.sidebarClass);
    this.container = document.querySelector(this.containerClass);
    this.lastScrollPosition = 0;
    this.init();
  }

  /**
   * @function init
   * @description Initialize scroll listener for the sticky sidebar
   */
  init() {
    bindAll(this,
      'scrollHandler',
      'scrollUp',
      'scrollDown',
      'resetStyles'
    );

    // IE does not support position: sticky
    // Sidebar maintains default behaviour for IE
    if (navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > -1) {
      return;
    }

    window.addEventListener('scroll', this.scrollHandler, {passive: true});
    window.addEventListener('resize', this.resetStyles, {passive: true});
  }

  /**
   * @function scrollUp
   * @description Correctly positions sidebar upon scrolling up
   */
  scrollUp(stickyPosition) {
    if(stickyPosition === 1) return stickyPosition;

      let elem = this.sidebar;
      let aboveElem = elem.getBoundingClientRect().top > this.headerHeight;

      if (aboveElem){
        // jQuery used for applying styles as vanilla js doesn't
        // handle offsetTop well for nested elements.
        $(this.sidebarClass)
          .css('top', this.headerHeight)
          .css('bottom', '')
          .css('align-self', 'flex-start');

        this.sidebar.classList.remove('sticky-sidebar-reset');
        this.sidebar.classList.add('sticky-sidebar-set');
        this.container.classList.remove('sticky-active');
        return 1;
      }

      if (stickyPosition == 0) return stickyPosition;

      // jQuery used for applying styles as vanilla js doesn't
      // handle offsetTop well for nested elements.
      $(this.sidebarClass)
        .css('top', elem.offsetTop)
        .css('bottom', '')
        .css('align-self', '');

      this.sidebar.classList.remove('sticky-sidebar-set');
      this.sidebar.classList.add('sticky-sidebar-reset');
      this.container.classList.add('sticky-active');

      return 0;
  }

  /**
   * @function scrollDown
   * @description Correctly positions sidebar upon scrolling down
   */
  scrollDown(stickyPosition) {
    if(stickyPosition === -1) return stickyPosition;
      let elem = this.sidebar;
      let browser_bottom = window.innerHeight;
      let elem_bottom = elem.getBoundingClientRect().top + elem.offsetHeight;
      let belowElem = browser_bottom > elem_bottom;

      if (belowElem){
        // jQuery used for applying styles as vanilla js doesn't
        // handle offsetTop well for nested elements.
        $(this.sidebarClass)
          .css('top', '')
          .css('bottom', 0)
          .css('align-self', 'flex-end');

        this.sidebar.classList.remove('sticky-sidebar-reset');
        this.sidebar.classList.add('sticky-sidebar-set');
        this.container.classList.remove('sticky-active');

        return -1;
      }

      if (stickyPosition == 0) return stickyPosition;
      // jQuery used for applying styles as vanilla js doesn't
      // handle offsetTop well for nested elements.
      $(this.sidebarClass)
        .css('top', elem.offsetTop)
        .css('bottom', '')
        .css('align-self', '');

      this.sidebar.classList.remove('sticky-sidebar-set');
      this.sidebar.classList.add('sticky-sidebar-reset');
      this.container.classList.add('sticky-active');

      return 0;
  }


  /**
   * @function scrollHandler
   * @description Handles the scroll event
   */
  scrollHandler(event) {
    if (this.sidebar && window.innerWidth >= 1024) {
      if (window.scrollY < this.prevScrollY) {
        this.stickyPosition = this.scrollUp(this.stickyPosition);
      } else {
        this.stickyPosition = this.scrollDown(this.stickyPosition);
      }
      this.prevScrollY = window.scrollY;
    }
  }

  /**
   * @function resetStyles
   * @description Handles the resize event, iOS bug fix for orientation change
   */
  resetStyles(event) {
    if (this.sidebar && window.innerWidth < 1024) {
      this.sidebar.removeAttribute('style');
      this.container.classList.remove('sticky-active');
    }
  }
}

export default StickySidebar;
